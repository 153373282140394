import Vue from 'vue'
import Vuex from 'vuex'
import {current_date} from "@/utils/currentDate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    manageHeaderTitle:"云ICU后台管理系统",
    returnId:'',
    condition:'',
  //  通过的审批按钮
    passBtn:false,
  //  审核的模板链接
    caseLink:'',
    //图片打码左侧id
    returnLeftId:'',
    //图片打码状态
    picState:'',
  //  直播抽奖活动标题
    liveTitle:'',
  //  直播活动会议名称
    liveMeetName:'',
  //  直播间新增里的两张图片默认打开显示会议的图片
    liveImg:{},
  //  医学会议直播间新增里的两张图片默认打开显示会议的图片
    convertionImg:{},
  // 病例审核页码数
    pageNo:1,

    //职称考试分类名称Id
    examId:null,

  //  名单对话框的数据  注册会员管理-病历项目管理中的资格证上传-
    listDialogBox:{
    //  是否开启对话框
      isShow:false,
    //  用户memberId
      memberId:null,
    //  对话框中的数据
      addForm:{},
    },
    //  liveMeet  直播会议的操作记录  --历史记录
    liveMeetHistory:{
      year:current_date(),
      //判断路径上进入
      isRouter:true,
      state:'',
      title:'',
      code:'',
      principal:'',
      pageNo:1,
    },
    // 意见反馈中所有意见的总数
    all: '',
    // 意见反馈中已解决意见的条数
    done: '',
    // 意见反馈中待确认意见的条数
    confirmed:'',
    //点播点击记录内容类型
    content_type: {
      VOD_SINGLE:'单视频',
      MIX_SET_TOPIC:'专题',
      ARTICLE_SINGLE:'文章',
    },
    //内容点击位置
    location:{
      CDYX_INDEX: '畅道云学院栏目首页',
      CXNX1_INDEX: '出血凝血栏目首页',
      NDZ_INDEX: '脓毒症栏目首页',
      OTHER_INDEX: '其他栏目首页',
      YUN_ICU_INDEX: '云ICU栏目首页',
      YUN_ICU_index:'云ICU栏目首页',
      XZ_INDEX: '心脏与血流栏目首页',
      ZL_INDEX: '肿瘤栏目首页',
      ZTZJ_INDEX: '镇痛镇静栏目首页',
      ZZCK_INDEX: '产科栏目首页',
      ZZEK_INDEX: '儿科栏目首页',
      ZZGR_INDEX: '感染栏目首页',
      ZZHL_INDEX: '护理栏目首页',
      ZZHX_INDEX: '呼吸栏目首页',
      ZZKY_INDEX: '重症科研栏目首页',
      ZZSJ_INDEX: '神经栏目首页',
      ZZSZ_INDEX: '肾脏栏目首页',
      ZZWK_INDEX: '外科栏目首页',
      ZZXXH_INDEX: '重症信息化栏目首页',
      ZZYY_INDEX: '营养栏目首页',
      SQ_INDEX: '社区首页',
      YUNICU_VOD_LIST: '云ICU点播列表',
      YUN_ICU_VOD_LIST: '云ICU点播列表',
      MIX_SET_TOPIC: '点播专题',
      MY_COLLECTION: '我的收藏列表',
      VOD_SEARCH: '搜索点播列表',
      ZH_SEARCH: '搜索综合列表',
      ARTICLE_SEARCH: '搜索文章列表',
      YUN_ICU_ARTICLE_LIST: '云ICU文章列表'
    },
    //访问渠道
    channel:{
      mina:'小程序',
      app:'APP',
      web:'官网'
    },
    //内容记录左侧菜单分类
    content_type_menu:{
      IMAGE: '社区帖子-图片类型',
      LINK: '社区帖子-外链类型',
      TEXT: '社区帖子-文本类型',
      VIDEO: '社区帖子-视频类型',
      COMMUNITY_POST:'社区帖子',
      DOCUMENT: '文献指南',
      MAGAZINE: '医学杂志',
      EDUCATION:'进修招聘',
      ARTICLE: '文章',
      VOD: '点播',
      DRUG:'药品',
      YXGS:'医学公式'
    }


  },
  mutations: {
    all(state,data){
      state.all = data
    },
    done(state,data){
      state.done = data
    },
    confirmed(state,data){
      state.confirmed = data
    },
    setManageHeaderTitle(state,value){
      state.manageHeaderTitle = value
    },
    //病例返回上一层恢复历史状态
    returnId(state,data){
      state.returnId = data
    },
    condition(state,data){
      state.condition = data
    },
    passBtn(state,data){
      state.passBtn = data
    },
    caseLink(state,data){
      state.caseLink = data
    },
    //图片打码返回上一层恢复历史状态
    returnLeftId(state,data){
      state.returnLeftId = data
    },
    picState(state,data){
      state.picState = data
    },
    liveMeetName(state,data){
      state.liveMeetName = data
    },
    liveTitle(state,data){
      state.liveTitle = data
    },
    liveImg(state,data){
     state.liveImg = data
    },
    convertionImg(state,data){
     state.convertionImg = data
    },
    pageNo(state,data){
     state.pageNo = data
    },
    listDialogBox(state,data){
     state.listDialogBox = data
    },
    liveMeetHistory(state,data){
     state.liveMeetHistory = data
    },
  },
  actions: {
    setManageHeaderTitle(context,value){
      context.commit("setManageHeaderTitle",value)
    },
    returnId(context,data){
      context.commit('returnId',data)
    },
    condition(context,data){
      context.commit('condition',data)
    },
    passBtn(context,data){
      context.commit('passBtn',data)
    },
    caseLink(context,data){
      context.commit('caseLink',data)
    },
    returnLeftId(context,data){
      context.commit('returnLeftId',data)
    },
    picState(context,data){
      context.commit('picState',data)
    },
    liveTitle(context,data){
      context.commit('liveTitle',data)
    },
    liveMeetName(context,data){
      context.commit('liveMeetName',data)
    },
    liveImg(context,data){
      context.commit('liveImg',data)
    },
    convertionImg(context,data){
      context.commit('convertionImg',data)
    },
    pageNo(context,data){
      context.commit('pageNo',data)
    },
    listDialogBox(context,data){
      context.commit('listDialogBox',data)
    },
    liveMeetHistory(context,data){
      context.commit('liveMeetHistory',data)
    },

  },
  modules: {
  }
})
