<template>
  <div class="ImageManagement">
    <a-spin :spinning="spinning" >
    <div class="head" >
      <a-tabs default-active-key="1" >
        <a-tab-pane key="1" tab="海报">
          <div class="main">
            <div  class="has-color">
              <h3>主色值:</h3>
              <div style="margin-left: 20px;margin-bottom: 5px;display: flex;justify-content: space-between;width: 50%" >
                <a-radio-group  v-model="HBcolor_value" @change="ColorChange">
                  <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #0099FF;margin: 5px" value="0153255"/>
                  <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #00FF00;margin: 5px" value="02550"/>
                  <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #CC0000;margin: 5px" value="20400"/>
                  <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #FFFF00;margin: 5px" value="2552550"/>
                  <a-radio-button style="width: 70px;border-radius: 0;background-color: #00FFFF;margin: 5px" value="0255255"/>
                  <a-radio-button style="width: 70px;border-radius: 0;background-color: #FF7D00;margin: 5px" value="2551250"/>
                  <a-radio-button style="width: 70px;border-radius: 0;background-color: #9900CC;margin: 5px" value="1530204"/>
                </a-radio-group>
              </div>
            </div>
            <div class="image_list" >
              <div style="display: flex;justify-content: space-between;width: 98%;margin: 0 auto">
                <a-button type="primary" @click="addPosters">新增海报</a-button>
                            <a-input-search
                                placeholder="输入作者搜索"
                                enter-button="搜索"
                                v-model="HBnameValue"
                                style="width: 20%"
                                @search="PosterSearch"
                            />
              </div>
              <div style="display: flex;flex-wrap: wrap;margin-bottom: 20px" >
                <a-card  hoverable style="width: 200px;margin: 10px;" v-for="(list,index) in PosterList" :key="index">
                  <img
                      slot="cover"
                      alt="example"
                      style=" padding: 5px;margin: 0 auto;width: 120px;height: 150px"
                      :src="list.img_url"
                  />
                  <span style="font-weight:500">作者：{{list.user_name}}</span><br/>
                  <span  style="font-size: 10px">上传时间:{{dateFormat('YYYY-mm-dd',list.created_time)}}</span>
                  <div class="card-button">
                    <a-button type="primary" size="small" @click="Download(list)">下载</a-button>
                    <a-button  size="small" @click="Edit(list)">编辑</a-button>
                    <a-button  size="small" @click="delImg(list.id)">删除</a-button>
                  </div>
                </a-card>
              </div>
              <div style="display: flex;justify-content: flex-end;right: 35px;z-index:99;bottom:20px;position: absolute;">
                <a-locale-provider  :locale="zhCN" >
                  <a-pagination v-model="PosterPagination.current"
                                :total="PosterPagination.total"
                                show-quick-jumper
                                size="small"
                                show-size-changer
                                @showSizeChange="onShowSizePoster"
                                :show-total="PosterPagination.showTotal"
                                @change="pageNumberChange" />
                </a-locale-provider>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="封面图" force-render>
          <div class="main">
            <div  class="has-color">
              <h3>主色值:</h3>
              <div style="margin-left: 20px;margin-bottom: 5px;display: flex;justify-content: space-between;width: 50%" >
                <a-radio-group  v-model="color_value" @change="CoverColor">
                  <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #0099FF;margin: 5px" value="0153255"/>
                  <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #00FF00;margin: 5px" value="02550"/>
                  <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #CC0000;margin: 5px" value="20400"/>
                  <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #FFFF00;margin: 5px" value="2552550"/>
                  <a-radio-button style="width: 70px;border-radius: 0;background-color: #00FFFF;margin: 5px" value="0255255"/>
                  <a-radio-button style="width: 70px;border-radius: 0;background-color: #FF7D00;margin: 5px" value="2551250"/>
                  <a-radio-button style="width: 70px;border-radius: 0;background-color: #9900CC;margin: 5px" value="1530204"/>
                </a-radio-group>
              </div>
            </div>
            <div class="image_list" >
              <div style="display: flex;justify-content: space-between;width: 98%;margin: 0 auto">
                <a-button type="primary" @click="()=>{this.Cover = true;this.spinning = true}">新增封面图</a-button>
                <a-input-search
                    placeholder="输入作者搜索"
                    enter-button="搜索"
                    v-model="nameValue"
                    style="width: 20%"
                    @search="CoverSearch"
                />
              </div>
              <div style="display: flex;flex-wrap: wrap;margin-bottom: 20px" >
                <a-card  hoverable style="width: 200px;margin: 10px;" v-for="(list,index) in CoverList" :key="index">
                  <img
                      slot="cover"
                      alt="example"
                      style=" padding: 5px;margin: 0 auto;width: 120px;height: 150px"
                      :src="list.img_url"
                  />
                  <span style="font-weight:500">作者：{{list.user_name}}</span><br/>
                  <span  style="font-size: 10px">上传时间:{{dateFormat('YYYY-mm-dd',list.created_time)}}</span>
                  <div class="card-button">
                    <a-button type="primary" size="small" @click="Download(list)">下载</a-button>
                    <a-button  size="small" @click="EditCover(list)">编辑</a-button>
                    <a-button  size="small" @click="delCoverImg(list.id)">删除</a-button>
                  </div>
                </a-card>
              </div>
              <div style="display: flex;justify-content: flex-end;right: 35px;z-index:99;bottom:20px;position: absolute;">
                <a-locale-provider  :locale="zhCN" >
                  <a-pagination v-model="CoverPagination.current"
                                :total="CoverPagination.total"
                                show-quick-jumper
                                size="small"
                                show-size-changer
                                @showSizeChange="onShowSizeChange"
                                :show-total="CoverPagination.showTotal"
                                @change="pageCoverPagination" />
                </a-locale-provider>
              </div>
            </div>

          </div>
        </a-tab-pane>
<!--        <a-tab-pane key="3" tab="会议首页">-->
<!--        </a-tab-pane>-->
      </a-tabs>
    </div>
    <!--------------------弹框---------------------->

    <!--海报添加-->
    <a-drawer
        title="新增"
        :width="450"
        :visible="visible"
        :maskClosable="false"
        :body-style="{ paddingBottom: '80px' }"
        @close="()=>{visible=false; spinning =false}"
    >
      <a-form-model  :model="AddPoster" :rules="rules" ref="addItem" layout="horizontal" hide-required-mark :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item prop="img_color" label="主色值">
          <div style="margin-bottom: 5px;display: flex;flex-wrap: wrap; width: 100%;" >
            <a-radio-group  v-model="AddPoster.img_color" @change="onChange">
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #0099FF;margin: 5px" value="0153255"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #00FF00;margin: 5px" value="02550"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #CC0000;margin: 5px" value="20400"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #FFFF00;margin: 5px" value="2552550"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #00FFFF;margin: 5px" value="0255255"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #FF7D00;margin: 5px" value="2551250"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #9900CC;margin: 5px" value="1530204"/>
            </a-radio-group>
          </div>
        </a-form-model-item>
        <a-form-model-item prop="img_url" label="缩略图">
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 70%">
            <div class="uploadImg">
              <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="UpPosterThumbnail" ref="Thumbnail" />
            </div>
            <div class="upload_image">
              <img
                  v-if="AddPoster.img_url"
                  alt=""
                  style="width: 95px;height:95px;"
                  :src="AddPoster.img_url"
              />
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="AddPoster.remark" type="textarea" :rows="3" placeholder="请输入备注" />
        </a-form-model-item>
        <a-form-model-item prop="img_files" label="源文件">
          <div style="display: flex; align-items: center ;flex-wrap: wrap " v-for="(item,index) in AddPoster.img_files" :key="index"  >
                <div>
                  <a-input v-model="item.size"  placeholder="尺寸" style="width: 90px"  />&nbsp;--&nbsp;
                </div>
                <div class="upload_File">
                  <input  :id="index" style=" cursor:pointer;width:150px;height: 100%;opacity: 0;"  type="file"  @change=" UpPoster(index)" />

                </div>
                <div v-if="index!==0">
                  <a-button type="dashed"  @click="deleteIndex(item, index)" style=" color:#CCCCCC; border: #CCCCCC 2px solid;margin-left: 20px;" shape="circle" size="small" >
                    <a-icon type="minus" />
                  </a-button>
                </div>
              <div v-if=" index <=0" >
              <a-button @click="clickAddRecharge" style=" color:#0099FF; border: #0099FF 2px solid;margin-left: 20px;" size="small" shape="circle">
                <a-icon type="plus" />
              </a-button>
                </div>
            <div v-if="fileJudge ==='0' " >
              <span  style="color: #00CC33">{{item.name}}</span>
            </div>
            <div v-if="fileJudge === '1'">
              <a-icon type="close" style="color: #CC0000" /><span style="color: #CC0000">上传失败</span>
            </div>
          </div>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click.stop="()=>{visible=false; spinning =false}">
          取消
        </a-button>
        <a-button type="primary" @click="addOK">
          提交
        </a-button>
      </div>
    </a-drawer>

    <!--封面图添加-->
    <a-drawer
        title="新增"
        :width="450"
        :maskClosable="false"
        :visible="Cover"
        :body-style="{ paddingBottom: '80px' }"
        @close="()=>{this.Cover=false; this.spinning =false}"
    >
      <a-form-model  :model="AddCover" :rules="rules" ref="addCover" layout="horizontal" hide-required-mark :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item prop="img_color" label="主色值">
          <div style="margin-bottom: 5px;display: flex;flex-wrap: wrap; width: 100%;" >
            <a-radio-group  v-model="AddCover.img_color" @change="onChange">
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #0099FF;margin: 5px" value="0153255"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #00FF00;margin: 5px" value="02550"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #CC0000;margin: 5px" value="20400"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #FFFF00;margin: 5px" value="2552550"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #00FFFF;margin: 5px" value="0255255"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #FF7D00;margin: 5px" value="2551250"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #9900CC;margin: 5px" value="1530204"/>
            </a-radio-group>
          </div>
        </a-form-model-item>
        <a-form-model-item prop="img_url" label="缩略图">
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 70%">
            <div class="uploadImg">
              <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" type="file" accept="image/*" @change="UpCoverThumbnail" ref="CoverThumbnail" />
            </div>
            <div class="upload_image">
              <img
                  v-if="AddCover.img_url"
                  alt=""
                  style="width: 95px;height:95px;"
                  :src="AddCover.img_url"
              />
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="AddCover.remark" type="textarea" :rows="3" placeholder="请输入备注" />
        </a-form-model-item>
        <a-form-model-item prop="type" label="源文件">
          <div style="display: flex; align-items: center;flex-wrap: wrap" v-for="(item,index) in AddCover.img_files" :key="index"  >
            <div>
              <a-input v-model="item.size"  placeholder="尺寸" style="width: 90px"  />&nbsp;--&nbsp;
            </div>
            <div class="upload_File">
              <input  :id="index" style=" cursor:pointer;width:150px;height: 100%;opacity: 0;"  type="file"  @change=" UpCover(index)"  />
            </div>
            <div v-if="index!==0">
              <a-button type="dashed"  @click="deleteC(item, index)" style=" color:#CCCCCC; border: #CCCCCC 2px solid;margin-left: 20px;" shape="circle" size="small" >
                <a-icon type="minus" />
              </a-button>
            </div>
            <div v-if=" index <=0" >
              <a-button @click="clickAddC" style=" color:#0099FF; border: #0099FF 2px solid;margin-left: 20px;" size="small" shape="circle">
                <a-icon type="plus" />
              </a-button>
            </div>
            <div v-if="fileJudge ==='0' " >
              <span  style="color: #00CC33">{{item.name}}</span>
            </div>
            <div v-if="fileJudge === '1'">
              <a-icon type="close" style="color: #CC0000" /><span style="color: #CC0000">上传失败</span>
            </div>
          </div>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click.stop="()=>{Cover=false; spinning =false}">
          取消
        </a-button>
        <a-button type="primary" @click="addCover">
          提交
        </a-button>
      </div>
    </a-drawer>
    <!--    下载-->
    <a-drawer
        title="下载"
        :width="450"
        :visible="download"
        :body-style="{ paddingBottom: '50px' }"
        @close="()=>{download=false}"
    >
      <a-form-model :model="dImg"  layout="horizontal" hide-required-mark :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item  label="" >
          <img
              v-if="dImg.img_url"
              alt=""
              style="width: auto;padding-left: 20px;height: 280px;"
              :src="dImg.img_url"
          />
        </a-form-model-item >
        <a-form-model-item style="width: 100%;" label="">
          <div v-for="(value,index) in dImg.img_files"  style=" font-weight: bold; width: 100%;align-items: center;margin: 5px;display: flex;justify-content: space-between">
            <span>{{value.size}}</span>
          <a-button type="primary"  @click="downloadCodeImg(index)" style="width: 35%;height: 22px;vertical-align:middle;text-align: center;line-height: 15px;">
            下载源文件
          </a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
    <!--   海报修改-->
    <a-drawer
        title="编辑"
        :width="450"
        :maskClosable="false"
        :visible="edit"
        :body-style="{ paddingBottom: '80px' }"
        @close="()=>{edit=false; this.spinning=false}"
    >
      <a-form-model :model="EditPoster" :rules="rules" ref="editItem" layout="horizontal" hide-required-mark :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item prop="img_color" label="主色值">
          <div style="margin-bottom: 5px;display: flex;flex-wrap: wrap; width: 100%;" >
            <a-radio-group  v-model="EditPoster.img_color" @change="onChange">
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #0099FF;margin: 5px" value="0153255"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #00FF00;margin: 5px" value="02550"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #CC0000;margin: 5px" value="20400"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #FFFF00;margin: 5px" value="2552550"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #00FFFF;margin: 5px" value="0255255"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #FF7D00;margin: 5px" value="2551250"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #9900CC;margin: 5px" value="1530204"/>
            </a-radio-group>
          </div>
        </a-form-model-item>
        <a-form-model-item prop="img_url" label="缩略图"  >
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 70%">
            <div class="uploadImg">
              <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" type="file" accept="image/*" @change="editPosterImg" ref="editPosterImg" />
            </div>
            <div class="upload_image">
              <img
                  v-if="EditPoster.img_url"
                  alt=""
                  style="width: 95px;height:95px;"
                  :src="EditPoster.img_url"
              />
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="EditPoster.remark" type="textarea" :rows="3" placeholder="请输入备注" />
        </a-form-model-item>
        <a-form-model-item prop="type" label="源文件">
          <div style="display: flex; align-items: center;flex-wrap: wrap" v-for="(item,index) in EditPoster.img_files" :key="index"  >
            <div>
              <a-input v-model="item.size"  placeholder="尺寸" style="width: 90px"  />&nbsp;--&nbsp;
            </div>
            <div class="upload_File">
              <input  :id="index" style=" cursor:pointer;width:150px;height: 100%;opacity: 0;"  type="file"  @change="editUpPoster(index)"  />
            </div>
            <div v-if="index!==0">
              <a-button type="dashed"  @click="deleteEP(item, index)" style=" color:#CCCCCC; border: #CCCCCC 2px solid;margin-left: 20px;" shape="circle" size="small" >
                <a-icon type="minus" />
              </a-button>
            </div>
            <div v-if=" index <=0" >
              <a-button @click="clickEP" style=" color:#0099FF; border: #0099FF 2px solid;margin-left: 20px;" size="small" shape="circle">
                <a-icon type="plus" />
              </a-button>
            </div>
            <div  >
              <span  style="color: #00CC33">{{item.name}}</span>
            </div>
            <div v-if="fileJudge === '1'">
              <a-icon type="close" style="color: #CC0000" /><span style="color: #CC0000">上传失败</span>
            </div>
          </div>
        </a-form-model-item>

      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click.stop="()=>{edit=false; spinning=false}">
          取消
        </a-button>
        <a-button type="primary" @click="editOK(EditPoster.id)">
          确认
        </a-button>
      </div>
    </a-drawer>
     <!--  封面图修改-->
    <a-drawer
        title="编辑"
        :width="450"
        :maskClosable="false"
        :visible="editCover"
        :body-style="{ paddingBottom: '80px' }"
        @close="()=>{editCover=false;this.spinning=false}"
    >
      <a-form-model :model="EditCoverForm" :rules="rules" ref="editCoverItem" layout="horizontal" hide-required-mark :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item prop="img_color" label="主色值">
          <div style="margin-bottom: 5px;display: flex;flex-wrap: wrap; width: 100%;" >
            <a-radio-group  v-model="EditCoverForm.img_color" @change="onChange">
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #0099FF;margin: 5px" value="0153255"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #00FF00;margin: 5px" value="02550"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #CC0000;margin: 5px" value="20400"/>
              <a-radio-button class="asd" style="width: 70px;border-radius: 0;background-color: #FFFF00;margin: 5px" value="2552550"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #00FFFF;margin: 5px" value="0255255"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #FF7D00;margin: 5px" value="2551250"/>
              <a-radio-button style="width: 70px;border-radius: 0;background-color: #9900CC;margin: 5px" value="1530204"/>
            </a-radio-group>
          </div>
        </a-form-model-item>
        <a-form-model-item prop="img_url" label="缩略图"  >
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 70%">
            <div class="uploadImg">
              <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" type="file" accept="image/*" @change="editCoverThumbnail" ref="editCoverThumbnail" />
            </div>
            <div class="upload_image">
              <img
                  v-if="EditCoverForm.img_url"
                  alt=""
                  style="width: 95px;height:95px;"
                  :src="EditCoverForm.img_url"
              />
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="EditCoverForm.remark" type="textarea" :rows="3" placeholder="请输入备注" />
        </a-form-model-item>
        <a-form-model-item prop="type" label="源文件">
          <div style="display: flex; align-items: center;flex-wrap: wrap" v-for="(item,index) in EditCoverForm.img_files" :key="index"  >
            <div>
              <a-input v-model="item.size"  placeholder="尺寸" style="width: 90px"  />&nbsp;--&nbsp;
            </div>
            <div class="upload_File">
              <input  :id="index" style=" cursor:pointer;width:150px;height: 100%;opacity: 0;"  type="file"  @change="editUpCover(index)"  />
            </div>
            <div v-if="index!==0">
              <a-button type="dashed"  @click="deleteEC(item, index)" style=" color:#CCCCCC; border: #CCCCCC 2px solid;margin-left: 20px;" shape="circle" size="small" >
                <a-icon type="minus" />
              </a-button>
            </div>
            <div v-if=" index <=0" >
              <a-button @click="clickEC" style=" color:#0099FF; border: #0099FF 2px solid;margin-left: 20px;" size="small" shape="circle">
                <a-icon type="plus" />
              </a-button>
            </div>
            <div  >
              <span  style="color: #00CC33">{{item.name}}</span>
            </div>
            <div v-if="fileJudge === '1'">
              <a-icon type="close" style="color: #CC0000" /><span style="color: #CC0000">上传失败</span>
            </div>
          </div>
        </a-form-model-item>

      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click.stop="()=>{editCover=false; spinning=false}">
          取消
        </a-button>
        <a-button type="primary" @click="editCoverOK">
          确认
        </a-button>
      </div>
    </a-drawer>
    </a-spin>
  </div>
</template>

<script>
import {
  delCover,
  delPoster,
  getCoverList,
  getPosterList,
  postAddCover,
  postAddForm,
  postUpCover,
  postUpPoster,
  putEditCoverForm,
  putEditForm,
} from '@/service/image_api'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { downloadByBlob } from '@/utils/download';
import {update} from "@/utils/update";

export default {
  name: "ImageManagement",
    data() {
      return {
        zhCN,
        spinning:false,
        // 搜索作者
        HBnameValue:'',
        nameValue:'',

        labelCol: {span: 4},
        wrapperCol: {span: 20},
        visible: false,
        Cover:false,
        edit:false,
        download: false,
        editCover:false,
        HBcolor_value:'',
        color_value:'',
        items:[],
        // 获取海报列表
        PosterList: {},
        posterSearch:{},
        // 封面图
        CoverList:{},
        // 添加表单
        // 海报
        AddPoster: {
          img_url:'',
          img_color:'',
          img_files:[
            {
              url:'',
              size:'',
              name:''
            },
          ],
          type:'HB',
          remark:'',
          user_id:'',
          user_name:''
        },
        // 封面图
        AddCover:{
          img_url:'',
          img_color:'',
          img_files:[
            {
              url:'',
              size:'',
              name:''
            }
          ],
          type:'BANNER',
          remark:'',
          user_id:'',
          user_name:''
        },
        // 编辑表单
        EditPoster:{
        },
        EditCoverForm: {

        },
        downloadImg:{

        },
        dImg:{

        },
        rules: {
          img_color:[
            {required: true, message: '请选择主色值', trigger: 'blur' }
          ],
          img_url:[
            { required: true, message: '需要上传图片',trigger: 'blur' }
          ],
          img_files:[
            { required: true, message: '请输入尺寸及上传源文件',trigger: 'blur' }
          ],
        },
        // 分页
        PosterPagination:{
          current: 1,//当前页数
          total:0,
          pages:0,
          },
        CoverPagination:{
          current: 1,//当前页数
          total:0,
          pages:0,
        },
        user:'',
        urls:'',
        fileName:'',
        fileJudge:''
      };
    },
  async created() {
    this.$store.dispatch('setManageHeaderTitle',"图片素材管理")
    await this.getPoster(1,10)
    await this.getCover(1,10)
    const user_info = window.localStorage.getItem("user_info");
    this.AddPoster.user_name =  JSON.parse(user_info).user_name
    this.AddCover.user_name = JSON.parse(user_info).user_name
    this.AddPoster.user_id = JSON.parse(user_info).id
    this.AddCover.user_id = JSON.parse(user_info).id

  },
  methods: {
    // 获取海报列表
    async getPoster(pageNumber,end,username,imgcolor){
      this.spinning = true
      const result=await getPosterList(pageNumber,end,username,imgcolor)
      if (result.code === 0){
        this.PosterList= result.data.rows
        this.PosterPagination.total= result.data.count
      }else {
        this.$message.error("获取错误，请联系管理员~"+result.message);
      }
      this.spinning = false
    },
    // 获取封面图列表
    async getCover(pageNumber,end,username,imgcolor){
      this.spinning = true
      const result=await getCoverList(pageNumber,end,username,imgcolor)
      if (result.code === 0){
        this.CoverList= result.data.rows
        this.CoverPagination.total= result.data.count
      }else {
        this.$message.error("获取错误，请联系管理员~"+result.message);
      }
      this.spinning = false
    },
    // 颜色搜索
    async ColorChange(){
      const imgcolor = this.HBcolor_value
      await this.getPoster(1,10,"",imgcolor)
      this.PosterPagination.current= 1
    },
    async CoverColor(){
      const imgcolor = this.color_value
      await this.getCover(1,10,"",imgcolor)
      this.CoverPagination.current= 1
    },
    // 海报搜索
     async PosterSearch(){
      const username = this.HBnameValue
       await this.getPoster(1,10,username)
       this.PosterPagination.current= 1
    },
    // 封面图搜索
     async CoverSearch(){
       const username = this.nameValue
       await this.getCover(1,10,username)
       this.CoverPagination.current= 1
     },
    // 分页
    async pageNumberChange(pageNumber,end){
      await this.getPoster(pageNumber,end)
      this.PosterPagination.current = pageNumber
      this.PosterPagination.pages = end
    },
    async onShowSizePoster(current, end) {
      await this.getPoster(current,end)
      this.PosterPagination.current = current
      this.PosterPagination.pages = end
    },
    async onShowSizeChange(current, end) {
      await this.getCover(current,end)
      this.CoverPagination.current = current
      this.CoverPagination.pages = end
    },
    async pageCoverPagination(pageNumber,end){
      await this.getCover(pageNumber,end)
      this.CoverPagination.current = pageNumber
      this.CoverPagination.pages = end
    },
    onChange() {
    },
    addPosters(){
      this.visible=true
      this.spinning = true
    },
    // 海报新增
    async addOK(){
      console.log(this.AddPoster)
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const addForm = await postAddForm(this.AddPoster)
          if (addForm.code === 0){
            await  this.getPoster(1,10)
            this.$refs.addItem.resetFields()
            this.$message.success("添加成功")
            this.visible=false
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
      this.spinning = true
    },
    // 封面图新增
    async addCover(){
      this.$refs.addCover.validate(async valid =>{
        if(valid){
          const addCover = await postAddCover(this.AddCover)
          if (addCover.code === 0){
            await  this.getCover(1,10)
            this.$refs.addCover.resetFields()
            this.$message.success("添加成功")
            this.Cover=false
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    // 上传海报源文件
    async UpPoster(addImg){
      let inputDOM = document.getElementById(addImg);
      let file= inputDOM.files[0];
      if( file !== undefined) {
        this.fileJudge = '0'
        let formData = new FormData()
        formData.append('file', file)
        const image = await postUpPoster(formData);
        if (image.code === 0) {
          this.AddPoster.img_files[addImg].name = file.name
          this.fileJudge = '0'
          this.AddPoster.img_files[addImg].url = image.data.url
          this.$message.success('上传成功')
        } else {
          this.fileJudge = '1'
          this.$message.error("上传失败");
        }
      }
    },
    // 上传海报缩略图
    async UpPosterThumbnail(){
      let inputDOM = this.$refs.Thumbnail;
      let file= inputDOM.files[0];
      let formData =new FormData()
      formData.append('file',file)
      const image = await postUpPoster(formData);
      this.AddPoster.img_url= image.data.url
      this.$message.success('上传成功')
    },
    // 编辑海报缩略图
    async editPosterImg(){
      let inputDOM = this.$refs.editPosterImg;
      let file= inputDOM.files[0];
      let formData =new FormData()
      formData.append('file',file)
      const image = await postUpPoster(formData);
      this.EditPoster.img_url= image.data.url
      this.$message.success('上传成功')
    },
    // 编辑海报源文件
    async editUpPoster(editPImg){
      let inputDOM = document.getElementById(editPImg);
      let file= inputDOM.files[0];
      if( file !== undefined) {
        this.fileJudge = '0'
        this.EditPoster.img_files[editPImg].name = file.name
        let formData = new FormData()
        formData.append('file', file)
        const image = await postUpPoster(formData);
        if (image.code === 0) {
          this.EditPoster.img_files[editPImg].url = image.data.url
          this.$message.success('上传成功')
        } else {
          this.fileJudge = '1'
          this.$message.error("上传失败");
        }
      }
    },
    // 上传封面图缩略图
    async UpCoverThumbnail(){
      let inputDOM = this.$refs.CoverThumbnail;
      let file= inputDOM.files[0];
      let formData =new FormData()
      formData.append('file',file)
      const image = await postUpCover(formData);
      this.AddCover.img_url= image.data.url
      this.$message.success('上传成功')
    },
    // 上传封面图源文件
    async UpCover(addCImg){
      let inputDOM = document.getElementById(addCImg);
      let file= inputDOM.files[0];
      if( file !== undefined) {
        this.fileJudge = '0'
        this.AddCover.img_files[addCImg].name = file.name
        let formData = new FormData()
        formData.append('file', file)
        const image = await postUpCover(formData);
        if (image.code === 0) {
          this.AddCover.img_files[addCImg].url = image.data.url
          this.$message.success('上传成功')
        } else {
          this.fileJudge = '1'
          this.$message.error("上传失败");
        }
      }
    },
    // 编辑封面缩略图
    async editCoverThumbnail(){
      let inputDOM = this.$refs.editCoverThumbnail;
      let file= inputDOM.files[0];
      let formData =new FormData()
      formData.append('file',file)
      const image = await postUpCover(formData);
      this.EditCoverForm.img_url= image.data.url
      this.$message.success('修改成功')
    },
    // 编辑封面图源文件
    async editUpCover(editCImg){
      let inputDOM = document.getElementById(editCImg);
      let file= inputDOM.files[0];
      if( file !== undefined) {
        this.fileJudge = '0'
        this.EditCoverForm.img_files[editCImg].name = file.name
        let formData = new FormData()
        formData.append('file', file)
        const image = await postUpPoster(formData);
        if (image.code === 0) {
          this.EditCoverForm.img_files[editCImg].url = image.data.url
          this.$message.success('上传成功')
        } else {
          this.fileJudge = '1'
          this.$message.error("上传失败");
        }
      }
    },
    // 点击展开下载框
    Download(ref){
      this.dImg = ref
      this.dImg = JSON.parse(JSON.stringify(ref))
      this.download=true
    },
    // 下载确定按钮
     downloadCodeImg(index){
      let url =  this.dImg.img_files[index].url;
      window.open(url,'文件')
      // downloadByBlob(url,"pic")
    },
    // 点击展开编辑框
    async Edit(ref){
      this.EditPoster = ref
      this.EditPoster = JSON.parse(JSON.stringify(ref))
      this.edit=true;
      this.spinning = true

    },
     async EditCover(ref){
        this.EditCoverForm = ref
        this.EditCoverForm = JSON.parse(JSON.stringify(ref))
        this.editCover=true;
        this.spinning = true
    },
    // 海报编辑
    editOK(){
      this.$refs.editItem.validate( async valid =>{
        if (valid){
          let editForm= this.EditPoster
          const EditItem = await putEditForm(editForm)
          if (EditItem.code === 0){
            await this.getPoster(1,10)
            this.$refs.editItem.resetFields();
            this.edit=false;
            this.$message.success('修改成功');
            this.edit=false;
          }else {
            this.$message.error( EditItem.message);
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    // 封面图编辑
    async editCoverOK(){
      this.$refs.editCoverItem.validate( async valid =>{
        if (valid){
          let editForm= this.EditCoverForm
          const EditItem = await putEditCoverForm(editForm)
          if (EditItem.code === 0){
            await this.getCover(1,10)
            this.$refs.editCoverItem.resetFields();
            this.editCover=false;
            this.$message.success('修改成功');
            this.editCover=false;
          }else {
            this.$message.error( EditItem.message);
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    // 删除
    async delImg(id){
          const delId = await delPoster(id)
          if (delId.code === 0){
            this.$message.success('删除成功');
            await this.getPoster(1,10)
          }
          else {
            this.$message.error('删除失败'+delId.message);
          }
    },
    async delCoverImg(id){
          const delId = await delCover(id)
          if (delId.code === 0){
            this.$message.success('删除成功');
            await this.getCover(1,10,)
          }
          else {
            alert('删除失败！'+delId.message)
          }

    },
    // 添加修改中的源文件 点击添加，删除一行输入框
    clickAddRecharge () {
      let ifTrue = true
      this.AddPoster.img_files.forEach(item => {
        if (item.size === '') {
          this.$message.warning('请完善尺寸')
          ifTrue = false
        }
      })
      if (ifTrue) {
        this.AddPoster.img_files.push({
          size: '',
          name:''
        })
      }
    },
    clickAddC () {
      let ifTrue = true
      this.AddCover.img_files.forEach(item => {
        if (item.size === '') {
          this.$message.warning('请完善尺寸')
          ifTrue = false
        }
      })
      if (ifTrue) {
        this.AddCover.img_files.push({
          size: '',
          name:''
        })
      }
    },
    clickEP () {
      let ifTrue = true
      this.EditPoster.img_files.forEach(item => {
        if (item.size === '') {
          this.$message.warning('请完善尺寸')
          ifTrue = false
        }
      })
      if (ifTrue) {
        this.EditPoster.img_files.push({
          size: '',name:''
        })
      }
    },
    clickEC () {
      let ifTrue = true
      this.EditCoverForm.img_files.forEach(item => {
        if (item.size === '') {
          this.$message.warning('请完善尺寸')
          ifTrue = false
        }
      })
      if (ifTrue) {
        this.EditCoverForm.img_files.push({
          size: '',name:''
        })
      }
    },
    deleteIndex (item, index) {
      this.AddPoster.img_files.splice(index, 1)
    },
    deleteEP (item, index) {
      this.EditPoster.img_files.splice(index, 1)
    },
    deleteC (item, index) {
      this.AddCover.img_files.splice(index, 1)
    },
    deleteEC (item, index) {
      this.EditCoverForm.img_files.splice(index, 1)
    },

    // 时间格式化
    dateFormat(fmt, date) {
      let ret="";
      date=new Date(date);
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (let k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(
              ret[1],
              ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
          )
        }
      }
      return fmt
    },
  },
}
</script>

<style lang="scss" scoped >
body{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
::v-deep .ant-tabs-bar{
  margin: 0 !important;
}

.main{
  width: 100%;
  background-color: #EEEEEE;
  padding-top: 10px;
  padding-bottom: 5px;
}
.has-color{
  background-color: white;
  padding: 20px;
  height: 80px;
  width: 98%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.image_list{
  background-color: white;
  padding: 20px;
  width: 98%;
  margin: 10px auto;
  height: 100%;
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_File{
  width: 150px;
  border: 1px #DDDDDD solid;
  border-radius:4px;
  height: 32px;
  background-image: url("../../assets/add_imgfile.png");
  background-size:70% 70%;
  background-position:center center;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_File:hover{
  background: #4a9bfa;
  opacity: 0.5;
}
.uploadImg:hover{
  background: #4a9bfa;
  opacity: 0.5;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;

}
.ant-card-body{
  padding: 15px!important;
}
.card-button{
  margin-top: 5px;
  justify-content: space-between;
  display: flex;
}
.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled:not(:first-child)::before {
  background-color: #d9d9d9 !important;
  border:none !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  border: #666666 2px solid !important;

}
</style>