import {get,post} from './base'

/**
 * 获取菜单
 * @returns {Promise<AxiosResponse<any> | void>}
 */
export function manageMenus(){
    return get("/api/admin/manageMenus")
}

