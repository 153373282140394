<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-header style="height:64px; padding: 0;display: flex">
<!--      <a-icon-->
<!--          class="trigger"-->
<!--          :type="collapsed ? 'menu-unfold' : 'menu-fold'"-->
<!--          @click="() => (collapsed = !collapsed)"-->
<!--      />-->
      <div class="logo">
        <span @click="()=>{this.$router.push('/')}" style="cursor: pointer">
          <!-- <img  style="width:170px;" src="../assets/yunicu-logo.png" alt="" v-if="this.imgChange == false">
          <img  style="width:170px;" src="../assets/yunLive.png" alt="" v-if="this.imgChange == true"> -->
          <img  style="width:170px;" src="../assets/yunLive.png" alt="">
        </span>
      </div>
      <a-menu
          theme="dark"
          mode="horizontal"
          :default-selected-keys="['/']"
          :style="{ lineHeight: '64px' }"
      >
        <a-menu-item key="/" >
          <router-link to="/">
            社区运营管理后台
          </router-link>
        </a-menu-item>

        <!-- <a-menu-item key="liveRoot">
          <router-link to="/liveRoot">
             直播运营管理后台
          </router-link>
        </a-menu-item> -->
        <a-menu-item key="https://yun-manage.yunicu.com/case/admin/" @click="go_case">
          <!--          <router-link to="/SubmissionProjectManagement">-->
          调研投稿管理后台
          <!--          </router-link>-->
        </a-menu-item>
        <!-- <a-menu-item key="caseRoot">
         <router-link to="/caseRoot">
           病例收集管理后台
         </router-link>
       </a-menu-item> -->
        <a-menu-item key="https://yun-manage.yunicu.com/appData/appHomepage" @click="go_appData">
          数据分析管理后台
        </a-menu-item>
        <a-menu-item key="DataExport" @click="go_DataExport">
          <!-- <router-link to="/appDataExport"> -->
             数据导出管理后台
          <!-- </router-link> -->
        </a-menu-item>
        <a-menu-item key="https://yun-manage.yunicu.com/service/web" @click="go_service_web">
          <!-- <router-link to="/appDataExport"> -->
          信息化运营管理
          <!-- </router-link> -->
        </a-menu-item>
      </a-menu>
      <ManageHeader></ManageHeader>
    </a-layout-header>

    <a-layout>
      <a-layout-sider v-model="collapsed"
                      :trigger="null"
                      collapsible
                      breakpoint="xl"
                      class="sider"
      >
        <manage-sider :collapsed="collapsed"/>
      </a-layout-sider>
      <a-layout-content
          :style="{ margin: '15px 16px', padding: '15px', background: '#fff', minHeight: '280px',
            overflowY:'scroll'}"
      >
        <router-view style="overflow-y: hidden"></router-view>
<!--        <div style="position:absolute;bottom: 15px">-->
<!--            云ICU后台管理系统©2021 Created by 优屏研发团队-->
<!--        </div>-->
      </a-layout-content>

    </a-layout>
  </a-layout>
</template>

<script>
import ManageSider from "@/components/ManageSider";
import ManageHeader from "@/components/ManageHeader";
import ManageFooter from "@/components/ManageFooter";

export default {
  name: "ManageRoot",
  components: {ManageFooter, ManageHeader, ManageSider},
  data(){
    return{
      collapsed: false,
    }
  },
  mounted() {
    const user_info = window.localStorage.getItem("user_info");
    this.userInfo =  JSON.parse(user_info)
  },
  methods:{
    go_appData(){
      // location.href="https://yun-manage.yunicu.com/appData/appHomepage?byId=" + this.userInfo.id
      location.href="https://yun-manage.yunicu.com/appData/appHomepage"
    },
    go_case(){
      location.href="https://yun-manage.yunicu.com/case/admin/"
    },
    go_DataExport(){
      location.href="https://yun-manage.yunicu.com/DataExport/appDataExport"
    },
    go_service_web () {
      location.href = 'https://yun-manage.yunicu.com/service/web'
    }
  }
}
</script>

<style>
.ant-layout{
  height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 40px;
  display: flex;
  align-items: center;
  margin: 15px;
}
.sider{
  overflow-y: scroll;
}
</style>
