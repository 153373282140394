import { get, post, put , del} from './base_yun'

/**
 * 获取已播放视频
 * @param params
 * @returns {Promise<unknown>}
 */
export function getVideo(params){
    return get('/api2/v1/admin/live-meet/18/video',{"id":params})
}

/**
 * 直播会议列表
 * @returns {Promise<unknown>}
 */
export function getLive_meet(page_no){
    return get("/api2/v1/admin/live-meet",{page_no,"page_size":10})
}

/**
 * 修改直播会议
 * @param id
 * @param data
 * @returns {Promise<unknown>}
 */
export function modifyLive_meet(id,data){
    return put('/api2/v1/admin/live-meet/'+id,data)
}

/**
 * 添加直播会议
 * @returns {Promise<unknown>}
 */
export function addLive_meet(data) {
    return post('/api2/v1/admin/live-meet', data)
}

/**
 * 上传文件
 * @param path
 */
export function uploadFile(path){
    return yunICUPost('/api/v1/admin/common/uploadFile?type=YUNICU',{file:path})
}

/**
 * 直播会议设置协议修改
 * @param live_id 直播会议ID
 * @param protocol_is_open 协议是否开启0：否1：是
 * @param protocol_content 协议内容
 */
export function alter_live_protocol(live_id,protocol_is_open,protocol_content){
    return put('/api2/v1/admin/live-meet/'+live_id+'/protocol',{protocol_is_open,protocol_content})
}

/**
 * 直播会议详情
 * @param live_id 直播会议ID
 * @returns {Promise<unknown | void>}
 * @constructor
 */
export function live_meet_details(live_id){
    return get('/api2/v1/admin/live-meet/'+live_id)
}

/**
 * 直播会议回放详情
 * @param live_id
 */
export function playback_details(live_id){
    return get('/api2/v1/admin/live-meet/'+live_id+'/playback')
}

/**
 * 直播视频修改标题
 * @param live_id
 * @param title
 * @returns {Promise<* | void>}
 */
export function alter_video_title(live_id,title){
    return put('/api2/v1/admin/live-meet-video/'+live_id,{'title':title})
}

/**
 * 删除会议回放视频
 * @param live_id
 * @returns {Promise<* | void>}
 * @private
 */
export function delete_video_(live_id){
    return put('/api2/v1/admin/live-meet-video/'+live_id,{is_delete:1})
}

/**
 * 下载观看记录
 * @param live_id
 * @returns {Promise<* | void>}
 * @private
 */
export function download_record_(live_id){
    return get('/api2/v1/admin/live-meet/'+live_id+'/export')
}

/**
 *直播会议选择会议列表
 * @param live_id
 * @returns {Promise<* | void>}
 */
export function convention(live_id){
    return get('/api2/v1/admin/live-meet/'+live_id+'/convention')
}

/**
 * 回放是否开启
 * @param live_id
 * @param i 0：否1：是
 * @returns {Promise<* | void>}
 * @private
 */
export function playback_open_close_(live_id,i){
    return put('/api2/v1/admin/live-meet/'+live_id,{'replay_is_open':i})
}

/**
 * 直播会议的id绑定大会的id
 * @param live_id 直播会议的id
 * @param convention_id 大会的id
 * @returns {Promise<* | void>}
 */
export function live_id_convention_id(live_id,convention_id){
    return put('/api2/v1/admin/live-meet/'+live_id,{'convention_id':convention_id})
}

/**
 * 时长修改
 * @param live_id
 * @param duration_is_open
 * @param duration_remark
 * @returns {Promise<* | void>}
 * @private
 */
export function alter_duration_(live_id,duration_is_open,duration_remark){
    return put('/api2/v1/admin/live-meet/'+live_id,{'duration_is_open':duration_is_open,'duration_remark':duration_remark})
}

export function alter_login_type(live_id,login_type){
    return put('/api2/v1/admin/live-meet/'+live_id,{'login_type':login_type})
}





/**
 * 设置倒计时开始
 * @param id 抽奖环节的ID
 * @param is_countdown 倒计时是否开始，1为未开始 0为开始
 * @returns {Promise<unknown | void>}
 */
export function raffles_set_countdown(id,is_countdown){
    return put('/api2/v1/admin/live-meet-raffles/'+id+'/set_countdown',{is_countdown:is_countdown})
}

/**
 *直播会议设置抽奖按钮是否显示与结束
 * @param id 直播会议的ID
 * @param raffle_is_open 抽奖活动是否开启0：否1：是
 * @param raffle_is_finish 抽奖活动是否结束0：否1：是
 * @returns {Promise<unknown | void>}
 */
export function set_raffle(id,raffle_is_open,raffle_is_finish){
    return put('/api2/v1/admin/live-meet/'+id+'/set_raffle',{'raffle_is_open':raffle_is_open,'raffle_is_finish':raffle_is_finish})
}




/**
 * 后台登录
 */
export function adminLogin(){
    return yunICUPost('/api/v1/admin/auth/login',{login_name:'lyp',password:'lyp20200120'})
}

//-----------------------------------------------镜像管理----------------------------------------------------

//镜像管理列表
export function getImages(){
    return get('/api2/v1/admin/images')
}
//镜像的详情-病案资料-诊断结果-投票内容
export function getImages_details(id){
    return get('/api2/v1/admin/images/'+id)
}

//镜像添加
export function addImages(){
    return get('/api2/v1/admin/images')
}

//--------------------------------------------------相关资料----------------------------

//相关资料列表
export function getImagesRelateInfo(id){
    return get('/api2/v1/admin/images-relate-info',{image_id:id,page_no:0,page_size:10})
}

//相关资料上移与下移
export function putUpOrDown(id,type){
    return put('/api2/v1/admin/images-relate-info/2/UpOrDown/'+id,{type:type})
}

//相关资料修改
/**
 *
 * @param id    	资料的ID
 * @param cover_url 封面图
 * @param title 标题
 * @param jump_url 跳转路径
 */
export function putAmendImagesRelateInfo(id,cover_url,title,jump_url){
    return put('/api2/v1/admin/images-relate-info/'+id,{cover_url:cover_url,title:title,jump_url:jump_url})
}

//相关资料添加

export function postImagesRelateInfo(image_id,cover_url,title,jump_url){
    return post('/api2/v1/admin/images-relate-info',{image_id:image_id,cover_url:cover_url,title:title,jump_url:jump_url})
}

//相关资料详情
export function getImagesRelateInfoDetails(id){
    return get('/api2/v1/admin/images-relate-info/'+id)
}

// 相关资料发布与下线
export function putImagesRelateInfoStatus(id){
    return get('/api2/v1/admin/images-relate-info/'+id+'/status')
}

//相关资料删除


// //------------------------------云ICU-----------------------------
//上传图片
export function upload(url,file){
    return post(url,{file:file})
}
//
// //登录后台
// export function adminLogin(login_name,password){
//   return yunPost('/api/v1/admin/auth/login',{login_name:'lyp',password:'lyp20200120'},null)
// }
// //登录
// export function login(){
//   return yunPost('/api/v1/app/login',{type:'phone',phone:'15169160632',password:'111111'})
// }
//
// //获取个人信息
// export function getinfo(){
//   return yunGet('/api/v1/app/my/info')

//======================活动抽奖管理============================
/**
 * 获取活动列表
 * @param page_no
 * @param page_size
 * @returns {Promise<* | void>}
 */
export function live_activityList(page_no){
    return get("/api2/v1/admin/live-activity?page_no="+page_no+"&page_size=10")
}

/**
 * 创建活动
 * @param data
 * @returns {Promise<* | void>}
 * @constructor
 */
export function AddLive_activity(data){
    return post("/api2/v1/admin/live-activity",data)
}

/**
 * 抽奖环节查询,根据活动id
 * @param data active_id=直播会议的ID&page_no=当前的页数&page_size=每页显示的条数
 * @returns {Promise<unknown | void>}
 */
export function live_meet_raffles_stream_id(data){
    return get('/api2/v1/admin/live-meet-raffles',data)
}

/**
 * 抽奖环节添加
 * @param data "activity_id": "活动的ID",
 "start_time": "开始时间",
 "last_time": "持续时间",
 "number_per_person": "每人抽奖次数",
 "slogan": "广告语",
 "rule": "规则",
 "content": [{
 "pic": "图片",
 "probability": "概率（0-1）",
 "count": "数量",
 "title": "奖品名称"
 }]
 * @returns {Promise<unknown | void>}
 */
export function add_live_meet_raffles(data){
    return post('/api2/v1/admin/live-meet-raffles',data)
}

/**
 * 抽奖环节详情
 * @param id 抽奖环节ID
 * @returns {Promise<* | void>}
 */
export function live_meet_raffles(id){
    return get('/api2/v1/admin/live-meet-raffles/'+id)
}

/**
 * 删除抽奖环节
 * @param id 	抽奖环节的ID
 * @returns {Promise<unknown | void>}
 */
export function del_live_meet_raffles(id){
    return del('/api2/v1/admin/live-meet-raffles/'+id)
}

/**
 * 抽奖环节修改
 * @param id
 * @param data
 * @returns {Promise<unknown | void>}
 */
export function alter_live_meet_raffles(id,data){
    return put('/api2/v1/admin/live-meet-raffles/'+id,data)
}



/**
 * 活动详情
 * @param id
 * @returns {Promise<* | void>}
 */
export function getLive_active(id){
    return get("/api2/v1/admin/live-activity/"+id)
}

/**
 *修改活动
 "is_open": "是否开启0：否 1：是",
 "is_finish": "是否结束0：否 1：是",
 "prize_total": "奖品总数",
 "title": "活动标题",
 "stream_ids": "直播室的ID字符串 以英文“；”隔开"
 * @param id 活动的ID
 * @param data
 * @returns {Promise<* | void>}
 */
export function alter_live_active(id,data){
    return put("/api2/v1/admin/live-activity/"+id,data)
}

/**
 * 删除活动，（会把活动对应的阶段和中奖信息删掉）
 * @param id
 * @returns {Promise<* | void>}
 */
export function del_live_active(id){
    return del("/api2/v1/admin/live-activity/"+id)
}
//医学会议-----------------------------------------------------------------------------
//新增医会议直播间
export function postCaseLive(data){
    return post("/api2/v1/admin/live-meet",data)
}