<template>
  <span>
    云ICU后台管理系统©2021 Created by 优屏研发团队
  </span>
</template>

<script>
export default {
  name: "ManageFooter"
}
</script>

<style scoped>

</style>