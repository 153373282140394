import{get,llget,llpost}from"./base";
//注册总量
export function getRegisterSum(){
    return get('/api2/registerNumberTotal')
}
//注册量身份总数
export function getRegisterStatusSum(){
    return get('/api/totalRegisterUser')
}
//今日注册量
export function getToday(){
    return get('/api2/registerNumberTotalThisYear')
}
//注册量每日人数
export function getTodayReg(){
    return get('/api/totalRegisterUserToday')
}
// 注册量（日）:registerNumberTotalDay
export function getRegisterDay(start_time,end_time){
    return get('/api2/registerNumberTotalDay',{"start_time":start_time,"end_time":end_time})
}
// 注册量（月）:registerNumberTotalMonth
export function getRegisterMoon(year){
    return get('/api2/registerNumberTotalMonth',{"year":year})
}
// 注册量（年）:registerNumberTotalYear
export function getRegisterYear(){
    return get('/api2/registerNumberTotalYear')
}


//注册量身份---日
export function getRegisterStatusDay(start_time,end_time){
    return get('/api/registerUserSelectDay',{"start_time":start_time,"end_time":end_time})
}
//注册量身份---月
export function getRegisterStatusMoon(year){
    return get('/api/registerUserSelectMoon',{"year":year})
}
//注册量身份---年
export function getRegisterStatusYear(){
    return get('/api/registerUserSelectYear')
}


// 新首页的接口 //
//1. 今日社区首页
export function getCommunity(){
	return get(`/v3/dataOverview/community`) 
}

//2. 今日直播人数
export function getLive(){
	return get(`/v3/dataOverview/live`) 
}

//3. 今日点播人数
export function getVod(){
	return get(`/v3/dataOverview/vod`) 
}

//4.今日文章人数
export function getArticle(){
	return get(`/v3/dataOverview/article`) 
}

//5. 今日文献人数
export function getGuide(){
	return get(`/v3/dataOverview/guide`) 
}

//6. 今日杂志人数
export function getMagazine(){
	return get(`/v3/dataOverview/magazine`) 
}

//7. 今日招聘人数
export function getRecruit(){
	return get(`/v3/dataOverview/recruit`) 
}

//8. 今日病例夹人数
export function getCase(){
	return get(`/v3/dataOverview/case`) 
}

// 9.今日注册人数
export function getRegister(){
	return get(`/v3/dataOverview/register`) 
}

// 10.本周APP人数
export function getApp(){
	return get(`/v3/dataOverview/app`) 
}

// 11.本周小程序人数
export function getMina(){
	return get(`/v3/dataOverview/mina`) 
}

// 12.本周官网人数
export function getWeb(){
	return get(`/v3/dataOverview/web`) 
}

// 13.本周客户端人数
export function getClient(){
	return get(`/v3/dataOverview/client`) 
}

// 14.预约未处理记录
export function getBookRecord(){
	return get(`/v3/dataOverview/bookRecord`) 
}

// 15.本周模拟考人数
export function getParcticeVisitors(){
	return get(`/v3/dataOverview/parcticeVisitors`) 
}

// 16.本周模拟考次数
export function getPractice(){
	return get(`/v3/dataOverview/practice`) 
}

// 17.本周模同步练习使用人数
export function getSyncExerciseVisitors(){
	return get(`/v3/dataOverview/syncExerciseVisitors`) 
}

// 18.本周模同步练习使用次数
export function getSyncExercise(){
	return get(`/v3/dataOverview/syncExercise`) 
}

// 19.今日搜索人数
export function getSearch(){
	return get(`/v3/dataOverview/search`) 
}

// 20.今日搜索人数
export function getMost(){
	return get(`/v3/dataOverview/most`) 
}

// 21.本周平台访问人数
export function getPlatform(){
	return get(`/v3/dataOverview/platform`) 
}