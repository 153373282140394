<template>
<div class="manageSider">
<!--  <div class="logo" >-->
<!--    <span @click="()=>{this.$router.push('/');this.select='[0]'}" style="cursor: pointer">-->
<!--      &lt;!&ndash; <span style="color: #F0F2F5" v-if="collapsed === false">云ICU后台管理系统</span> &ndash;&gt;-->
<!--      <img v-if="collapsed === false" style="width:160px;" src="../assets/yunicu-logo.png" alt="">-->
<!--      <img-->
<!--          v-if="collapsed"-->
<!--          height="30"-->
<!--          width="30"-->
<!--          src="@/assets/yunIcu-logo-y.png">-->
<!--    </span>-->
<!--  </div>-->
  <a-menu theme="dark" mode="inline"
          @openChange = "onOpenChange"
          :selectedKeys="[$route.path]"
          v-model=select
          :open-keys="openKeys"
  >
    <a-sub-menu :key="menu.id" v-for="menu in menus">
      <span slot="title">
<!--         <admin_con type="icon-settings"/>-->
        <span role="img" aria-label="form" class="anticon anticon-form">
              <div class="titleIcon">
                {{menu.name.slice(0,1)}}
              </div>
<!--          <img style="width: 20px;height: 20px;margin-right: 3px" :src="menu.icon === ''? img:menu.icon" alt="">-->

        </span>
        <span class="ant-pro-menu-item-title">{{ menu.name }}</span>
      </span>
      <a-menu-item :key="menuChildren.path"  v-for="menuChildren in menu.childMenu"  @click="menuTitle(menuChildren.name)">
        <router-link :to="menuChildren.path">
          <span>{{menuChildren.name}}</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</div>
</template>

<script>
import {admin_icon} from '@/utils/myIcon'
import {manageMenus} from '@/service/menu_api'
export default {
  name: "ManageSider",
  components:{
    admin_con: admin_icon,
  },
  props:["collapsed"],
  data(){
    return{
      img:'https://yunicu-1252497858.cos.ap-guangzhou.myqcloud.com/images/1620376125128_2.png',
      menus:'',
      select:['0'],
      openKeys:[],
      rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
    }
  },
  async created() {
    await this.getMenus();
    await this.change_select()
  },
  computed:{
    liveHistory(){
      return this.$store.state.liveMeetHistory
    }
  },

  watch:{
    $route(to,from){
      if (to.path==='/'){
        this.select=['0']
      }
    }
  },
  mounted() {

  },
  methods:{
    async change_select(){
      let path = this.$route.path;
      let select  = []
      select.push(path)
      this.select = select
      let menuData = this.menus;
      for (const menuDataKey in menuData) {
        for (const itemKey in menuData[menuDataKey].childMenu){
          let myList = [];
          if (menuData[menuDataKey].childMenu[itemKey].path === path) {
            myList.push(menuData[menuDataKey].childMenu[itemKey].parentId)
            this.openKeys =myList
            break;
          }
        }
      }
    },

    async getMenus(){
      const response = await manageMenus();
      if (response.code === 0){
        this.menus = response.data
      }
    },


    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    menuTitle(title){
      this.$store.dispatch('setManageHeaderTitle',title)
      // 判断是菜单点击进入
      this.liveHistory.isRouter = true
    },
  }
}
</script>

<style lang="scss" scoped>
.logo{
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleIcon{
  width: 25px;
  height: 25px;
  border-radius: 10px;
  margin-top: 6px;
  margin-right: 5px;
  background: linear-gradient(to right bottom, #FFB97D,#FE4259);
  color: #F7F9FA;
  text-align: center;
  font-size: 12px;
  line-height: 25px;
}
</style>
