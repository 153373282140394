import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueClipboards from 'vue-clipboard2'
import VueParticles from 'vue-particles'
Vue.config.productionTip = false
import tool from "./utils/tool";

import TcVod from 'vod-js-sdk-v6'
Vue.prototype.$TcVod = TcVod

import Print from 'vue-print-nb'
Vue.use(Print)

Vue.use(tool);
Vue.use(Antd)
Vue.use(VueClipboards);
Vue.use(VueParticles)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
