//正式头
const api_header = "https://yun-new-admin-api.yunicu.com";
const api_header_drug = "https://yun-new-admin-api.yunicu.com"; //药品管理正式头
const formula_header = "https://tool-h5.yunicu.com"; //医学公式h5页面地址-正式
// 外包-正式
const api_header_epiboly = "https://yunicu-api.yunicu.com";

// // 测试
// const api_header = "http://admin-dev-api.yunicu.com";
// //药品管理测试头
// const api_header_drug = "http://admin-dev-api.yunicu.com";
// //医学公式h5页面地址-测试
// const formula_header = "http://tool-h5-dev.yunicu.com"
// // 外包测试
// const api_header_epiboly = "http://yunicu-api.we-fire.cn";

export default {
  api_header,
  api_header_epiboly,
  api_header_drug,
  formula_header,
};
