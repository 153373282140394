import {get,post,put,del} from './base_yun'

/**
 * 获取设置直播列表
 * @param page_no
 * @param page_size
 * @param title
 * @param live_date
 * @returns {Promise<* | void>}
 */
export function getLiveList(page_no,page_size,title,live_date){
    return get("/api2/v1/admin/live-meet",{page_no,page_size,title,live_date})
}

/**
 * 添加直播间
 * @param data
 * @returns {Promise<* | void>}
 */
export function postSetLive(data){
    return post("/api2/v1/admin/live-meet",data)
}
//生成推流地址
export function postSetLiveAddress(data){
    return post("/api2/v1/admin/live-meet/setUrl",data)
}
export function putSetLive(id,data){
    return put("/api2/v1/admin/live-meet/"+id,data)
}
export function putSetLive_xieyi(id,data){
    return put("/api2/v1/admin/live-meet/"+id+"/protocol",data)
}
export function getplaybackList(id){
    return get("/api2/v1/admin/live-meet/" + id + "/playback")
}
export function putTitle(id,data){
    return put("/api2/v1/admin/live-meet-video/"+id,data)
}

//-------------------------------------直播抽奖活动（外包接口）-----------------------------------
/**
 * 直播抽奖活动列表
 * @returns {Promise<unknown | void>}
 */
// export function getActivityList(page_no,page_size){
//     return get("/api2/v1/admin/live-activity",{page_no,page_size})
// }

/**
 * 修改直播抽奖活动的显示与隐藏
 * @param id
 * @returns {Promise<* | void>}
 */
export function putSwitch(id,data){
    return put("/api2/v1/admin/live-meet/" + id + "/set_raffle",data)
}
// export function putSwitchOpen(id,data){
//     return put("/api2/v1/admin/live-meet/" + id + "/set_raffle",data)
// }

export function delActivityList(id){
    return del("/api2/v1/admin/live-activity/"+id)
}
export function postActivityList(data){
    return post("/api2/v1/admin/live-activity",data)
}
export function putActivityList(id,data){
    return put("/api2/v1/admin/live-activity/" +id,data)
}

/**
 * 抽奖环节添加
 * @param data
 * @returns {Promise<unknown | void>}
 */
export function getLiveMeetRaffles(data){
    return get("/api2/v1/admin/live-meet-raffles",data)
}

/**
 * 添加抽奖
 * @param data
 * @returns {Promise<* | void>}
 */
export function postLiveMeetRaffles(data){
    return post("/api2/v1/admin/live-meet-raffles",data)
}

export function getRafflesContent(id){
    return get("/api2/v1/admin/live-meet-raffles/"+id)
}

/**
 * 修改抽奖环节
 * @param id
 * @param data
 * @returns {Promise<* | void>}
 */
export function putRafflesContent(id,data){
    return put("/api2/v1/admin/live-meet-raffles/"+id,data)
}
//删除抽奖环节
export function delRafflesList(id){
    return del("/api2/v1/admin/live-meet-raffles/"+id)
}

export function putTimeStart(id,data){
    return put("/api2/v1/admin/live-meet-raffles/"+ id +"/set_countdown",data)
}

//获取直播间详情
export function getLiveOne(id){
    return get("/api2/v1/admin/live-meet/"+id)
}

/**
 * 修改答题模板
 * @param data
 * @returns {Promise<unknown | void>}
 */
export function putAnsList_answers(id,data){
    return put('/api2/v1/admin/live-meet/question/'+id+'/isOpen',data)
}
