/**
 * 图片素材管理
 */

import{put,post,get,del}from"./base";
import qs from "qs";
// 海报
export function getPosterList(current,end,username,imgcolor){
    return get('/v3/pagingqueryposter',{current,end,"type":'HB',username,imgcolor})
}
// 封面图
export function getCoverList(current,end,username,imgcolor){
    return get('/v3/pagingqueryplot',{current,end,"type":'BANNER',username,imgcolor})
}
// 上传海报图片
export function postUpPoster(file){
    return post('/v3/updateposter',file,{headers:{'Content-Type':'multipart/form-data'}})
}
// 上传封面图片
export function postUpCover(file){
    return post('/v3/updateplot',file,{headers:{'Content-Type':'multipart/form-data'}})
}
// 新增海报
export function postAddForm(data){
    return post('/v3/inserth',data)
}
// 新增封面图
export function postAddCover(data){
    return post('/v3/insertf',data)
}
// 删除海报
export function delPoster(id){
    return del('/v3/deleteposter' + '?id=' + id)
}
// 删除封面图
export function delCover(id){
    return del('/v3/deleteplot'+'?id=' + id)
}
// 编辑海报
export function putEditForm(data){
    return put('/v3/updateallh',data)
}
// 编辑封面图
export function putEditCoverForm(data){
    return put('/v3/updateallf',data)
}
