<template>
  <div class="AppHome">
    <!--格子 -->
    <div class="ActiveBar">
      <!-- 第一行 -->
      <a-row class="ActiveBarRow">
        <!-- 今日注册人数 -->
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 今日注册人数 -->
              <div class="ActiveBarTop">
                 <div class="ActiveBarTopIcon">
                   <p>今日注册人数</p>
                       <div>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                             <span> 1、包含全部身份的注册用户 <br>2、不包含今日注册且又注销的用户 <br> 3、截止至当前页面加载时间点</span>
                           </template>
                           <a-icon type="info-circle" style="color:#ababab"/>
                         </a-tooltip>
                       </div>
                 </div>
                 <!-- 填进axios的数据 -->
                 <div class="ActiveBarTwo">
                   <!-- axios数据 -->
                   <h1>{{ this.Register.dataOfToday }}</h1>
                   <div class="ActiveBarThree">
                     <p>比昨日</p>
                     <div :class="this.Register.dataOfToday  < this.Register.dataOfYesterday  ? 'up' : 'down'"></div>
                     {{ Math.abs(this.Register.dataOfToday - this.Register.dataOfYesterday) }}
                   </div>
                 </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                 <p>昨日注册人数
                   <span>{{ this.Register.dataOfYesterday }}</span>
                 </p>
                 <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
        <!-- 预约未处理记录 -->
        <a-col :span="6">
          <div style="padding-bottom: 20px;padding-right:20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px; ">
              <!-- 预约未处理记录 -->
              <div class="ActiveBarTop">
                <div class="ActiveBarTopIcon">
                  <p>预约未处理记录</p>
                     <div>
                       <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                         <template #title>
                           <span>1、本月为当前自然月<br>
                                 2、本月活跃度=社区首页本月访问人数/（当前注册总人数-身份不明人数）<br>
                                 3、截止至当前页面加载时间点</span>
                         </template>
                         <!-- <info-circle-outlined class="info-circle-outlined" /> -->
                         <!-- <a-button>Top</a-button> -->
                       </a-tooltip>
                     </div>
                </div>
                <!-- 填进axios的数据 -->
                  <div class="ActiveBarTwos">
                      <a @click="()=>{$router.push('/appointmentLog')}" > <h1 style="color:#2684dd">  {{ this.BookRecord.number }}</h1>  </a>
                       <div class="ActiveBarThree">
                         <p>比上月</p>
                         <div :class="this.BookRecord.dateOfThisMonth  < this.BookRecord.dataOfLastMonth  ? 'up' : 'down'"></div>
                       {{ Math.abs(this.BookRecord.dateOfThisMonth - this.BookRecord.dataOfLastMonth) }}
                       </div>
                  </div>
                   </div>
                   <!-- 昨日访问总人数 接口数据 -->
                   <div class="ActiveBarBottomFour">
                     <p>本月预约<span>{{ this.BookRecord.dateOfThisMonth }}</span>
                      </p>
                      <p class="pDisplay">上月预约<span>{{ this.BookRecord.dataOfLastMonth }}</span>
                      </p>                                                          
                     <!-- 接口数据填进去 -->
                   </div>
            </a-card>
          </div>
        </a-col>
        <!-- 今日搜索人数 -->
        <a-col :span="6">
          <div style="padding-bottom: 20px;padding-right:20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px; ">
              <!-- 今日搜索人数 -->
              <div class="ActiveBarTop">
                <div class="ActiveBarTopIcon">
                  <p>今日搜索人数</p>
                     <div>
                       <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                         <template #title>
                           <span>1、搜索人数=登录用户(id)搜索人数+游客(ip)搜索人数<br>
                                 2、游客(ip)搜索人数不含ip属于某登录用户的ip数<br>
                                 3、截止至当前页面加载时间点</span>
                         </template>
                         <a-icon type="info-circle" style="color:#ababab"/>
                       </a-tooltip>
                     </div>
                </div>
                <!-- 填进axios的数据 -->
                  <div class="ActiveBarTwos">
                       <h1>{{ this.Search.dataOfToday }}</h1>
                       <div class="ActiveBarThree">
                         <p style="margin-right:6px">昨日</p>
                         <!-- <div :class="this.BookRecord.dateOfThisMonth  < this.BookRecord.dataOfLastMonth  ? 'up' : 'down'"></div> -->
                       {{ this.Search.dataOfYesterday }}
                       </div>
                  </div>
                   </div>
                   <!-- 昨日访问总人数 接口数据 -->
                   <div class="ActiveBarBottomFour">
                     <p>登录用户<span>{{ this.Search.dataOfThisWeek}}</span>
                      </p>
                      <p class="pDisplay">游客<span>{{ this.Search.dataOfLastWeek }}</span>
                      </p>                                                          
                     <!-- 接口数据填进去 -->
                   </div>
            </a-card>
          </div>
        </a-col>
        <!-- 本周平台访问人数 -->
        <a-col :span="6">
          <div style="padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 本周平台访问人数 -->
              <div class="ActiveBarTop">
                 <div class="ActiveBarTopIcon">
                   <p>本周平台访问人数</p>
                       <div>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                             <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                           </template>
                           <a-icon type="info-circle" style="color:#ababab"/>
                         </a-tooltip>
                       </div>
                 </div>
                 <!-- 填进axios的数据 -->
                 <div class="ActiveBarTwo">
                   <!-- axios数据 -->
                   <h1>{{ this.Platform.dataOfThisWeek }}</h1>
                   <div class="ActiveBarThree">
                     <p>比上周</p>
                     <div :class=" this.Platform.dataOfThisWeek  < this.Platform.dataOfLastWeek   ? 'up' : 'down'"></div>
                     {{ Math.abs( this.Platform.dataOfThisWeek  - this.Platform.dataOfLastWeek) }}
                   </div>
                 </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                 <p>上周平台访问人数
                   <span> {{this.Platform.dataOfLastWeek}} </span>
                 </p>
                 <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
      </a-row>
      <!-- 第二行 -->
      <a-row class="ActiveBarRow">
        <!-- 本周APP访问人数 -->
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 本周APP访问人数 -->
              <div class="ActiveBarTop">
                 <div class="ActiveBarTopIcon">
                   <p>本周APP访问人数</p>
                       <div>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                             <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                           </template>
                           <a-icon type="info-circle" style="color:#ababab"/>
                         </a-tooltip>
                       </div>
                 </div>
                 <!-- 填进axios的数据 -->
                 <div class="ActiveBarTwo">
                   <!-- axios数据 -->
                   <h1>{{ this.App.dataOfThisWeek }}</h1>
                   <div class="ActiveBarThree">
                     <p>比上周</p>
                     <div :class="this.App.dataOfThisWeek  < this.App.dataOfLastWeek  ? 'up' : 'down'"></div>
                     {{ Math.abs(this.App.dataOfThisWeek - this.App.dataOfLastWeek) }}
                   </div>
                 </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                 <p>上周APP访问人数
                   <span>{{ this.App.dataOfLastWeek }}</span>
                 </p>
                 <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
        <!-- 小程序本周访问人数 -->
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 今日访问总人数 -->
              <div class="ActiveBarTop">
                <div class="ActiveBarTopIcon">
                  <p>小程序本周访问人数</p>
                     <div>
                       <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                         <template #title>
                           <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                         </template>
                         <a-icon type="info-circle" style="color:#ababab"/>
                       </a-tooltip>
                     </div>
                </div>
                <!-- 填进axios的数据 -->
                <div class="ActiveBarTwo">
                  <!-- 开整 -->
                  <h1>{{ this.Mina.dataOfThisWeek }}</h1>
                  <div class="ActiveBarThree">
                    <p>比上周</p>
                    <div :class="this.Mina.dataOfThisWeek  < this.Mina.dataOfLastWeek  ? 'up' : 'down'"></div>
                   {{ Math.abs(this.Mina.dataOfThisWeek - this.Mina.dataOfLastWeek) }}
                  </div>
                </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                <p>上周小程序访问人数 <span>{{ this.Mina.dataOfLastWeek }}</span> </p>
                <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
        <!-- 本周官网访问人数 -->
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 本周官网访问人数 -->
              <div class="ActiveBarTop">
                <div class="ActiveBarTopIcon">
                  <p>本周官网访问人数</p>
                     <div>
                       <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                         <template #title>
                           <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                         </template>
                         <a-icon type="info-circle" style="color:#ababab"/>
                       </a-tooltip>
                     </div>
                </div>
                <!-- 填进axios的数据 -->
                <div class="ActiveBarTwo">
                  <h1>{{ this.Web.dataOfThisWeek }}</h1>
                  <div class="ActiveBarThree">
                    <p>比上周</p>
                    <div :class="this.Web.dataOfThisWeek  < this.Web.dataOfLastWeek  ? 'up' : 'down'"></div>
                    {{ Math.abs(this.Web.dataOfThisWeek - this.Web.dataOfLastWeek) }}
                  </div>
                </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                <p>上周官网访问人数 <span>{{ this.Web.dataOfLastWeek }}</span></p>
                <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
        <!-- 本周电脑客户端访问人数 -->
        <a-col :span="6">
          <div style="padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 本周电脑客户端访问人数 -->
              <div class="ActiveBarTop">
                <div class="ActiveBarTopIcon">
                  <p>本周电脑客户端访问人数</p>
                    <div>
                      <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                        <template #title>
                          <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                        </template>
                        <a-icon type="info-circle" style="color:#ababab"/>
                      </a-tooltip>
                    </div>
                </div>
                <!-- 填进axios的数据 -->
                <div class="ActiveBarTwo">
                  <h1>{{ this.Client.dataOfThisWeek }}</h1>
                  <div class="ActiveBarThree">
                    <p>比上周</p>
                    <div :class="this.Client.dataOfThisWeek   < this.Client.dataOfLastWeek  ? 'up' : 'down'"></div>
                    {{ Math.abs(this.Client.dataOfThisWeek  - this.Client.dataOfLastWeek) }}
                  </div>
                </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                <p>上周电脑客户端访问人数 <span>{{ this.Client.dataOfLastWeek }}</span></p>
                <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
      </a-row>
      <!-- 第三行 -->
      <a-row class="ActiveBarRow">
        <!-- 今日社区首页访问人数 -->
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 今日社区首页访问人数 -->
              <div class="ActiveBarTop">
                 <div class="ActiveBarTopIcon">
                   <p>今日社区首页访问人数</p>
                       <div>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                             <span>1、无游客数据，因为游客无法访问社区 <br>2、截止至当前页面加载时间点</span>
                           </template>
                           <a-icon type="info-circle" style="color:#ababab"/>
                         </a-tooltip>
                       </div>
                 </div>
                 <!-- 填进axios的数据 -->
                 <div class="ActiveBarTwo">
                   <!-- axios数据 -->
                   <h1>{{ this.Community.dataOfToday }}</h1>
                   <div class="ActiveBarThree">
                     <p>比昨日</p>
                     <div :class="this.Community.dataOfToday  < this.Community.dataOfYesterday  ? 'up' : 'down'">
                    </div>
                    {{ Math.abs(this.Community.dataOfToday - this.Community.dataOfYesterday) }}
                   </div>
                 </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                 <p>昨日社区首页访问人数
                   <span>{{ this.Community.dataOfYesterday }}</span>
                 </p>
                 <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
        <!-- 今日直播访问人数 -->
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 今日直播访问人数 -->
              <div class="ActiveBarTop">
                <div class="ActiveBarTopIcon">
                  <p>今日直播模块访问人数</p>
                     <div>
                       <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                         <template #title>
                           <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                         </template>
                         <a-icon type="info-circle" style="color:#ababab"/>
                       </a-tooltip>
                     </div>
                </div>
                <!-- 填进axios的数据 -->
                <div class="ActiveBarTwo">
                  <!-- 开整 -->
                  <h1>{{ this.Live.dataOfToday }}</h1>
                  <div class="ActiveBarThree">
                    <p>比昨日</p>
                    <div  :class="this.Live.dataOfToday  < this.Live.dataOfYesterday  ? 'up' : 'down'"></div>
                    {{ Math.abs(this.Live.dataOfToday - this.Live.dataOfYesterday) }}
                  </div>
                </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                <p>昨日直播模块访问人数 <span>{{ this.Live.dataOfYesterday }}</span> </p>
                <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
        <!-- 今日点播访问人数 -->
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 今日点播访问人数 -->
              <div class="ActiveBarTop">
                <div class="ActiveBarTopIcon">
                  <p>今日点播模块访问人数</p>
                     <div>
                       <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                         <template #title>
                           <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                         </template>
                         <a-icon type="info-circle" style="color:#ababab"/>
                       </a-tooltip>
                     </div>
                </div>
                <!-- 填进axios的数据 -->
                <div class="ActiveBarTwo">
                  <h1>{{ this.Vod.dataOfToday }}</h1>
                  <div class="ActiveBarThree">
                    <p>比昨日</p>
                    <div  :class=" this.Vod.dataOfToday  < this.Vod.dataOfYesterday  ? 'up' : 'down'"></div>
                   {{ Math.abs( this.Vod.dataOfToday - this.Vod.dataOfYesterday) }}
                  </div>
                </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                <p>昨日点播模块访问人数 <span>{{ this.Vod.dataOfYesterday }}</span></p>
                <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
        <!-- 今日咨询访问人数 -->
        <a-col :span="6">
          <div style="padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 今日文章访问人数 -->
              <div class="ActiveBarTop">
                <div class="ActiveBarTopIcon">
                  <p>今日文章模块访问人数</p>
                     <div>
                       <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                         <template #title>
                           <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                         </template>
                         <a-icon type="info-circle" style="color:#ababab"/>
                       </a-tooltip>
                     </div>
                </div>
                <!-- 填进axios的数据 -->
                <div class="ActiveBarTwo">
                  <h1>{{ this.Consult.dataOfToday }}</h1>
                  <div class="ActiveBarThree">
                    <p>比昨日</p>
                    <div  :class=" this.Consult.dataOfToday  < this.Consult.dataOfYesterday   ? 'up' : 'down'"></div>
                   {{ Math.abs( this.Consult.dataOfToday - this.Consult.dataOfYesterday ) }}
                  </div>
                </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                <p>昨日文章模块访问人数 <span>{{ this.Consult.dataOfYesterday  }}</span></p>
                <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
      </a-row>
      <!-- 第四行 -->
      <a-row class="ActiveBarRow">
        <!-- 今日文献访问人数 -->
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 今日文献访问人数 -->
              <div class="ActiveBarTop">
                 <div class="ActiveBarTopIcon">
                   <p>今日文献访问人数</p>
                       <div>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                             <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                           </template>
                           <a-icon type="info-circle" style="color:#ababab"/>
                         </a-tooltip>
                       </div>
                 </div>
                 <!-- 填进axios的数据 -->
                 <div class="ActiveBarTwo">
                   <!-- axios数据 -->
                   <h1>{{ this.Guide.dataOfToday }}</h1>
                   <div class="ActiveBarThree">
                     <p>比昨日</p>
                     <div :class="this.Guide.dataOfToday  < this.Guide.dataOfYesterday  ? 'up' : 'down'"></div>
                     {{ Math.abs(this.Guide.dataOfToday - this.Guide.dataOfYesterday) }}
                   </div>
                 </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                 <p>昨日文献访问人数
                   <span>{{ this.Guide.dataOfYesterday  }}</span>
                 </p>
                 <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
        <!-- 今日杂志访问人数 -->
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 今日杂志访问人数 -->
              <div class="ActiveBarTop">
                <div class="ActiveBarTopIcon">
                  <p>今日杂志访问人数</p>
                     <div>
                       <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                         <template #title>
                           <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                         </template>
                         <a-icon type="info-circle" style="color:#ababab"/>
                       </a-tooltip>
                     </div>
                </div>
                <!-- 填进axios的数据 -->
                <div class="ActiveBarTwo">
                  <!-- 开整 -->
                  <h1>{{ this.Magazine.dataOfToday }}</h1>
                  <div class="ActiveBarThree">
                    <p>比昨日</p>
                    <div  :class="this.Magazine.dataOfToday  < this.Magazine.dataOfYesterday  ? 'up' : 'down'"></div>
                   {{ Math.abs(this.Magazine.dataOfToday - this.Magazine.dataOfYesterday) }}
                  </div>
                </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                <p>昨日杂志访问人数 <span>{{ this.Magazine.dataOfYesterday }}</span> </p>
                <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
        <!-- 今日招聘访问人数 -->
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 今日招聘访问人数 -->
              <div class="ActiveBarTop">
                <div class="ActiveBarTopIcon">
                  <p>今日招聘访问人数</p>
                     <div>
                       <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                         <template #title>
                           <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                         </template>
                         <a-icon type="info-circle" style="color:#ababab"/>
                       </a-tooltip>
                     </div>
                </div>
                <!-- 填进axios的数据 -->
                <div class="ActiveBarTwo">
                  <h1>{{ this.Recruit.dataOfToday }}</h1>
                  <div class="ActiveBarThree">
                    <p>比昨日</p>
                    <div :class="this.Recruit.dataOfToday  < this.Recruit.dataOfYesterday  ? 'up' : 'down'"></div>
                   {{ Math.abs(this.Recruit.dataOfToday - this.Recruit.dataOfYesterday) }}
                  </div>
                </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                <p>昨日招聘访问人数 <span>{{ this.Recruit.dataOfYesterday}}</span></p>
                <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
        <!-- 今日病例夹访问人数 -->
        <a-col :span="6">
          <div style="padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 130px;">
              <!-- 今日病例夹访问人数 -->
              <div class="ActiveBarTop">
                <div class="ActiveBarTopIcon">
                  <p>今日病例夹访问人数</p>
                     <div>
                       <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                         <template #title>
                           <span>1、仅统计已登录的用户数 <br>2、截止至当前页面加载时间点</span>
                         </template>
                         <a-icon type="info-circle" style="color:#ababab"/>
                       </a-tooltip>
                     </div>
                </div>
                <!-- 填进axios的数据 -->
                <div class="ActiveBarTwo">
                  <h1>{{ this.Case.dataOfToday }}</h1>
                  <div class="ActiveBarThree">
                    <p>比昨日</p>
                    <div :class="this.Case.dataOfToday  < this.Case.dataOfYesterday ? 'up' : 'down'"></div>
                    {{ Math.abs(this.Case.dataOfToday - this.Case.dataOfYesterday) }}
                  </div>
                </div>
              </div>
              <!-- 昨日访问总人数 接口数据 -->
              <div class="ActiveBarBottom">
                <p>昨日病例夹访问人数 <span>{{ this.Case.dataOfYesterday }}</span></p>
                <!-- 接口数据填进去 -->
              </div>
            </a-card>
          </div>
        </a-col>
      </a-row>
      <!-- 第五行 -->
      <!-- <a-row class="ActiveBarRow">
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 280px;">
              <div class="ActiveBarTop">
                 <div class="ActiveBarTopIcon">
                   <p>本周模拟考使用人数</p>
                       <div>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                             <span>1、访问人数=登录用户(id)访问人数+游客(ip)访问人数 <br>2、截止至当前页面加载时间点</span>
                           </template>
                         </a-tooltip>
                       </div>
                 </div>
                 <div class="ActiveBarTwo">
                   <h1>{{ this.ParcticeVisitors.number }}</h1>
                   <div class="ActiveBarThree">
                     <p>比上周</p>
                     <div :class="this.ParcticeVisitors.number  < this.ParcticeVisitors.dataOfLastWeek  ? 'up' : 'down'"></div>
                     {{ Math.abs(this.ParcticeVisitors.number - this.ParcticeVisitors.dataOfLastWeek) }}
                   </div>
                 </div>
              </div>
              <div class="ActiveBarBottom">
                 <p>重症医学（中级）：
                   <span>{{ this.ParcticeVisitors.criticalMed   }}</span>
                 </p>
                 <p>重症医学（副高）：
                   <span>{{ this.ParcticeVisitors.criticalDeputy   }}</span>
                 </p>
                 <p>重症医学（正高）：
                   <span>{{ this.ParcticeVisitors.criticalSenior   }}</span>
                 </p>
                 <p>护理学（初级）：
                   <span>{{ this.ParcticeVisitors.nursing   }}</span>
                 </p>
                 <p>内科护理（中级）：
                   <span>{{ this.ParcticeVisitors.nursingMed   }}</span>
                 </p>
                 <p>内科护理（副高）：
                   <span>{{ this.ParcticeVisitors.nursingDeputy   }}</span>
                 </p>
                 <p>临床执业医师：
                   <span>{{ this.ParcticeVisitors.clinical  }}</span>
                 </p>
              </div>
            </a-card>
          </div>
        </a-col>
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 280px;">
              <div class="ActiveBarTop">
                 <div class="ActiveBarTopIcon">
                   <p>本周模拟考使用次数</p>
                       <div>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                             <span>1、访问人数=登录用户(id)访问人数+游客(ip)访问人数 <br>2、截止至当前页面加载时间点</span>
                           </template>
                         </a-tooltip>
                       </div>
                 </div>
                 <div class="ActiveBarTwo">
                   <h1>{{ this.Practice.number }}</h1>
                   <div class="ActiveBarThree">
                     <p>比上周</p>
                     <div :class="this.Practice.number  < this.Practice.dataOfLastWeek  ? 'up' : 'down'"></div>
                     {{ Math.abs(this.Practice.number - this.Practice.dataOfLastWeek) }}
                   </div>
                 </div>
              </div>
              <div class="ActiveBarBottom">
                 <p>重症医学（中级）：
                   <span>{{ this.Practice.criticalMed   }}</span>
                 </p>
                 <p>重症医学（副高）：
                   <span>{{ this.Practice.criticalDeputy   }}</span>
                 </p>
                 <p>重症医学（正高）：
                   <span>{{ this.Practice.criticalSenior   }}</span>
                 </p>
                 <p>护理学（初级）：
                   <span>{{ this.Practice.nursing   }}</span>
                 </p>
                 <p>内科护理（中级）：
                   <span>{{ this.Practice.nursingMed   }}</span>
                 </p>
                 <p>内科护理（副高）：
                   <span>{{ this.Practice.nursingDeputy   }}</span>
                 </p>
                 <p>临床执业医师：
                   <span>{{ this.Practice.clinical  }}</span>
                 </p>
              </div>
            </a-card>
          </div>
        </a-col>
        <a-col :span="6">
          <div style="padding-right:20px;padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 280px;">
              <div class="ActiveBarTop">
                 <div class="ActiveBarTopIcon">
                   <p>本周同步练习使用人数</p>
                       <div>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                             <span>1、访问人数=登录用户(id)访问人数+游客(ip)访问人数 <br>2、截止至当前页面加载时间点</span>
                           </template>
                         </a-tooltip>
                       </div>
                 </div>
                 <div class="ActiveBarTwo">
                   <h1>{{ this.SyncExerciseVisitors.number }}</h1>
                   <div class="ActiveBarThree">
                     <p>比上周</p>
                     <div :class="this.SyncExerciseVisitors.number  < this.SyncExerciseVisitors.dataOfLastWeek  ? 'up' : 'down'"></div>
                     {{ Math.abs(this.SyncExerciseVisitors.number - this.SyncExerciseVisitors.dataOfLastWeek) }}
                   </div>
                 </div>
              </div>
              <div class="ActiveBarBottom">
                 <p>重症医学（中级）：
                   <span>{{ this.SyncExerciseVisitors.criticalMed   }}</span>
                 </p>
                 <p>重症医学（副高）：
                   <span>{{ this.SyncExerciseVisitors.criticalDeputy   }}</span>
                 </p>
                 <p>重症医学（正高）：
                   <span>{{ this.SyncExerciseVisitors.criticalSenior   }}</span>
                 </p>
                 <p>护理学（初级）：
                   <span>{{ this.SyncExerciseVisitors.nursing   }}</span>
                 </p>
                 <p>内科护理（中级）：
                   <span>{{ this.SyncExerciseVisitors.nursingMed   }}</span>
                 </p>
                 <p>内科护理（副高）：
                   <span>{{ this.SyncExerciseVisitors.nursingDeputy   }}</span>
                 </p>
                 <p>临床执业医师：
                   <span>{{ this.SyncExerciseVisitors.clinical  }}</span>
                 </p>
              </div>
            </a-card>
          </div>
        </a-col>
        <a-col :span="6">
          <div style="padding-bottom: 20px;" :span="6">
            <a-card :bordered="false" style="min-width:220px;height: 280px;">
              <div class="ActiveBarTop">
                 <div class="ActiveBarTopIcon">
                   <p>本周同步练习使用次数</p>
                       <div>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                             <span>1、访问人数=登录用户(id)访问人数+游客(ip)访问人数 <br>2、截止至当前页面加载时间点</span>
                           </template>
                         </a-tooltip>
                       </div>
                 </div>
                 <div class="ActiveBarTwo">
                   <h1>{{ this.SyncExercise.number }}</h1>
                   <div class="ActiveBarThree">
                     <p>比上周</p>
                     <div :class="this.SyncExercise.number  < this.SyncExercise.dataOfLastWeek  ? 'up' : 'down'"></div>
                     {{ Math.abs(this.SyncExercise.number - this.SyncExercise.dataOfLastWeek) }}
                   </div>
                 </div>
              </div>
              <div class="ActiveBarBottom">
                 <p>重症医学（中级）：
                   <span>{{ this.SyncExercise.criticalMed   }}</span>
                 </p>
                 <p>重症医学（副高）：
                   <span>{{ this.SyncExercise.criticalDeputy   }}</span>
                 </p>
                 <p>重症医学（正高）：
                   <span>{{ this.SyncExercise.criticalSenior   }}</span>
                 </p>
                 <p>护理学（初级）：
                   <span>{{ this.SyncExercise.nursing   }}</span>
                 </p>
                 <p>内科护理（中级）：
                   <span>{{ this.SyncExercise.nursingMed   }}</span>
                 </p>
                 <p>内科护理（副高）：
                   <span>{{ this.SyncExercise.nursingDeputy   }}</span>
                 </p>
                 <p>临床执业医师：
                   <span>{{ this.SyncExercise.clinical  }}</span>
                 </p>
              </div>
            </a-card>
          </div>
        </a-col>
      </a-row> -->
    </div>
    <!-- 表格 -->
    <a-table
    :columns="columns"
    :data-source="data"
    bordered
    size="middle"
    style="background-color: #fff;"
     :row-key="(record) => record.id"
     :pagination="false"
    >
          <!-- 内容1悬浮框 -->
      <span slot="xuanfutitle" slot-scope="text,item">
      <!-- 悬浮框 -->
      <a-tooltip placement="topLeft">
        <template slot="title">
          {{ item.title }}
        </template>
        <span class="multi-ellipsis">{{ item.title }}</span>
      </a-tooltip>
      </span>
      <!-- 内容2悬浮框 -->
      <span slot="titleCopy" slot-scope="text,item">
      <a-tooltip placement="topLeft">
        <template slot="title">
          {{ item.titleCopy }}
        </template>
        <span class="multi-ellipsis">{{ item.titleCopy }}</span>
      </a-tooltip>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  getCommunity, getLive, getVod, getArticle, getGuide, getMagazine, getRecruit, getCase, getRegister, getApp, getMina, getWeb, getClient, getBookRecord,
  getParcticeVisitors,getPractice,getSyncExerciseVisitors,getSyncExercise,getSearch,getMost,getPlatform
} from "../../service/home_page";

const columns = [
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
    align:'center',
    width: 200,
    // fixed: 'right',
  },
  {
    title: '上周',
    //  width: 200,
    children: [
      {
        title: '内容',
        dataIndex: 'titleCopy',
        key: 'titleCopy',
        align: 'center',
        width: 200,
        scopedSlots: { customRender: 'titleCopy' } 
      },
      {
        title: '人数',
        dataIndex: 'dataOfLastWeek',
        key: 'dataOfLastWeek',
        align:'center',
        width: 200,
      },
    ],
  },
  {
    title: '本周',
    //  width: 300,
    children: [
      {
        title: '内容',
        dataIndex: 'title',
        key: 'title',
        align:'center',
        width: 200,
        scopedSlots: { customRender: 'xuanfutitle' } 
      },
      {
        title: '人数',
        dataIndex: 'dataOfThisWeek',
        key: 'dataOfThisWeek',
        align:'center',
        width: 200,
      },
    ],
  },
];

// const data = [];
// for (let i = 0; i < 100; i++) {
//   data.push({
//     // key: i,
//     // name: 'John Brown',
//     // age: i + 1,
//     // street: 'Lake Park',
//     // building: 'C',
//     // number: 2035,
//     // companyAddress: 'Lake Street 42vdv  ',
//     // companyhhhAddress: 'Lake Street 42',
//     // companyName: 'SoftLake Co',
//     // companyhhhName: 'SoftLake Co',
//     gender: 'M',
//   });
// }

export default {
  setup() {
    return {
    };
  },
  data() {
    return {
      data: [],
      columns,
      Community: '',
      Live: '',
      Vod: '',
      Consult: '',
      Guide: '',
      Magazine: '',
      Recruit: '',
      Case: '',
      Register: '',
      App: '',
      Mina: '',
      Web: '',
      Client: '',
      BookRecord: '',
      ParcticeVisitors: '',
      Practice: '',
      SyncExerciseVisitors: '',
      SyncExercise: '',
      Search: '',
      Platform:'',
    }
  },
  mounted() {
    this.getCommunity();
    this.getLive();
    this.getVod();
    this.getArticle();
    this.getGuide();
    this.getMagazine();
    this.getRecruit();
    this.getCase();
    this.getRegister();
    this.getApp();
    this.getMina();
    this.getWeb();
    this.getClient();
    this.getBookRecord();
    this.getParcticeVisitors();
    this.getPractice();
    this.getSyncExerciseVisitors();
    this.getSyncExercise();
    this.getSearch();
    this.getMost();
    this.getPlatform();
  },
  methods: {
    setup() {
      return {
        value3: ref(),
        locale: zhCN
      };
    },
    // 今日社区
    async getCommunity() {
      const response = await getCommunity()
      let Community = response.data;
      this.Community = Community;
      // console.log(this.Community)
    },
    // 今日直播
    async getLive() {
      const response = await getLive()
      this.Live = response.data;
      // console.log(this.Live)
    },
    // 今日点播
    async getVod() {
      const response = await getVod()
      this.Vod = response.data;
      // console.log(this.Vod)
    },
    // 今日咨询
    async getArticle() {
      const response = await getArticle()
      this.Consult = response.data; //咨询换文章（article）
      // console.log("哈哈哈",this.Consult)
    },
    // 今日文献
    async getGuide() {
      const response = await getGuide()
      this.Guide = response.data;
      // console.log(this.Guide)
    },
    // 今日杂志
    async getMagazine() {
      const response = await getMagazine()
      this.Magazine = response.data;
      // console.log(this.Magazine)
    },
    // 今日招聘
    async getRecruit() {
      const response = await getRecruit()
      this.Recruit = response.data;
      // console.log(this.Recruit)
    },
    // 今日招聘
    async getCase() {
      const response = await getCase()
      this.Case = response.data;
      // console.log(this.Case)
    },
    // 今日注册
    async getRegister() {
      const response = await getRegister()
      this.Register = response.data;
      // console.log(this.Register)
    },
    // App本周
    async getApp() {
      const response = await getApp()
      this.App = response.data;
      // console.log(this.App)
    }, 
    // 小程序本周
    async getMina() {
      const response = await getMina()
      this.Mina = response.data;
      // console.log(this.Mina)
    }, 
    // 官网本周
    async getWeb() {
      const response = await getWeb()
      this.Web = response.data;
      // console.log(this.Web)
    }, 
    // 客户端本周
    async getClient() {
      const response = await getClient()
      this.Client = response.data;
      // console.log(this.Client)
    }, 
    // 预约未处理
    async getBookRecord() {
      const response = await getBookRecord()
      this.BookRecord = response.data;
      // console.log(this.BookRecord)
    }, 
    // 本周模拟考使用人数
    async getParcticeVisitors() {
      const response = await getParcticeVisitors()
      this.ParcticeVisitors = response.data;
      // console.log(this.ParcticeVisitors)
    },
    // 本周模拟考使用次数
    async getPractice() {
      const response = await getPractice()
      this.Practice = response.data;
      // console.log(this.Practice)
    },
    // 本周同步练习使用人数
    async getSyncExerciseVisitors() {
      const response = await getSyncExerciseVisitors()
      this.SyncExerciseVisitors = response.data;
      // console.log(this.SyncExerciseVisitors)
    },
    // 本周同步练习使用次数
    async getSyncExercise() {
      const response = await getSyncExercise()
      this.SyncExercise = response.data;
      // console.log(this.SyncExercise)
    },
    // 今日搜索人数
    async getSearch() {
      const response = await getSearch()
      this.Search = response.data;
      // console.log(this.Search)
    },
    // 本周平台访问人数
    async getPlatform() {
      const response = await getPlatform()
      this.Platform = response.data;
      // console.log(this.Platform)
    },
    // 表格
    async getMost() {
      const response = await getMost()
      this.data = response.data;
      // console.log(this.data)
    },

  }
}

</script>

<style lang="scss" scoped>
.AppHome {
  background-color: #f0f2f5;
  // height: 99%;
   margin: -15px;
}

:deep(.ant-layout,.ant-layout *){
    box-sizing: border-box;  
    font-weight: 600; 
}

p,
h1,
h2,
h3,
h4,
h5,
html {
  margin: 0;
  padding: 0;
  margin-bottom: 0;
  box-sizing: border-box;
}

.ActiveBarRow {
  display: flex;
  justify-content: space-between;

}

:deep(#components-a-tooltip-demo-placement) .ant-btn {
  width: 70px;
  text-align: center;
  padding: 0;
  margin-right: 8px;
  margin-bottom: 8px;
}

.ActiveBarTop {
  /* 颜色全部都要改 */
  border-bottom: 2px solid #f2f2f2;

  p {
    font-size: 13px;
    color: #adb0b1;
  }

  .ActiveBarTopIcon {
    display: flex;
    justify-content: space-between;

    .info-circle-outlined {
      color: #ababab;
    }
  }
}

.ActiveBarTwo {
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 30px;
  }
}
.ActiveBarTwos {
  display: flex;
  justify-content: space-between;

  h1 {
    font-size:30px;
  }
}

.multi-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.ActiveBarThree {
  display: flex;
  align-items: center;
}
.ActiveBarBottomFour {
  width:110%;
  display: flex;
  justify-content: space-around;
  transform: translate(-16px,3px);
  span {
    // font-weight: 500;
    color: #5a5a5a;
    font-size: 14px;
    margin-left:5px
  }
  // .pDisplay{
  //   transform: translateX(6px);
  // }
}
.ActiveBarBottomFour p {
  color: #adb0b1;
  font-size: 13px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  // margin-top: 1.5px;
  margin-top: 5px;
}


.ActiveBarBottom p {
  color: #adb0b1;
  margin-top: 5px;
  font-size: 14px;
  transform: translateY(3px);

  span {
    // font-weight: 500;
    color: #5a5a5a;
    font-size: 14px;
  }
}

#dayContainer{
      display: flex;
      justify-content: space-between;
}

#weekContainer{
  display: flex;
  justify-content: space-between;
}

#monthContainer{
  display: flex;
  justify-content: space-between;
}


.DayVisitsPicture {
  width: 100%;
  margin: auto;
  margin-bottom: 19px;
  // margin-bottom: ;

  .DayVisits {
    width: 100%;
    background-color: #ffffff;
    height: 54px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    padding-left: 20px;
    position: relative;

    h2 {
      font-size: 17px;
      font-weight: 600;
    }

    .dataPicker {
      position: absolute;
      right: 20px;
    }
  }
}

.DayVisitsPictureTwo {
  width: 100%;
  margin: auto;
  margin-bottom: 19px;
  display: flex;
  justify-content: space-between;
  // margin-bottom: ;

  .DayVisitsTwo {
    width: 100%;
    background-color: #ffffff;
    height: 54px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    padding-left: 20px;
    position: relative;

    h2 {
      font-size: 17px;
      font-weight: 600;
    }

    .dataPicker {
      position: absolute;
      right: 20px;
    }
  }
}

.DayVisitsThree {
  width: 100%;
  background-color: #ffffff;
  height: 54px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: relative;

  h2 {
    font-size: 17px;
    font-weight: 600;
  }

  .dataPicker {
    position: absolute;
    right: 20px;
  }
}

.up {
  width: 0px;
  height: 0px;
  border: 5px solid transparent;
  /*以下四个样式对应四种三角形，任选其一即可实现*/
  border-top-color: rgb(246 54 40);
  margin: 0 10px;
  transform: translateY(5px);
}

.down {
  width: 0px;
  height: 0px;
  border: 5px solid transparent;
  /*以下四个样式对应四种三角形，任选其一即可实现*/
  // border-top-color: lightseagreen;
  /* border-left-color:lightseagreen; */
  // border-right-color: lightseagreen;
  border-bottom-color: #63d564;
  transform: translateY(-3px);
  // margin: 0 2px;
  margin: 0 10px;
}


.headBold .ant-table-header-column {
  font-weight: bold;   // 字体加粗
}

</style>
