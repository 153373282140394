const dev_header = 'http://192.168.31.100:10086'
const server_header = 'http://yun-new-admin-api.yunicu.com'

const yun_serve_header = 'https://yunicu-api.yunicu.com'
const use_header = server_header


const updateImageUrl = use_header+'/api/admin/common/update'
const updateFileUrl = use_header+'/api/admin/common/updateFile'
export default {
  updateImageUrl,
  updateFileUrl,
  server_header,
  yun_serve_header,
}
