<template>
  <div class="Recruitment">
    <a-spin :spinning="spinning">
      <div style="border-bottom: 10px solid #F0F2F5;height: 70px;padding-top: 15px;padding-left: 15px">
        年份: &nbsp;<a-date-picker
          v-model="yearQuarter"
          :disabled-date="disabledDate"
          :open='yearShowOne'
          format='YYYY'
          mode="year"
          placeholder="请选择"
          style="width: 10%;margin-right: 15px"
          @openChange="openChangeOne"
          @panelChange="panelChangeOne"/>
        标题:&nbsp;
        <a-input
            v-model="search_value"
            enter-button
            placeholder="请输入"
            style="width: 210px;margin-right: 40px;"/>
        状态: &nbsp;<a-radio-group default-value="1" style="margin-right: 40px" @change="tab_change">
        <a-radio-button value="1">
          已发布
        </a-radio-button>
        <a-radio-button value="2">
          已置顶
        </a-radio-button>
        <a-radio-button value="3">
          未发布
        </a-radio-button>
      </a-radio-group>
        <a-button type="primary" @click="search_getRecruitmentList">查询</a-button>
        <a-button style="margin-left: 10px" @click="reset">重置</a-button>
      </div>
      <a-button style="margin: 15px 0px 0 15px" type="primary" @click="()=>{this.addVisible = true}">新增</a-button>
      <a-popconfirm
          cancel-text="否"
          ok-text="是"
          style="margin-left: 10px"
          title="确定删除?"
          @confirm="removeTableData"
      >
        <a-button>删除</a-button>
      </a-popconfirm>
      <a-table
          :columns="tableColumns"
          :data-source="recruitmentList"
          :pagination=false
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :scroll="{ x: 1500, y: 600}"
          rowKey="r_id"
          size="middle"
          style="padding: 15px"
      >
<!--        序号-->
        <template slot="index" slot-scope="text,row,index">
          {{index + ((pagination.current - 1) * pagination.pageSize) + 1}}
        </template>
        <!--   标题   -->
        <span slot="title_link" slot-scope="text,record">
       <a-tag v-if="record.is_top === 1" color="#F5222D">
        置顶
      </a-tag>
        <span>{{ record.title }}</span>
      </span>
        <a-space slot="operation" slot-scope="text,record">
          <a-popconfirm
              cancel-text="取消"
              ok-text="确定"
              title="确定执行操作？"
              @confirm="change_is_show(record.r_id,record.is_show === 1?0:1)"
          >
            <a :disabled="record.recommendState && record.is_show === 1">{{ record.is_show === 1 ? '下架' : '上架' }}</a>
          </a-popconfirm>
          <a v-show="!is_true" @click="change_is_show(record.r_id, 'top', record.is_top === 1 ? 0 : 1)">{{
              record.is_top === 1 ? '取消置顶' : '置顶'
            }}</a>
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="getAlterRecruitment(record.r_id)">基本信息修改</a-menu-item>
              <a-menu-item key="2" @click="open_updateContent(record.r_id)"> 内容修改</a-menu-item>
            </a-menu>
            <a type="primary"> 修改
              <a-icon type="down"/>
            </a>
          </a-dropdown>
          <a @click="promotions(record)">推广</a>
        </a-space>
      </a-table>
      <div style="margin-top: 30px;padding-right: 15px;padding-left: 20px">
        <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{
            pagination.current
          }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
        <a-pagination
            v-model="pagination.current"
            :page-size="pagination.pageSize"
            :page-size-options="pageSizeOptions"
            :total="pagination.total"
            show-quick-jumper
            show-size-changer
            style="float: right;margin-bottom: 40px"
            @change="listChangePageNo"
            @showSizeChange="listChangePageNo"
        >
        </a-pagination>
      </div>
      <!--    新增对话框     -->
      <a-drawer :keyboard="false" :visible="addVisible" class="addVisible" title="新增" width="600" @close="addClose">
        <a-form-model
            ref="addFrom"
            style="margin-bottom: 40px"
            :labelCol="{ span: 5 }"
            :model="addFrom" :rules="FromRules" :wrapperCol="{ span: 15 }">
          <!--        封面图-->
          <!--        <a-form-model-item label="封面图" prop="image">-->
          <!--          <div class="updateFile">-->
          <!--            <input type="file" ref="addUpdateImage" class="container"-->
          <!--                   @change="ImageUpdate('add')"-->
          <!--            accept="image/*">-->
          <!--          </div>-->
          <!--        </a-form-model-item>-->
          <a-form-model-item label="logo" prop="image">
            <div>
              <div style="display: flex">
                <div class="uploadImg">
                  <input ref="add" class="upload_img" type="file" @change="ImageUpdate('add')"/>
                </div>
                <div class="upload_image">
                  <img v-show="addFrom.image" :alt="addFrom.image" :src="addFrom.image" style="width: 100%;height: 100%;"/>
                </div>
              </div>
              <span style="font-size: 12px;">请上传2M内的图片</span>
            </div>


          </a-form-model-item>
          <a-form-model-item label="封面图" prop="coverImg">
            <div>
              <div style="display:flex;">
                <div class="uploadImg">
                  <input ref="addCover" class="upload_img" type="file" @change="ImageUpdate('addCover')"/>
                </div>
                <div class="upload_image">
                  <img v-show="addFrom.coverImg" :alt="addFrom.coverImg" :src="addFrom.coverImg" style="width: 100%;height: 100%;"/>
                </div>
              </div>
              <span style="font-size: 12px;">注：建议尺寸500*500px，宽度不能超过500，大小不超过1M</span>
            </div>

          </a-form-model-item>
          <!--        <a-card class="image_container" style="width: 300px">-->
          <!--          预览图:-->
          <!--          <img v-if="addFrom.image" :src="addFrom.image" :alt="addFrom.image">-->
          <!--        </a-card>-->
          <!--      标题  -->
          <a-form-model-item label="标题" prop="title" style="padding-top: 10px">
            <a-input v-model="addFrom.title" placeholder="请输入"/>
          </a-form-model-item>
          <!--        医院-->
          <a-form-model-item label="医院" prop="hospital_name">
            <a-input v-model="addFrom.hospital_name" placeholder="请输入"/>
          </a-form-model-item>
          <!--        显示时间-->
          <!--          <a-form-model-item label="显示时间" prop="show_time">-->
          <!--            <a-date-picker v-model="addFrom.show_time" placeholder="请选择" style="width: 100%"/>-->
          <!--          </a-form-model-item>-->
          <!--        备注-->
          <a-form-model-item label="报名截止时间" prop="remark">
            <a-input v-model="addFrom.remark" placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item label="地点" prop="address">
            <a-input v-model="addFrom.address" placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item label="薪资" prop="salary">
            <a-input v-model="addFrom.salary" placeholder="请输入"/>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="addClose">
            取消
          </a-button>
          <a-button type="primary" @click="getAddRecruitment">
            提交
          </a-button>
        </div>
      </a-drawer>

      <!--    修改对话框-->
      <a-drawer :keyboard="false" :visible="alterVisible" class="addVisible" title="修改招聘"
                width="600"
                @close="alterClose">
        <a-form-model
            style="margin-bottom: 40px"
            ref="alterFrom"
            :labelCol="{ span: 5 }"
            :model="alterFrom" :rules="FromRules" :wrapperCol="{ span: 15 }">
          <!--        封面图-->
          <!--        <a-form-model-item label="封面图" prop="image">-->
          <!--          <div class="updateFile">-->
          <!--            <input type="file" ref="addUpdateImage" class="container"-->
          <!--                   @change="ImageUpdate('alter')"-->
          <!--                   accept="image/*">-->
          <!--          </div>-->
          <!--        </a-form-model-item>-->
          <a-form-model-item label="logo" prop="image">
            <div>
              <div style="display: flex">
                <div class="uploadImg">
                  <input ref="alter" class="upload_img" type="file" @change="ImageUpdate('alter')"/>
                </div>
                <div class="upload_image">
                  <img v-show="alterFrom.image" :alt="alterFrom.image" :src="alterFrom.image"
                       style="width: 100%;height: 100%;"/>
                </div>
              </div>
              <span style="width: 150px;height:0;font-size: 12px;">注：建议尺寸500*500px，宽度不能超过500，大小不超过1M</span>
            </div>


          </a-form-model-item>
          <a-form-model-item label="封面图" prop="coverImg">
            <div>
              <div style="display: flex">
                <div class="uploadImg">
                  <input ref="alterCover" class="upload_img" type="file" @change="ImageUpdate('alterCover')"/>
                </div>
                <div class="upload_image">
                  <img v-show="alterFrom.coverImg" :alt="alterFrom.coverImg" :src="alterFrom.coverImg"
                       style="width: 100%;height: 100%;"/>
                </div>
              </div>
              <span style="font-size: 12px;">注：建议尺寸500*500px，宽度不能超过500，大小不超过1M</span>
            </div>


          </a-form-model-item>
          <!--        <a-card class="image_container" style="width: 300px">-->
          <!--          预览图:-->
          <!--          <img v-if="alterFrom.image" :src="alterFrom.image" :alt="alterFrom.image">-->
          <!--        </a-card>-->
          <!--      标题  -->
          <a-form-model-item label="标题" prop="title" style="padding-top: 10px">
            <a-input v-model="alterFrom.title" placeholder="请输入"/>
          </a-form-model-item>
          <!--        医院-->
          <a-form-model-item label="医院" prop="hospital_name">
            <a-input v-model="alterFrom.hospital_name" placeholder="请输入"/>
          </a-form-model-item>
          <!--        显示时间-->
          <!--          <a-form-model-item label="显示时间" prop="show_time">-->
          <!--            <a-date-picker v-model="alterFrom.show_time" placeholder="请选择" style="width: 100%"/>-->
          <!--          </a-form-model-item>-->
          <!--        备注-->
          <a-form-model-item label="报名截止时间" prop="remark">
            <a-input v-model="alterFrom.remark" placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item label="地点" prop="address">
            <a-input v-model="alterFrom.address" placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item label="薪资" prop="salary">
            <a-input v-model="alterFrom.salary" placeholder="请输入"/>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="alterClose">
            取消
          </a-button>
          <a-button type="primary" @click="submitAlterRecruitment">
            提交
          </a-button>
        </div>
      </a-drawer>

      <!--    修改内容对话框-->
      <a-drawer :confirmLoading="spinning"
                :keyboard="false" :title="'内容修改ID:'+alter_content_r_id"
                :visible="contentVisible"
                width="700"
                @close="cententClose"
      >
        <a-spin :spinning="spinning">
          <!--      富文本编辑框-->
<!--          <div ref="editor"></div>-->
          <div style="border: 1px solid #ccc;">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="height: 500px; overflow-y: hidden;"
                v-model="html"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </a-spin>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="cententClose">
            取消
          </a-button>
          <a-button type="primary" @click="updateContent">
            确定
          </a-button>
        </div>
      </a-drawer>

      <!--    点击标题，显示对话框-->
      <a-modal
          :visible="list_content"
          @cancel="content_close"
      >
        <a-descriptions title="详情">
          <a-descriptions-item :span="3" label="标题">
            {{ alterFrom.title }}
          </a-descriptions-item>
          <a-descriptions-item :span="3" label="医院">
            {{ alterFrom.hospital_name }}
          </a-descriptions-item>
          <a-descriptions-item :span="3" label="显示日期">
            {{ alterFrom.show_time }}
          </a-descriptions-item>
          <a-descriptions-item :span="3" label="上传图片">
            <img :src="alterFrom.image" style="width: 200px;height: auto"/>
          </a-descriptions-item>
          <a-descriptions-item :span="3" label="报名截止时间">
            {{ alterFrom.remark }}
          </a-descriptions-item>
        </a-descriptions>
        <!--    去除a-model自带的取消和确认-->
        <div slot="footer"></div>
      </a-modal>
      <!--     推广 -->
      <a-drawer
          :body-style="{ paddingBottom: '80px' }"
          :visible="promotionsVisible"
          :width="500"
          title="推广"
          @close="promotionsClose"
      >
        <a-spin :spinning="promotionSpin">
          <div style="margin:20px">
            <a-table :columns="appColumns" :data-source="appData" :pagination="false" :row-key="record => record.code"
                     bordered>
          <span slot="is_open" slot-scope="text, record">
            <a-switch v-if="record.title == '社区首页'" v-model="record.is_open" checked-children="是" un-checked-children="否" @click="communityListSwitch(record.is_open,record)" default-checked />
            <a-switch v-else v-model="record.is_open" checked-children="是" default-checked un-checked-children="否" @click="AppListSwitch(record.is_open,record)"/>
          </span>
            </a-table>
          </div>
          <!-- <div style="margin:20px">
            <a-table :row-key="record => record.id" :columns="uniAppColumns" :data-source="uniAppData" bordered>
              <span slot="is_open" slot-scope="text, record">
                 <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否" @click="minaRecommend(record.id)" default-checked />
               </span>
            </a-table>
       </div> -->
        </a-spin>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="promotionsClose">
            关闭
          </a-button>
        </div>
      </a-drawer>
    </a-spin>
  </div>
</template>

<script>
import {
  addRecruitment,
  recruitmentList,
  alterRecruitment,
  recruitmentListById,
  alterRecruitmentContent, delRecruitmentTableData
} from "@/service/recruitment";
import {update} from "@/utils/update";
import moment from "moment";
import E from 'wangeditor'
import {
  delAppPromotion,
  getColumnVodsList,
  getAppPromotion,
  getcommunityPromotionofRecruit,
  postAppPromotion, postcommunityPromotion
} from "@/service/MedicalConference_y";
import {postminaRecommend} from "@/service/Recruiting";
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  name: "Recruitment",
  components: { Editor, Toolbar },
  data() {
    return {
      uniAppColumns:[
        {
          title: '小程序',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      uniAppData:[
        {
          id:1,
          title:'推荐页（首页）',
          is_open:false
        }
      ],
      selectedRowKeys: [],
      show: 1,
      selectInput: undefined,
      list_content: false,
      //加载框
      spinning: true,
      recruitmentList: [],
      tableColumns: [
        {
          title: '序号',
          fixed: 'left',
          width: 70,
          align: 'center',
          scopedSlots: {customRender: 'index'}
        },
        {
          dataIndex: 'title',
          title: '标题',
          width: '25%',
          scopedSlots: {customRender: 'title_link'},
        },
        {
          dataIndex: 'hospital_name',
          title: '医院',
          width: '10%'
        },
        {
          dataIndex: 'address',
          title: '地点',
          width: '10%'
        },
        {
          dataIndex: 'salary',
          title: '薪资',
        },
        // {
        //   dataIndex: 'show_time',
        //   title: '显示时间'
        // },
        // {
        //   dataIndex: 'image',
        //   title:'封面图',
        //   scopedSlots:{customRender: 'image'}
        // },
        {
          dataIndex: 'remark',
          title: '报名截止时间',
          width: '15%',
        },
        {
          dataIndex: 'create_time',
          align: 'center',
          title: '创建时间',
          width: '15%'
        },
        {
          dataIndex: 'operation',
          title: '操作',
          width: 200,
          fixed: 'right',
          scopedSlots: {customRender: 'operation'}
        }
      ],
      //表单验证规则
      FromRules: {
        coverImg: [
          {
            required: true,
            message: '请上传封面图',
            trigger: 'blur',
          },
        ],
        image: [
          {
            required: true,
            message: '请上传logo',
            trigger: 'blur',
          },
        ],
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur',
          },
        ],
        hospital_name: [
          {
            required: true,
            message: '请输入医院信息',
            trigger: 'blur'
          },
        ],
        show_time: [
          {
            required: true,
            message: '请选择时间',
            trigger: 'blur'
          },
        ],
        remark: [
          {
            required: true,
            message: '请输入报名截止时间',
            trigger: 'blur'
          },
        ],
        salary: [
          {
            required: true,
            message: '请输入薪资',
            trigger: 'blur'
          },
        ],
        address: [
          {
            required: true,
            message: '请输入地点',
            trigger: 'blur'
          },
        ],
      },
      //添加表单
      is_true: false,
      addVisible: false,
      addFrom: {
        // image:'https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/aa84bd90-1ed8-478e-b097-72cec56ece92.png'
        image: ''
      },
      //修改表单
      alterVisible: false,
      alterFrom: {},
      //内容相关
      contentVisible: false,
      //内容与编辑器
      //富文本显示内容编辑
      // 默认情况下，显示所有菜单
      editor_menus: [
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'link',
        'list',
        'todo',
        'justify',
        'quote',
        'emoticon',
        'image',
        'table',
        'splitLine',
        'undo',
        'redo',
      ],
      alter_content_r_id: 0,
      content: '',
      editor: null,
      //搜索输入框的内容
      search_value: "",
      //分页配置
      pagination: {
        //配置分页
        pageSize: 10,
        //总数
        total: 0,
        //默认的当前页数
        current: 1
      },
      promotionsVisible: false,
      appColumns: [
        {
          title: 'APP',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: {customRender: 'is_open'},
        },
      ],
      appData: [],
      columnListApp: [],
      promotionId: null,
      // subContentType:null,
      subContent2Type:null,
      promotionSpin: false,
      communityColumns: [
        {
          title: '社区',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: {customRender: 'is_open'},
        },
      ],
      communityData: [
        {
          id: 1,
          title: '首页',
          is_open: false,
          code: null,
        }
      ],
      yearQuarter: null,
      yearShowOne: false,
      pageSizeOptions: ['10', '20', '30', '40', '50'],

      //  富文本编辑器
      //   editor: null,
      html: '<p>hello</p>',
      toolbarConfig: {
        excludeKeys:["group-video"] //不显示上传视频
      },
      editorConfig: {
        placeholder: '请输入内容...',
        // autoFocus: false,
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 图片上传
          uploadImage: {
            server: 'https://yun-new-admin-api.yunicu.com/api/admin/common/update',
            fieldName: 'file',
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 10 * 1024 * 1024, // 10M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: [],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              // token: 'xxx',
              // otherKey: 'yyy'
              // file:''
            },
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,

            // 自定义增加 http  header
            headers: {
              // Accept: 'text/x-json',
              // otherKey: 'xxx'
            },

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: false,

            // 超时时间，默认为 10 秒
            timeout: 10 * 1000, //10 秒
            // 自定义插入图片
            customInsert(res, insertFn) {
              // 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
              insertFn(res.data.url, res.data.url, res.data.url);
            },
          },
        }
      },
      mode: 'default', // or 'simple'
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle', "招聘管理")
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  async mounted() {
    await this.getRecruitmentList()
  },
  methods: {
    async minaRecommend(){
      const data = {
        contentId:this.promotionId,
        contentType:'EDUCATION',
        // subContentType:this.subContent2Type,
      }
      const response = await postminaRecommend(data)
      if(response.code === 0){
        this.$message.success("操作成功！")
      }else {
        this.$message.warning(response.message)
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },

    cententClose() { // 内容修改关闭方法
      this.contentVisible = false
    },
    addClose() { // 新增取消方法
      this.addVisible = false
    },
    async removeTableData() { // 删除方法
      let ids = this.selectedRowKeys.join(',')
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请至少选择一条数据进行修改')
        return
      }
      let list = [];
      // console.log(this.selectedRowKeys)
      // return
      this.selectedRowKeys.forEach(item => {
        this.recruitmentList.some(item2 => {
          if (item2.r_id === item && item2.recommendState) {
            list.push(item2)
          }
          return item2.r_id === item
        })
      })
      if (list.length > 0) {
        this.$message.warning('包含已推广数据，无法删除')
        return
      }
      const response = await delRecruitmentTableData(ids)
      if (response.code === 0) {
        this.$message.success('删除成功')
        let data = {
          page_size: this.pagination.pageSize,
          is_top: this.is_top,
          is_show: this.show,
          title: this.search_value
        }
        await this.getRecruitmentList(data)
      } else {
        this.$message.warning(response.message)
      }
    },
    //获取id
    onSelectChange(selectedRowKeys) {
      // //显示是否被选中
      this.selectedRowKeys = selectedRowKeys;
    },
    reset() { // 重置按钮方法
      this.search_value = undefined
      this.yearQuarter = undefined
      let data = {
        is_show: this.show,
        is_top: this.is_top
      }
      this.pagination = {
        //配置分页
        pageSize: 10,
        //总数
        total: 0,
        //默认的当前页数
        current: 1
      }
      this.getRecruitmentList(data)
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearQuarter = value;
      this.yearShowOne = false;
    },
    disabledDate(current) { // 限制日期选择
      return current && moment(current).format('YYYY') > moment().format('YYYY')
    },
    //获取招聘列表
    async getRecruitmentList(data) {
      if (!data) {
        data = {
          is_show: this.show,
          title: this.search_value,
          page_size: this.pagination.pageSize,
          page_no: this.pagination.current
        }
      }
      //设置默认显示已发布
      if (data === null || data === undefined) {
        data = {
          is_show: 1,
        }
      }
      //加载
      this.spinning = true
      if (this.yearQuarter) {
        data.time = moment(this.yearQuarter).format('YYYY')
      }
      const response = await recruitmentList(data);
      if (response.code === 0) {
        this.recruitmentList = response.data.rows
        this.pagination.total = response.data.count
        // console.log("可以吗" + response)
      } else {
        this.recruitmentList = null
        this.$message.error("列表获取错误:" + response.message)
      }
      //放开加载
      this.spinning = false
    },

    //搜索功能
    async search_getRecruitmentList() {
      //加载
      this.spinning = true
      const data = {
        is_top: this.is_top,
        is_show: this.show,
        title: this.search_value,
        page_no: this.pagination.current,
        page_size: this.pagination.pageSize
      }
      await this.getRecruitmentList(data);
      //放开加载
      this.spinning = false
    },


    //修改显示隐藏
    async change_is_show(id, is_show, is_top) {
      //加载
      this.spinning = true
      let data
      if (is_show === 'top') {
        data = {
          r_id: id,
          is_top
        }
      } else {
        data = {
          r_id: id,
          is_show
        }
      }
      const response = await alterRecruitment(data);
      if (response.code === 0) {
        //刷新列表
        let data2 = {
          is_top: this.is_top,
          is_show: this.show,
          title: this.search_value,
          page_no: this.pagination.current,
          page_size: this.pagination.pageSize
        }
        await this.getRecruitmentList(data2)
        this.$message.success('操作成功')
      } else {
        this.$message.error("修改状态失败，请稍后尝试。" + response.message)
      }
      //放开加载
      this.spinning = false
    },
    //提交添加内容
    getAddRecruitment() {
      //放开加载
      this.spinning = false
      //非空判断
      this.$refs.addFrom.validate(async valid => {
        if (valid) {
          this.addFrom.show_time = moment(this.addFrom.show_time).format('YYYY-MM-DD')
          const response = await addRecruitment(this.addFrom);
          if (response.code === 0) {
            this.$message.success("添加成功！")
            //刷新列表
            await this.getRecruitmentList();
            //清空
            // this.$refs.addFrom.resetFields();
            //关闭弹框
            this.addVisible = false
          } else {
            this.$message.error("添加失败！" + response.message)
          }
        } else {
          this.$message.error("请完整填写后再尝试。")
        }
      })
      //放开加载
      this.spinning = false
    },
    //上传图片
    async ImageUpdate(type) {
      let inputDOM = this.$refs[type].files[0];
      let reader = new FileReader() // 读取文件对象
      reader.readAsDataURL(inputDOM) //读取文件并将文件以URL的形式保存在result属性中 base64格式
      reader.onload = ((e)=> {
        let result = e.target.result // 获取base64格式图片
        const image = new Image() // 创建图片对象
        image.onerror = (e) => {
          this.$message.warning('图片加载失败，请联系管理员')
        }
        image.src = result // 将生成的base64格式作为图片地址
        image.onload = (async () => {
          if (inputDOM.size > 1048576) {
            this.$message.warning('图片大小不能超过1M')
            return
          }
          if (image.width > 500) {
            this.$message.warning('图片宽度不能超过500')
            return
          }
          const response = await update(inputDOM);
          if (response.code === 0) {
            //  上传成功
            //判断赋值到添加还是修改(add,alter)
            if (type === "add") {
              this.addFrom.image = response.data.url
              this.$message.success('上传成功！')
              this.$refs.add.value = null
            } else if (type === "addCover") {
              this.$set(this.addFrom,'coverImg',response.data.url)
              this.$message.success('上传成功！')
              this.$refs.addCover.value = null
            }else if (type === "alter") {
              this.alterFrom.image = response.data.url
              this.$message.success('上传成功！')
              this.$refs.alter.value = null
            } else if (type === "alterCover") {
              this.$set(this.alterFrom,'coverImg',response.data.url)
              this.$message.success('上传成功！')
              this.$refs.alterCover.value = null
            }else {
              this.$message.error("赋值出错，请联系管理员~")
            }
          } else {
            //  上传失败
            this.$message.error("上传失败，请重试。" + response.message)
          }
        })
      })
    },

    //点击修改按钮
    async getAlterRecruitment(r_id) {
      //1.通过id获取详情
      const response = await recruitmentListById(r_id);
      if (response.code === 0) {
        //  赋值
        let alterFrom = response.data[0]
        alterFrom.show_time = moment(alterFrom.show_time, 'YYYY-MM-DD')
        this.alterFrom = alterFrom
        //  打开修改框
        this.alterVisible = true
      } else {
        //  弹出错误
        this.$message.error("获取详情失败，请稍后再试。" + response.message)
      }
    },
    //  确认修改
    async submitAlterRecruitment() {
      this.spinning = true
      this.$refs.alterFrom.validate(async valid => {
        if (valid) {
          //赋值
          let alterFrom = this.alterFrom;
          alterFrom.show_time = moment(alterFrom.show_time).format("YYYY-MM-DD")
          const response = await alterRecruitment(alterFrom);
          if (response.code === 0) {
            let data = {
              page_size: this.pagination.pageSize,
              is_top: this.is_top,
              is_show: this.show,
              title: this.search_value
            }
            await this.getRecruitmentList()
            this.$message.success("成功")
            this.alterVisible = false
            alterFrom.show_time = moment(alterFrom.show_time, "YYYY-MM-DD")
          } else {
            this.$message.error("添加失败" + response.message)
          }
        } else {
          this.$message.error("请填写完整后再修改！")
        }
      })
      this.spinning = false
    },
    alterClose() { // 修改取消方法
      this.alterVisible = false
    },
    //配置富文本编辑器
    async editor_config() {
      // 初始化富文本编辑器
      const editor = new E(this.$refs.editor)
      this.editor = editor
      // 设置编辑区域高度为 500px
      editor.config.height = 500
      //配置菜单栏
      editor.config.menus = this.editor_menus
      //限制一次最多能传几张图片
      editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片
      //配置上传图片接口
      editor.config.customUploadImg = async function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        const response = await update(resultFiles[0]);
        if (response.code === 0) {
          // 上传图片，返回结果，将图片插入到编辑器中
          //url: "https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/cdeb804c-b6a2-4610-b1bf-a517288ff3e1.png"
          insertImgFn(response.data.url)
        } else {
          window.alert("图片上传失败", response.message)
        }
      }
      //创建编辑器
      await editor.create()
    },

//  获取对应内容
    async getContent(id) {
      const response = await recruitmentListById(id);
      if (response.code === 0) {
        this.content = response.data.content
      }
    },
    //  打开内容修改的弹窗
    async open_updateContent(id) {
      this.spinning = true
      this.alter_content_r_id = id
      //获取内容
      const response = await recruitmentListById(id);
      if (response.code === 0) {
        //赋值
        const content = response.data[0].content
        //打开弹窗
        this.contentVisible = true
        //等待dom元素更新
        // this.$nextTick(async () => {
        //   //只初始化一次富文本编辑器
        //   if (!this.editor) {
        //     //初始化富文本编辑器
        //     await this.editor_config(content)
        //   }
        //   //创建编辑器后设置内容
        //   this.editor.txt.html(content)
        // })
        this.html = content
      } else {
        this.$message.error("获取内容错误。" + response.message)
      }
      this.spinning = false
    },
    //  提交内容的修改
    async updateContent() {
      this.spinning = true
      const data = {
        r_id: this.alter_content_r_id,
        // content: this.editor.txt.html()
        content: this.html
      }
      const response = await alterRecruitmentContent(data);
      if (response.code === 0) {
        this.$message.success("修改成功！")
        this.contentVisible = false
      } else {
        this.$message.error("内容修改失败，请稍后再试。" + response.message, response.erro)
      }
      this.spinning = false
    },
    //点击标题显示详情对话框
    list_link(row) {
      //  1.获取信息
      //  2.赋值
      row.show_date = moment(row.show_date).format("YYYY-MM-DD")
      this.alterFrom = row
      //  3.显示对话框
      this.list_content = true
    },
    //关闭标题详情对话框
    content_close() {
      this.list_content = false
    },
    /**
     * 改变栏目
     */
    async tab_change(e) {
      let key = e.target.value
      this.spinning = true
      switch (key) {
        case "1": {
          this.show = 1
          this.is_top = 0
          this.is_true = false
          break;
        }
        case "3": {
          this.show = 0
          this.is_top = 0
          this.is_true = true
          break;
        }
        default: {
          this.is_top = 1
          this.show = null
          this.is_true = false
          break;
        }
      }
      let data = {
        is_show: this.show,
        title: this.search_value
      }
      if (key === "2") {
        data.is_top = 1
      }
      await this.getRecruitmentList(data)
      this.spinning = false
    },
    /**
     * 改变页码
     */
    listChangePageNo(page, pageSize) {
      const data = {
        is_show: this.show,
        is_top: this.is_top,
        "page_no": page,
        page_size: pageSize
      }
      this.pagination.pageSize = pageSize
      this.getRecruitmentList(data)
    },
    async promotions(list) {
      console.log("list",list)
      this.promotionId = list.r_id
      this.subContent2Type = list.type
      //获取栏目
      this.getColumnList(list.r_id)
      //获取社区
      // this.getcommunityList(list.r_id)
      this.promotionsVisible = true
    },
    async getcommunityList(id) {
      this.promotionSpin = true
      const response = await getcommunityPromotionofRecruit(id)
      if (response.code === 0) {
        if (response.data.length && response.data[0].isOpen) {
          // this.communityData[0].is_open = true
          // this.communityData[0].code = response.data[0].code
          this.appData[0].is_open = true
          this.appData[0].code = response.data[0].code
          // this.communityData[0].code = 'sq_index'
        } else {
          this.appData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
      this.promotionSpin = false
    },
    async getColumnList(id) {
      this.promotionSpin = true
      this.appData = []
      const response = await getColumnVodsList()
      if (response.code === 0) {
        this.appData = response.data
        this.appData.unshift({
          id:1,
          title:'社区首页',
          is_open:false
        })
        await this.getAppList(id)
      } else {
        this.$message.warning(response.message)
      }
      this.promotionSpin = false
    },
    async getAppList(id) {
      const response = await getAppPromotion(id)
      if (response.code === 0) {
        this.columnListApp = response.data
        //对比
        let app = this.appData
        let columnList = this.columnListApp
        app.forEach((a, index) => {
          a.is_open = columnList.some(b => {
            return b.code === a.code
          })
          this.$set(app, index, a)
        })
        await this.getcommunityList(id)  //加载app中社区首页的数据
      } else {
        this.$message.warning(response.message)
      }
    },
    promotionsClose() {
      this.promotionsVisible = false
      this.appData = []
      this.promotionId = null
      this.getRecruitmentList()
      // this.subContentType = null
    },
    async AppListSwitch(isOpen, row) {
      //推广
      if (isOpen) {
        let data = [
          {
            contentId: this.promotionId,//内容id
            contentType: "COMMUNITY",//一级内容
            columnCode: row.code,//栏目
            // subContentType: this.subContentType//子级内容
            subContentType: 'RECRUITMENT'//子级内容
          }
        ]
        await this.postAppSwitch(data, row)
      } else { //关闭推广
        await this.delAppSwitch(this.promotionId, row.code, 'app', row)
      }
    },
    async postAppSwitch(data, row) {
      const response = await postAppPromotion(data)
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a, index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a)
            return
          }
        })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async delAppSwitch(id, code, type, row) {
      let data = {
        code: code,
        type: type
      }
      const response = await delAppPromotion(id, data)
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a, index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a)
            return
          }
        })
        this.$message.success("关闭成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async communityListSwitch(isOpen, row) {
      //推广
      if (isOpen) {
        let data = {
          // type:this.subContentType,//内容类型
          type: 'RECRUITMENT',//内容类型
          contentId: this.promotionId,//内容id`
          isOpen: 1, //是否启用：0：否 1：是
          isTop: 0
        }
        await this.postcommunitySwitch(data)
        //  重新获取社区列表
        this.getcommunityList(this.promotionId)
      } else { //关闭推广
        await this.delAppSwitch(this.promotionId, row.code, 'community', row)
      }
    },
    async postcommunitySwitch(data) {
      const response = await postcommunityPromotion(data)
      if (response.code === 0) {
        // //更新数据
        // this.appData.forEach((a,index) => {
        //   if (a.code === row.code) {
        //     this.$set(this.appData, index, a)
        //     return
        //   }
        // })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>

.addVisible {
  .updateFile {
    margin-top: 7px;
    height: 25px;
    width: 80px;
    background-image: url("../../assets/addImage.png");
    background-size: 80px 25px;
    cursor: pointer;

    .container {
      height: 25px;
      width: 80px;
      opacity: 0;
      cursor: pointer;
    }
  }

  .image_container {
    margin: -20px 0 10px 16%;

    img {
      max-width: 80%;
    }
  }
}

.Recruitment {
  margin: -15px;
}

.upload_image {
  border: #DDDDDD 2px dashed;
  width: 145px;
  height: 145px;
  float: left;
  margin-left: 10px;
}

.uploadImg {
  border: #DDDDDD 2px dashed;
  width: 145px;
  height: 145px;
  background-image: url("../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  float: left;
}

.upload_img {
  width: 145px;
  height: 145px;
  opacity: 0;
  cursor: pointer;
}
</style>
