import Vue from "vue";
import VueRouter from "vue-router";
import ManageRoot from "../views/ManageRoot";
import live from "../views/content/Live";
import Document from "../views/content/Document";
import Magazine from "../views/content/Magazine";
import Tool from "../views/content/Tool";
import Activity from "../views/content/Activity";
import Recruitment from "../views/content/Recruitment";
import Login from "../views/Login";
// import Test from '../views/Test'
import Home_page from "../views/content/HomePage";
import MedicalStaff from "../views/content/auth/MedicalStaff";
import MedicalStudent from "../views/content/auth/MedicalStudent";
import AuthOther from "../views/content/auth/AuthOther";
import Iottery from "../views/content/Iottery";
import Winners from "../views/content/Winners";
import ImageManagement from "@/views/content/ImageManagement";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // name: 'ManageRoot',
    component: ManageRoot,
    children: [
      {
        path: "/",
        name: "Home_page",
        component: Home_page,
      },
      {
        path: "workbench", //平台运营分析 - 工作台
        name: "Workbench",
        component: () => import("@/views/content/operationAnalysis/Workbench"),
      },
      {
        path: "supervisorRating", //平台运营分析 - 主管评分
        name: "SupervisorRating",
        component: () =>
          import("@/views/content/operationAnalysis/SupervisorRating"),
      },
      {
        path: "salesRating", //平台运营分析 - 销售评分
        name: "SalesRating",
        component: () =>
          import("@/views/content/operationAnalysis/SalesRating"),
      },
      {
        path: "liveRating", //平台运营分析 - 直播评分
        name: "LiveRating",
        component: () => import("@/views/content/operationAnalysis/LiveRating"),
      },
      {
        path: "live",
        name: "live",
        component: live,
      },
      //测试
      {
        path: "test",
        name: "test",
        component: () => import("@/views/Test"),
      },
      //直播管理
      {
        path: "liveMeet",
        name: "liveMeet",
        component: () => import("@/views/content/LiveMeet"),
      },
      //数据导出 -- App页面数据导出
      {
        path: "appDataExport",
        name: "AppDataExport",
        component: () => import("@/views/content/dataExport/AppDataExport"),
      },
      //数据导出 -- App内容数据导出
      {
        path: "appContentDataExport",
        name: "AppContentDataExport",
        component: () =>
          import("@/views/content/dataExport/AppContentDataExport"),
      },
      //共用示例图
      {
        path: "publicSample",
        name: "PublicSample",
        component: () => import("@/utils/PublicSample"),
      },
      //直播管理，点击数据跳转
      {
        path: "liveInformation",
        name: "liveInformation",
        component: () => import("@/views/content/liveInformation"),
      },
      //直播管理，直播问答管理
      {
        path: "liveAnswers",
        name: "liveAnswers",
        component: () => import("@/views/content/liveAnswers"),
      },
      //直播管理，查看答题记录
      {
        path: "liveAnswerRecord",
        name: "liveAnswerRecord",
        component: () => import("@/views/content/liveAnswerRecord"),
      },
      // // 在线会议 -- 会议咨询管理
      // {
      //   path: "ConferenceConsultation",
      //   name: "ConferenceConsultation",
      //   component: () => import("@/views/content/OnlineMeetings/ConferenceConsultation"),
      // },
      //设置直播间管理
      {
        path: "setLiveMeet",
        name: "setLiveMeet",
        component: () => import("@/views/content/setLiveMeet"),
      },
      //直播会议总览
      {
        path: "conferenceOverview",
        name: "ConferenceOverview",
        component: () =>
          import("@/views/content/liveManage/ConferenceOverview"),
      },
      //直播评分
      {
        path: "liveScoring",
        name: "LiveScoring",
        component: () => import("@/views/content/liveManage/LiveScoring"),
      }, //直播主办方管理
      {
        path: "sponsorManagement",
        name: "SponsorManagement",
        component: () => import("@/views/content/liveManage/SponsorManagement"),
      },
      //合作计划
      {
        path: "cooperationPlan",
        name: "PlanProgress",
        component: () => import("@/views/content/liveManage/PlanProgress"),
      },
      //计划进展查询
      {
        path: "progressQuery",
        name: "ProgressQuery",
        component: () => import("@/views/content/liveManage/ProgressQuery"),
      },
      //平台会议结算   ----我的会议管理
      {
        path: "conferenceSettlement",
        name: "ConferenceSettlement",
        component: () =>
          import("@/views/content/ConferenceSettlement/ConferenceSettlement"),
      },
      //会议资料管理   其他会议管理
      {
        path: "dataApproval",
        name: "DataApproval",
        component: () =>
          import("@/views/content/ConferenceSettlement/DataApproval"),
      },
      //上传查看明细
      {
        path: "detailsMeetings",
        name: "DetailsMeetings",
        component: () =>
          import("@/views/content/ConferenceSettlement/DetailsMeetings"),
      }, //会议资料管理
      {
        path: "meetingMaterial",
        name: "MeetingMaterial",
        component: () =>
          import("@/views/content/ConferenceSettlement/MeetingMaterial"),
      },
      {
        path: "document",
        name: "document",
        component: Document,
      },
      {
        path: "magazine",
        name: "magazine",
        component: Magazine,
      },
      {
        path: "tool",
        name: "tool",
        component: Tool,
      },
      {
        path: "activity",
        name: "activity",
        component: Activity,
      },
      {
        path: "iottery",
        name: "Iottery",
        component: Iottery,
      },
      {
        path: "winners",
        name: "Winners",
        component: Winners,
      },
      {
        path: "recruitment",
        name: "recruitment",
        component: Recruitment,
      },
      {
        //医护人员管理
        path: "/medicalStaff",
        name: "MedicalStaff",
        component: MedicalStaff,
      },
      {
        //医学生管理
        path: "/medicalStudent",
        name: "MedicalStudent",
        component: MedicalStudent,
      },
      {
        //医学公式管理-待上架公式管理
        path: "/pendingListing",
        name: "PendingListing",
        component: () =>
          import("@/views/content/MedicalFormulas/PendingListing.vue"),
      },
      {
        //医学公式管理-已上架公式管理
        path: "/formulaAlreadyListed",
        name: "FormulaAlreadyListed",
        component: () =>
          import("@/views/content/MedicalFormulas/FormulaAlreadyListed.vue"),
      },
      {
        // 医学公式管理 -- 使用记录
        path: "/MedicalFormulaUsageRecord",
        name: "MedicalFormulaUsageRecord",
        component: () =>
          import("@/views/content/MedicalFormulas/MedicalFormulaUsageRecord"),
      },
      {
        // 医学公式管理 -- 单位换算浏览记录
        path: "/UnitConversionBrowsingHistory",
        name: "UnitConversionBrowsingHistory",
        component: () =>
          import(
            "@/views/content/MedicalFormulas/UnitConversionBrowsingHistory"
          ),
      },
      {
        // 医学公式管理 --分类管理
        path: "/typeManage",
        name: "typeManage",
        component: () => import("@/views/content/MedicalFormulas/typeManage"),
      },
      {
        // 医学公式管理 --纠错记录
        path: "/errorRecord",
        name: "errorRecord",
        component: () => import("@/views/content/MedicalFormulas/errorRecord"),
      },
      {
        // 医学公式管理 --推荐管理
        path: "/MedicalRecommendManage",
        name: "MedicalRecommendManage",
        component: () => import("@/views/content/MedicalFormulas/MedicalRecommendManage"),
      },
      {
        //医护人员
        path: "/YHStaffManagement",
        name: "YHStaffManagement",
        component: () => import("@/views/content/auth/YHStaffManagement"),
      },
      {
        //医学生管理（拆分的）
        path: "/MedicalStudentManagement",
        name: "MedicalStudentManagement",
        component: () =>
          import("@/views/content/auth/MedicalStudentManagement"),
      },
      {
        //注销会员管理管理
        path: "/CancelMembership",
        name: "CancelMembership",
        component: () => import("@/views/content/auth/CancelMembership"),
      },
      {
        //其他/非医务从业者管理
        path: "/NonMedical",
        name: "NonMedical",
        component: () => import("@/views/content/auth/NonMedical"),
      },
      {
        //内部成员管理
        path: "/InternalMemberManagement",
        name: "InternalMemberManagement",
        component: () => import("@/views/content/auth/InternalMemberManagement"),
      },
      {
        //身份不明
        path: "/unknownIdentity",
        name: "unknownIdentity",
        component: () => import("@/views/content/auth/unknownIdentity"),
      },
      {
        //其他用户管理
        path: "/authOther",
        name: "AuthOther",
        component: AuthOther,
      },
      {
        // 注册会员管理
        path: "registerMember",
        name: "RegisterMember",
        component: () => import("@/views/content/auth/RegisterMember"),
      },
      {
        // 图片素材管理
        path: "imageManagement",
        name: "imageManagement",
        component: ImageManagement,
      },

      {
        // 医院管理
        path: "hospital",
        name: "Hospital",
        component: () => import("@/views/content/auth/Hospital"),
      },
      {
        // 学校管理
        path: "school",
        name: "School",
        component: () => import("@/views/content/auth/School"),
      },
      {
        // 质控平台账号管理
        path: "zkAuth",
        name: "zkAuth",
        component: () => import("@/views/content/auth/ZKAuth"),
      },
      {
        // 会员导入管理
        path: "memberImport",
        name: "MemberImport",
        component: () => import("@/views/content/auth/MemberImport"),
      },
      {
        // 会员认证
        path: "memberAudit",
        name: "memberAudit",
        component: () => import("@/views/content/auth/memberAudit"),
      },
      {
        // 会员审核管理
        path: "SeriousExpertReview",
        name: "SeriousExpertReview",
        component: () =>
          import(
            "@/views/content/MembershipReviewManagement.vue/SeriousExpertReview"
          ),
      },
      // {
      //   // 医生名单（兵力项目管理里修改，注册会员的新增和修改）
      //   path:'listPackaging',
      //   name:'ListPackaging',
      //   component:()=>import ('@/views/content/case/ListPackaging')
      // },
      {
        // 病例首页
        path: "caseHome",
        name: "caseHome",
        component: () => import("@/views/content/case/CaseHome"),
      },
      {
        // 病例管理
        path: "case",
        name: "Case",
        component: () => import("@/views/content/case/Case"),
      },
      {
        // 目标医院审核
        path: "targetHospitalAudit",
        name: "targetHospitalAudit",
        component: () => import("@/views/content/case/targetHospitalAudit"),
      },
      {
        // 病历图片打码管理
        path: "casePicturesCode",
        name: "CasePicturesCode",
        component: () => import("@/views/content/case/CasePicturesCode"),
      },
      {
        // 图片打码
        path: "caseCode",
        name: "CaseCode",
        component: () => import("@/views/content/case/CaseCode"),
      },
      {
        // 病例审批管理
        path: "caseAudit",
        name: "CaseAudit",
        component: () => import("@/views/content/case/CaseAudit"),
      },
      {
        // 病例审核管理
        path: "caseReview",
        name: "caseReview",
        component: () => import("@/views/content/case/caseReview"),
      },
      {
        // 病例审核管理
        path: "caseReviewTwo",
        name: "caseReviewTwo",
        component: () => import("@/views/content/case/caseReviewTwo"),
      },
      {
        // 病例审核管理--主动脉
        path: "caseAorta",
        name: "caseAorta",
        component: () => import("@/views/content/case/caseAorta"),
      },
      {
        // 病例审核管理--脓毒症
        path: "casePyohemia",
        name: "CasePyohemia",
        component: () => import("@/views/content/case/CasePyohemia"),
      },
      {
        // 病例审核管理--心率失常
        path: "caseArrhythmia",
        name: "CaseArrhythmia",
        component: () => import("@/views/content/case/CaseArrhythmia"),
      },
      {
        // 病例查看比伐芦定在冠心病患者PCI治疗中的应用真实世界研究临床观察表
        path: "chdCase",
        name: "chdCase",
        component: () => import("@/views/content/case/CHD_case"),
      },
      {
        // 病例打印管理
        path: "casePrint",
        name: "CasePrint",
        component: () => import("@/views/content/case/CasePrint"),
      },
      {
        // 病例结算管理
        path: "caseAccount",
        name: "CaseAccount",
        component: () => import("@/views/content/case/CaseAccount"),
      },
      {
        // 病例项目
        path: "caseProject",
        name: "CaseProject",
        component: () => import("@/views/content/case/CaseProject"),
      },
      {
        // 病例项目进展查询
        path: "caseProgress",
        name: "caseProgress",
        component: () => import("@/views/content/case/caseProgress"),
      },
      {
        // 病例签字管理
        path: "caseSign",
        name: "caseSign",
        component: () => import("@/views/content/case/caseSign"),
      },
      {
        // 医生名单
        path: "doctorList",
        name: "DoctorList",
        component: () => import("@/views/content/case/DoctorList"),
      },

      {
        //通讯录管理
        path: "communicationManagement",
        name: "communicationManagement",
        component: () =>
          import("@/views/content/systemSetting/CommunicationManagement"),
      },
      {
        //菜单管理
        path: "menuManagement",
        name: "menuManagement",
        component: () => import("@/views/content/systemSetting/menuManagement"),
      },
      {
        //角色管理
        path: "roleManagement",
        name: "roleManagement",
        component: () => import("@/views/content/systemSetting/roleManagement"),
      },
      {
        //客户账户管理
        path: "customerAccount",
        name: "customerAccount",
        component: () =>
          import("@/views/content/systemSetting/customerAccount"),
      },
      {
        //操作日志
        path: "operationLog",
        name: "operationLog",
        component: () => import("@/views/content/auth/operationLog"),
      },
      {
        //评论管理
        path: "CommentManagement",
        name: "CommentManagement",
        component: () => import("@/views/content/auth/CommentManagement"),
      },
      {
        //李凌测试
        path: "cwotest",
        name: "cwotest",
        component: () => import("@/views/content/auth/cwotest"),
      },
      {
        //后台操作日志
        path: "mainOperationLog",
        name: "mainOperationLog",
        component: () =>
          import("@/views/content/systemSetting/mainOperationLog"),
      },
      //医学会议管理--会议管理
      {
        path: "meetingManagement",
        name: "MeetingManagement",
        component: () =>
          import("@/views/content/medicalConference/MeetingManagement"),
      },
      {
        path: "featureManagement",
        name: "featureManagement",
        component: () =>
          import("@/views/content/medicalConference/featureManagement"),
      },
      {
        path: "featureDHZB",
        name: "FeatureDHZB",
        component: () =>
          import("@/views/content/medicalConference/FeatureDHZB"),
      },
      {
        path: "featureDuration",
        name: "FeatureDuration",
        component: () =>
          import("@/views/content/medicalConference/FeatureDuration"),
      },
      {
        //首页模板地址管理
        path: "templateAddress",
        name: "templateAddress",
        component: () =>
          import("@/views/content/medicalConference/templateAddress"),
      },
      {
        //首页功能类型管理
        path: "functionTypes",
        name: "functionTypes",
        component: () =>
          import("@/views/content/medicalConference/functionTypes"),
      },
      {
        //首页模板管理
        path: "homepageTemplate",
        name: "homepageTemplate",
        component: () =>
          import("@/views/content/medicalConference/homepageTemplate"),
      },
      {
        //首页功能管理
        path: "commonFunctions",
        name: "commonFunctions",
        component: () =>
          import("@/views/content/medicalConference/commonFunctions"),
      },
      {
        //栏目管理
        path: "programManagement",
        name: "programManagement",
        component: () =>
          import("@/views/content/dataDictionary/programManagement"),
      },
      {
        //职称管理
        path: "titleManagement",
        name: "titleManagement",
        component: () =>
          import("@/views/content/dataDictionary/titleManagement"),
      },
      {
        //注册科室管理
        path: "departmentsPlatform",
        name: "departmentsPlatform",
        component: () =>
          import("@/views/content/dataDictionary/departmentsPlatform"),
      },
      {
        //医院待清洗
        path: "hospitalCleaning",
        name: "hospitalCleaning",
        component: () => import("@/views/content/dataCleaned/hospitalCleaning"),
      },
      {
        //科室待清洗
        path: "departmentCleaning",
        name: "departmentCleaning",
        component: () =>
          import("@/views/content/dataCleaned/departmentCleaning"),
      },
      {
        //职称待清洗
        path: "titleCleaning",
        name: "titleCleaning",
        component: () => import("@/views/content/dataCleaned/titleCleaning"),
      },
      {
        //题库管理
        path: "questionBank",
        name: "questionBank",
        component: () => import("@/views/content/titleExam/questionBank"),
      },
      {
        //帮助中心内容统计
        path: "helpCenterStatistics",
        name: "HelpCenterStatistics",
        component: () =>
          import("@/views/content/titleExam/HelpCenterStatistics"),
      },
      {
        //帮助中心内容管理
        path: "helpCenter",
        name: "HelpCenter",
        component: () => import("@/views/content/titleExam/HelpCenter"),
      },
      {
        //帮助中心内容管理
        path: "OfficialWebsiteResearch",
        name: "OfficialWebsiteResearch",
        component: () =>
          import("@/views/content/titleExam/OfficialWebsiteResearch"),
      },
      {
        //帮助中心内容管理
        path: "HelpCenterMenuManagement",
        name: "HelpCenterMenuManagement",
        component: () =>
          import("@/views/content/titleExam/HelpCenterMenuManagement"),
      },
      {
        //首页功能模块管理
        path: "homepageManagement",
        name: "homepageManagement",
        component: () => import("@/views/content/titleExam/HomepageManagement"),
      },
      {
        //职称题目分类
        path: "examType",
        name: "examType",
        component: () => import("@/views/content/titleExam/examType"),
      },
      {
        //复合题型题干管理
        path: "compoundQuestionType",
        name: "CompoundQuestionType",
        component: () =>
          import("@/views/content/titleExam/CompoundQuestionType"),
      },
      {
        //同步练习分类
        path: "exercisesType",
        name: "exercisesType",
        component: () => import("@/views/content/titleExam/exercisesType_tree"),
      },
      {
        //同步练习管理
        path: "synchronizeExercises",
        name: "synchronizeExercises",
        component: () =>
          import("@/views/content/titleExam/synchronizeExercises"),
      },
      {
        //模拟考试管理
        path: "practiceTest",
        name: "MOCK",
        component: () => import("@/views/content/titleExam/practiceTest"),
      },
      {
        //历年真题
        path: "pastTest",
        name: "PAST",
        component: () => import("@/views/content/titleExam/practiceTest"),
      },
      {
        //点播视频管理
        path: "videoOnDemand",
        name: "videoOnDemand",
        component: () => import("@/views/content/videoDemand/VideoOnDemand"),
      },
      {
        //视频库管理
        path: "videoLibrary",
        name: "videoLibrary",
        component: () => import("@/views/content/videoDemand/VideoLibrary"),
      },
      {
        //视频片头片尾管理
        path: "openingCredits",
        name: "OpeningCredits",
        component: () => import("@/views/content/videoDemand/OpeningCredits"),
      },

      {
        //点播内容点击记录
        path: "/vodLog",
        name: "VOD_SINGLE",
        component: () => import("@/views/content/videoDemand/VideoLog.vue"),
      },
      {
        //文章内容点击记录
        path: "/articleLog",
        name: "ARTICLE_SINGLE",
        component: () => import("@/views/content/videoDemand/VideoLog.vue"),
      },
      // {
      //   //点播内容搜索记录
      //   path: "/vodSearchLog",
      //   name: "vodSearchLog",
      //   component: () => import("@/views/content/videoDemand/VideoLog.vue"),
      // },
      {
        //点播内容收藏记录
        path: "/conCollectionLog",
        name: "collection",
        component: () =>
          import("@/views/content/videoDemand/VideoShareLog.vue"),
      },
      {
        //用户发送邮件收藏记录
        path: "/SendingEmailRecords",
        name: "SendingEmailRecords",
        component: () =>
          import("@/views/content/videoDemand/SendingEmailRecords.vue"),
      },
      {
        //点播内容评论记录
        path: "/conCommentsLog",
        name: "comments",
        component: () =>
          import("@/views/content/videoDemand/VideoShareLog.vue"),
      },
      {
        //点播内容分享记录
        path: "/conShareLog",
        name: "share",
        component: () =>
          import("@/views/content/videoDemand/VideoShareLog.vue"),
      },
      {
        //点播内容点赞记录
        path: "/conThumbsLog",
        name: "thumbsUp",
        component: () =>
          import("@/views/content/videoDemand/VideoShareLog.vue"),
      },

      {
        //首页功能管理---Banner广告管理
        path: "homeBannerManagement",
        name: "homeBannerManagement",
        component: () =>
          import("@/views/content/homePageFeatures/HomeBannerManagement"),
      },
      {
        //首页功能管理---栏目首页内容管理
        path: "columnHomepage",
        name: "columnHomepage",
        component: () =>
          import("@/views/content/homePageFeatures/columnHomepage"),
      },
      {
        //社区功能管理---社区首页内容管理
        path: "communityContent",
        name: "communityContent",
        component: () =>
          import("@/views/content/homePageFeatures/communityContent"),
      },
      {
        //社区功能管理---社区首页内容发布
        path: "communityContentPublishing",
        name: "communityContentPublishing",
        component: () =>
          import("@/views/content/homePageFeatures/communityContentPublishing"),
      },
      {
        // 社区功能管理--社区帖子浏览记录
        path: "/homeReadRecord",
        name: "homeReadRecord",
        component: () =>
          import("@/views/content/homePageFeatures/homeReadRecord"),
      },
      // {
      //   //平台内容管理---内容（外链）
      //   path:'contentUrlManagement',
      //   name:'contentUrlManagement',
      //   component:()=>import ('@/views/content/platformContent/contentUrlManagement')
      // },
      // {
      //   //平台内容管理---内容（pdf）
      //   path:'contentPDFManagement',
      //   name:'contentPDFManagement',
      //   component:()=>import ('@/views/content/platformContent/contentPDFManagement')
      // },
      // {
      //   //平台内容管理---内容（富文本）
      //   path:'contentTextManagement',
      //   name:'contentTextManagement',
      //   component:()=>import ('@/views/content/platformContent/contentTextManagement')
      // },
      {
        //平台内容管理---招聘信息抓取
        path: "recruitInfoCapture",
        name: "recruitInfoCapture",
        component: () =>
          import("@/views/content/platformContent/recruitInfoCapture"),
      },
      {
        //平台内容管理---文章管理
        path: "activeManager",
        name: "activeManager",
        component: () =>
          import("@/views/content/platformContent/activeManager"),
      },
      {
        //首页功能内容管理---社区首页功能管理
        path: "communityEnableFunction",
        name: "communityEnableFunction",
        component: () =>
          import("@/views/content/communityFunction/CommunityEnableFunction"),
      },
      {
        //首页功能管理---App首页功能管理
        path: "enableFunction",
        name: "enableFunction",
        component: () =>
          import("@/views/content/homePageFeatures/EnableFunction"),
      },
      {
        //系统管理---个人中心功能管理
        path: "personalCenterManagement",
        name: "personalCenterManagement",
        component: () =>
          import("@/views/content/systemSetting/PersonalCenterManagement"),
      },
      {
        //系统管理---热搜词管理
        path: "hotSearchWords",
        name: "hotSearchWords",
        component: () => import("@/views/content/systemSetting/HotSearchWords"),
      },
      {
        //系统管理---H5链接设置
        path: "h5LinkSet",
        name: "h5LinkSet",
        component: () => import("@/views/content/systemSetting/H5LinkSet"),
      },
      {
        //系统管理---用户投诉管理
        path: "userComplaints",
        name: "userComplaints",
        component: () => import("@/views/content/systemSetting/UserComplaints"),
      },
      {
        //系统管理---意见反馈管理
        path: "feedback",
        name: "feedback",
        component: () => import("@/views/content/systemSetting/Feedback"),
      },
      {
        //zoom会议室管理
        path: "zoomMeet",
        name: "zoomMeet",
        component: () => import("@/views/content/consultationMeet/ZoomMeet"),
      },
      {
        //预约会诊———预约记录管理
        path: "appointmentLog",
        name: "appointmentLog",
        component: () =>
          import("@/views/content/consultationMeet/AppointmentLog"),
      },
      {
        //预约会诊———会诊专家管理
        path: "expertsMeet",
        name: "ExpertsMeet",
        component: () => import("@/views/content/consultationMeet/ExpertsMeet"),
      },
      {
        //预约会诊———会诊会议管理
        path: "consultationMeets",
        name: "consultationMeets",
        component: () =>
          import("@/views/content/consultationMeet/ConsultationMeets"),
      },
      {
        //注册专家
        path: "expert",
        name: "Expert",
        component: () => import("@/views/content/auth/Expert"),
      },
      {
        //直播数据字典
        path: "livedatadictionary",
        name: "livedatadictionary",
        component: () =>
          import("@/views/content/LiveDatadictionary/livedatadictionary"),
      },
      {
        // 设计数据字典
        path: "livedesigndictionary",
        name: "livedesigndictionary",
        component: () =>
          import("@/views/content/LiveDatadictionary/livedesigndictionary"),
      },
      {
        // 模板管理
        path: "templatemanage",
        name: "templatemanage",
        component: () =>
          import("@/views/content/TemplateManage/templatemanage"),
      },
      {
        // 病例管理 -- 患者管理
        path: "PatientManagement",
        name: "PatientManagement",
        component: () =>
          import("@/views/content/CaseManagement/PatientManagement"),
      },
      {
        // 病例管理 -- 病程管理
        path: "DiseaseManagement",
        name: "DiseaseManagement",
        component: () =>
          import("@/views/content/CaseManagement/DiseaseManagement"),
      },
      {
        // 意见反馈
        path: "NewFeedback",
        name: "NewFeedback",
        component: () => import("@/views/content/NewFeedback"),
      },
      {
        // 病例夹-系统标签
        path: "caseClipTags",
        name: "caseClipTags",
        component: () => import("@/views/content/caseClip/caseClip_tags.vue"),
      },
      {
        // 病例夹-使用明细
        path: "caseClipDetail",
        name: "caseClipDetail",
        component: () => import("@/views/content/caseClip/caseClip_detail.vue"),
      },
      {
        // 病例夹-使用明细-患者列表
        path: "caseClipPatient",
        name: "caseClipPatient",
        component: () =>
          import("@/views/content/caseClip/caseClip_patient.vue"),
      },
      {
        // 病例夹-病程导出
        path: "caseClipCourse",
        name: "caseClipCourse",
        component: () => import("@/views/content/caseClip/caseClip_course.vue"),
      },
      {
        // 病例夹-病程导出
        path: "caseTagDetail",
        name: "caseTagDetail",
        component: () =>
          import("@/views/content/caseClip/caseClip_tagDetail.vue"),
      },
      {
        // 病例夹-病程导出
        path: "caseClipFun",
        name: "caseClipFun",
        component: () => import("@/views/content/caseClip/caseClip_Fun.vue"),
      },
      {
        //会员社交记录-访问量
        path: "visits",
        name: "Visits",
        component: () =>
          import("@/views/content/MemberSocialHistory/Visits.vue"),
      },
      {
        //会员社交记录-粉丝量
        path: "fanBase",
        name: "FanBase",
        component: () =>
          import("@/views/content/MemberSocialHistory/FanBase.vue"),
      },
      {
        //会员社交记录-关注量自定义专栏管理
        path: "numberFollowers",
        name: "NumberFollowers",
        component: () =>
          import("@/views/content/MemberSocialHistory/NumberFollowers.vue"),
      },
      {
        //专栏管理-自定义专栏管理
        path: "personalHistory",
        name: "personalHistory",
        component: () =>
          import("@/views/content/ColumnManagement/PersonalHistory.vue"),
      },
      {
        //专栏管理-个人主页访问记录
        path: "PersonalHomepageAccessHistory",
        name: "PersonalHomepageAccessHistory",
        component: () =>
          import(
            "@/views/content/ColumnManagement/PersonalHomepageAccessHistory.vue"
          ),
      },
      {
        //会员信息设置-擅长设置
        path: "proficientSetting",
        name: "ProficientSetting",
        component: () =>
          import("@/views/content/MemberInformation/ProficientSetting.vue"),
      },
      {
        // 药品查询管理--药品管理
        path: "DrugManagement",
        name: "DrugManagement",
        component: () =>
          import("@/views/content/DrugQueryManagement/DrugManagement.vue"),
      },
      {
        // 药品查询管理--搜索记录
        path: "SearchHistory",
        name: "SearchHistory",
        component: () =>
          import("@/views/content/DrugQueryManagement/SearchHistory.vue"),
      },
      {
        // 药品查询管理--搜索记录
        path: "FavoriteRecords",
        name: "FavoriteRecords",
        component: () =>
          import("@/views/content/DrugQueryManagement/FavoriteRecords.vue"),
      },
      {
        // 药品查询管理--错误记录
        path: "errorHistory",
        name: "errorHistory",
        component: () =>
          import("@/views/content/DrugQueryManagement/errorHistory.vue"),
      },
      {
        // 药品查询管理--分享记录
        path: "drugShareRecord",
        name: "drugShareRecord",
        component: () =>
          import("@/views/content/DrugQueryManagement/drugShareRecord.vue"),
      },
      // 药品查询--内容点击
      {
        path: "/DrugContentClick",
        name: "DrugContentClick",
        component: () =>
          import("@/views/content/DrugQueryManagement/DrugContentClick.vue"),
      },
      {
        // 进修招聘管理--招聘内容管理
        path: "/RecruitmentContent",
        name: "/RecruitmentContent",
        component: () =>
          import("@/views/content/Recruiting/RecruitmentContent.vue"),
      },
      // 进修招聘--内容点击
      {
        path: "/EduRecContentClick",
        name: "EduRecContentClick",
        component: () =>
          import("@/views/content/Recruiting/EduRecContentClick.vue"),
      },
      {
        // 进修招聘管理--进修内容管理
        path: "/ContinuingEducation",
        name: "/ContinuingEducation",
        component: () =>
          import("@/views/content/Recruiting/ContinuingEducation.vue"),
      },
      {
        // 进修招聘管理--搜索记录管理
        path: "/RecruitmentSearchHistory",
        name: "/RecruitmentSearchHistory",
        component: () => import("@/views/content/Recruiting/SearchHistory.vue"),
      },

      {
        // 进修招聘管理--培训内容管理
        path: "/TrainingManagement",
        name: "/TrainingManagement",
        component: () =>
          import("@/views/content/Recruiting/TrainingManagement.vue"),
      },
      {
        // 进修招聘管理--分享记录管理
        path: "/ShareRecordManagement",
        name: "/ShareRecordManagement",
        component: () =>
          import("@/views/content/Recruiting/ShareRecordManagement.vue"),
      },
      {
        // 进修招聘管理--订阅记录管理
        path: "/SubscriptionRecords",
        name: "/SubscriptionRecords",
        component: () =>
          import("@/views/content/Recruiting/SubscriptionRecords.vue"),
      },
      {
        // 进修招聘管理--关注记录管理
        path: "/FollowRecords",
        name: "/FollowRecords",
        component: () => import("@/views/content/Recruiting/FollowRecords.vue"),
      },
      {
        // 进修招聘  培训内容管理 邓
        path: "/trainManage",
        name: "trainManage",
        component: () => import("@/views/content/train/trainManage"),
      },
      {
        // 进修招聘 推荐内容管理 邓
        path: "/recommendManage",
        name: "recommendManage",
        component: () => import("@/views/content/train/recommendManage"),
      },
      {
        // 进修招聘  关注记录管理 邓
        path: "/concernManage",
        name: "concernManage",
        component: () => import("@/views/content/train/concernManage"),
      },
      {
        // 进修招聘  分享记录管理 邓
        path: "/shareManage",
        name: "shareManage",
        component: () => import("@/views/content/train/shareManage"),
      },
      {
        //进修招聘  数据字典管理 邓
        path: "/dictionaryManage",
        name: "dictionaryManage",
        component: () => import("@/views/content/train/dictionaryManage"),
      },
      {
        // 职称考试 考试信息
        path: "/examInformation",
        name: "examInformation",
        component: () => import("@/views/content/titleExam/examInformation"),
      },
      {
        // 职称考试 考点精选
        path: "/examinationPoints",
        name: "examinationPoints",
        component: () => import("@/views/content/titleExam/examinationPoints"),
      },
      {
        // 职称考试 考点精选
        path: "/UserErrorCorrection",
        name: "UserErrorCorrection",
        component: () =>
          import("@/views/content/titleExam/UserErrorCorrection"),
      },
      {
        // 职称考试 同步练习使用记录
        path: "/synchronousPracticeRecords",
        name: "SynchronousPracticeRecords",
        component: () =>
          import("@/views/content/titleExam/SynchronousPracticeRecords"),
      },
      {
        // 职称考试 模拟考试使用记录
        path: "/practiceTestRecords",
        name: "PracticeTestRecords",
        component: () =>
          import("@/views/content/titleExam/PracticeTestRecords"),
      },
      {
        // 职称考试 历年真题使用记录
        path: "/previousExamPapersRecords",
        name: "PreviousExamPapersRecords",
        component: () =>
          import("@/views/content/titleExam/PreviousExamPapersRecords"),
      },
      {
        // 职称考试 每日一练使用记录
        path: "/useDailyPracticeRecord",
        name: "UseDailyPracticeRecord",
        component: () =>
          import("@/views/content/titleExam/UseDailyPracticeRecord"),
      },
      {
        // 职称考试 错题添加记录
        path: "/errorAddRecord",
        name: "ErrorAddRecord",
        component: () => import("@/views/content/titleExam/ErrorAddRecord"),
      },
      {
        // 职称考试 推荐内容管理
        path: "/examRecommendManage",
        name: "examRecommendManage",
        component: () =>
          import("@/views/content/titleExam/examRecommendManage"),
      },
      {
        //经验值获得记录
        path: "/experienceValueRecord",
        name: "experienceValueRecord",
        component: () =>
          import("@/views/content/titleExam/ExperienceValueRecord"),
      },
      {
        // 职称考试 笔记添加记录
        path: "/addNotesRecord",
        name: "addNotesRecord",
        component: () => import("@/views/content/titleExam/addNotesRecord"),
      },
      {
        // 职称考试  考试信息浏览记录

        path: "/examMessageLook",
        name: "examMessageLook",
        component: () => import("@/views/content/titleExam/examMessageLook"),
      },
      {
        // 职称考试  考试精选浏览记录
        path: "/examPointsLook",
        name: "examPointsLook",
        component: () => import("@/views/content/titleExam/examPointsLook"),
      },
      {
        // 文献指南 疾病管理
        path: "/disease",
        name: "disease",
        component: () => import("@/views/content/document/disease.vue"),
      },
      {
        // 文献指南 已读记录
        path: "/ReadRecords",
        name: "ReadRecords",
        component: () => import("@/views/content/document/ReadRecords.vue"),
      },
      {
        // 文献指南 浏览记录
        path: "/BrowsingHistory",
        name: "BrowsingHistory",
        component: () => import("@/views/content/document/BrowsingHistory.vue"),
      },
      {
        // 小程序首页内容管理
        path: "/MiniProgramHomepage",
        name: "MiniProgramHomepage",
        component: () =>
          import(
            "@/views/content/HomeFunctionManagement.vue/MiniProgramHomepage.vue"
          ),
      },
      {
        // banner管理
        path: "/Administration",
        name: "Administration",
        component: () =>
          import(
            "@/views/content/HomeFunctionManagement.vue/Administration.vue"
          ),
      },
      {
        // 推广Banner管理
        path: "/PromotionBanner",
        name: "PromotionBanner",
        component: () =>
          import(
            "@/views/content/HomeFunctionManagement.vue/PromotionBanner.vue"
          ),
      },
      // 功能模块管理
      {
        path: "/FunctionalModuleManagement",
        name: "FunctionalModuleManagement",
        component: () =>
          import(
            "@/views/content/HomeFunctionManagement.vue/FunctionalModuleManagement.vue"
          ),
      },
      {
        // 小程序首页功能管理
        path: "/uniAppFunctionManagement",
        name: "uniAppFunctionManagement",
        component: () =>
          import(
            "@/views/content/HomeFunctionManagement.vue/uniAppFunctionManagement.vue"
          ),
      },
      {
        // 文献指南 制定者管理
        path: "/maker",
        name: "maker",
        component: () => import("@/views/content/document/maker.vue"),
      },
      {
        // 文献指南  收藏记录
        path: "/collectionHistory",
        name: "collectionHistory",
        component: () =>
          import("@/views/content/document/collectionHistory.vue"),
      },
      {
        // 医学杂志浏览记录
        path: "/magazineRecord",
        name: "magazineRecord",
        component: () => import("@/views/content/magazine/magazineRecord"),
      },
      {
        // 搜索记录查询
        path: "/searchRecord",
        name: "searchRecord",
        component: () => import("@/views/content/videoDemand/searchRecord"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  // {
  //   path: '/test',
  //   name: 'test',
  //   component:()=>import('@/views/Test'),
  // },
  {
    path: "/navigation",
    name: "Navigation",
    component: () => import("@/components/Navigation"),
  },
  {
    // 病例管理
    path: "/caseRoot",
    name: "caseRoot",
    component: () => import("@/views/content/case/CaseRoot"),
    children: [
      {
        // 病例首页
        path: "/caseRoot",
        name: "/caseRoot/caseHome",
        component: () => import("@/views/content/case/CaseHome"),
      },
      {
        // 病例管理
        path: "/caseRoot/case",
        name: "/caseRoot/Case",
        component: () => import("@/views/content/case/Case"),
      },
      {
        // 目标医院审核
        path: "/caseRoot/targetHospitalAudit",
        name: "/caseRoot/targetHospitalAudit",
        component: () => import("@/views/content/case/targetHospitalAudit"),
      },
      {
        // 病历图片打码管理
        path: "/caseRoot/casePicturesCode",
        name: "/caseRoot/CasePicturesCode",
        component: () => import("@/views/content/case/CasePicturesCode"),
      },
      {
        // 图片打码
        path: "/caseRoot/caseCode",
        name: "/caseRoot/CaseCode",
        component: () => import("@/views/content/case/CaseCode"),
      },
      {
        // 病例审批管理
        path: "/caseRoot/caseAudit",
        name: "/caseRoot/CaseAudit",
        component: () => import("@/views/content/case/CaseAudit"),
      },
      {
        // 病例审核管理
        path: "/caseRoot/caseReview",
        name: "/caseRoot/caseReview",
        component: () => import("@/views/content/case/caseReview"),
      },
      {
        // 病例审核管理
        path: "/caseRoot/caseReviewTwo",
        name: "/caseRoot/caseReviewTwo",
        component: () => import("@/views/content/case/caseReviewTwo"),
      },
      {
        // 病例审核管理--主动脉
        path: "/caseAorta",
        name: "/caseRoot/caseAorta",
        component: () => import("@/views/content/case/caseAorta"),
      },
      {
        // 病例审核管理--脓毒症
        path: "/casePyohemia",
        name: "/caseRoot/CasePyohemia",
        component: () => import("@/views/content/case/CasePyohemia"),
      },
      {
        // 病例审核管理--心率失常
        path: "/caseArrhythmia",
        name: "/caseRoot/CaseArrhythmia",
        component: () => import("@/views/content/case/CaseArrhythmia"),
      },
      {
        // 病例查看比伐芦定在冠心病患者PCI治疗中的应用真实世界研究临床观察表
        path: "/caseRoot/chdCase",
        name: "/caseRoot/chdCase",
        component: () => import("@/views/content/case/CHD_case"),
      },
      {
        // 病例打印管理
        path: "/caseRoot/casePrint",
        name: "/caseRoot/CasePrint",
        component: () => import("@/views/content/case/CasePrint"),
      },
      {
        // 病例结算管理
        path: "/caseRoot/caseAccount",
        name: "/caseRoot/CaseAccount",
        component: () => import("@/views/content/case/CaseAccount"),
      },
      {
        // 病例项目
        path: "/caseRoot/caseProject",
        name: "/caseRoot/caseProject",
        component: () => import("@/views/content/case/CaseProject"),
      },
      {
        // 病例项目进展查询
        path: "/caseRoot/caseProgress",
        name: "/caseRoot/caseProgress",
        component: () => import("@/views/content/case/caseProgress"),
      },
      {
        // 病例签字管理
        path: "/caseRoot/caseSign",
        name: "/caseRoot/caseSign",
        component: () => import("@/views/content/case/caseSign"),
      },
      {
        // 医生名单
        path: "/caseRoot/doctorList",
        name: "/caseRoot/DoctorList",
        component: () => import("@/views/content/case/DoctorList"),
      },
    ],
  },
  {
    // 直播运营
    path: "/liveRoot",
    name: "liveRoot",
    component: () => import("@/views/content/live/liveRoot"),
    children: [
      {
        // 工作台
        path: "/liveRoot",
        name: "liveRoot",
        component: () => import("@/views/content/operationAnalysis/Workbench"),
      },
      //直播会议总览
      {
        path: "/liveconferenceOverview",
        name: "liveliveConferenceOverview",
        component: () =>
          import("@/views/content/liveManage/ConferenceOverview"),
      },
      //直播会议管理
      {
        path: "/liveliveMeet",
        name: "liveliveMeet",
        component: () => import("@/views/content/liveliveMeet"),
      },
      //设置直播间管理
      {
        path: "/setliveliveMeet",
        name: "setliveliveMeet",
        component: () => import("@/views/content/setliveliveMeet"),
      },

      // // 直播间抽奖活动
      // {
      //   path: '/liveactivity',
      //   name: 'liveactivity',
      //   component: Activity
      // },
      //直播管理，直播问答管理
      {
        path: "/liveliveAnswers",
        name: "liveliveAnswers",
        component: () => import("@/views/content/liveliveAnswers"),
      },
      //直播管理，查看答题记录
      {
        path: "/liveliveAnswerRecord",
        name: "liveliveAnswerRecord",
        component: () => import("@/views/content/liveliveAnswerRecord"),
      },
      {
        path: "/livesupervisorRating", //平台运营分析 - 主管评分
        name: "liveSupervisorRating",
        component: () =>
          import("@/views/content/operationAnalysis/SupervisorRating"),
      },
      {
        path: "/livesalesRating", //平台运营分析 - 销售评分
        name: "liveSalesRating",
        component: () =>
          import("@/views/content/operationAnalysis/SalesRating"),
      },
      {
        //点播视频管理
        path: "/livevideoOnDemand",
        name: "livevideoOnDemand",
        component: () => import("@/views/content/videoDemand/VideoOnDemand"),
      },
      {
        //视频库管理
        path: "/livevideoLibrary",
        name: "livevideoLibrary",
        component: () => import("@/views/content/videoDemand/VideoLibrary"),
      },
      {
        //视频片头片尾管理
        path: "/liveopeningCredits",
        name: "liveOpeningCredits",
        component: () => import("@/views/content/videoDemand/OpeningCredits"),
      },
      {
        //预约会诊———会诊专家管理
        path: "/liveexpertsMeet",
        name: "liveExpertsMeet",
        component: () => import("@/views/content/consultationMeet/ExpertsMeet"),
      },
      {
        //zoom会议室管理
        path: "/livezoomMeet",
        name: "livezoomMeet",
        component: () => import("@/views/content/consultationMeet/ZoomMeet"),
      },
      {
        //预约会诊———会诊会议管理
        path: "/liveconsultationMeets",
        name: "liveconsultationMeets",
        component: () =>
          import("@/views/content/consultationMeet/ConsultationMeets"),
      },
      {
        //首页模板地址管理
        path: "/livetemplateAddress",
        name: "livetemplateAddress",
        component: () =>
          import("@/views/content/medicalConference/templateAddress"),
      },
      {
        //首页功能类型管理
        path: "/livefunctionTypes",
        name: "livefunctionTypes",
        component: () =>
          import("@/views/content/medicalConference/functionTypes"),
      },
      {
        //首页功能管理
        path: "/livecommonFunctions",
        name: "liecommonFunctions",
        component: () =>
          import("@/views/content/medicalConference/commonFunctions"),
      },
      {
        //首页模板管理
        path: "/livehomepageTemplate",
        name: "livehomepageTemplate",
        component: () =>
          import("@/views/content/medicalConference/homepageTemplate"),
      },

      {
        //首页功能管理---Banner广告管理
        path: "/livehomeBannerManagement",
        name: "livehomeBannerManagement",
        component: () =>
          import("@/views/content/homePageFeatures/HomeBannerManagement"),
      },
      //直播主办方管理
      {
        path: "/livesponsorManagement",
        name: "liveSponsorManagement",
        component: () =>
          import("@/views/content/liveManage/liveSponsorManagement"),
      },
      //计划进展查询
      {
        path: "/liveprogressQuery",
        name: "liveProgressQuery",
        component: () => import("@/views/content/liveManage/ProgressQuery"),
      },
      //医学会议管理--会议管理
      {
        path: "/livemeetingManagement",
        name: "liveMeetingManagement",
        component: () =>
          import("@/views/content/medicalConference/livemeetingManagement"),
      },
      {
        path: "/livefeaterManagement",
        name: "livefeaterManagement",
        component: () =>
          import("@/views/content/medicalConference/livefeaterManagement"),
      },
      //合作计划
      {
        path: "/livecooperationPlan",
        name: "PlanProgress",
        component: () => import("@/views/content/liveManage/livePlanProgress"),
      },
      {
        //直播数据字典
        path: "/livelivedatadictionary",
        name: "livelivedatadictionary",
        component: () =>
          import("@/views/content/LiveDatadictionary/livedatadictionary"),
      },
      {
        // 设计数据字典
        path: "/livelivedesigndictionary",
        name: "livelivedesigndictionary",
        component: () =>
          import("@/views/content/LiveDatadictionary/livedesigndictionary"),
      },
      {
        // 模板管理
        path: "/livetemplatemanage",
        name: "livetemplatemanage",
        component: () =>
          import("@/views/content/TemplateManage/templatemanage"),
      },
      // 直播间抽奖活动
      {
        path: "/liveliveactive",
        name: "liveliveactive",
        component: () => import("@/views/content/liveliveactive"),
      },
      {
        path: "/liveiottery",
        name: "liveIottery",
        component: () => import("@/views/content/liveIottery"),
      },
      {
        path: "/liveliveInformation",
        name: "liveliveInformation",
        component: () => import("@/views/content/liveliveInformation"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;

router.beforeEach((to, from, next) => {
  //如果是登录页面路径，就直接next()
  if (window.localStorage.getItem("user_info")) {
    //获取本地存储信息，并转换为Json格式
    const userData = JSON.parse(window.localStorage.getItem("user_info"));
    //判断有没有id
    if (
      userData.id !== "" &&
      userData.id !== undefined &&
      userData.id !== null
    ) {
      //返回上一层路由
      next(-1);
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  }
});
