<template>
  <div class="login">
    <div class="sign-up">
      <h1 class="sign-up-title">登 录</h1>
      <a-input ref="userNameInput" v-model="loginName" class="sign-up-input" placeholder="请输入手机号" autofocus>
        <a-icon style="color: #40A2FB;" slot="prefix" type="user" />
      </a-input>
<!--      <a-input type="text" v-model="loginName" class="sign-up-input" placeholder="请输入手机号" autofocus/>-->
<!--      <a-input type="password" v-model="password" class="sign-up-input" placeholder="请输入密码" @keydown.enter="login"></a-input>-->
      <a-input-password v-model="password" class="sign-up-input" placeholder="请输入密码" @keydown.enter="login">
        <a-icon style="color: #40A2FB" slot="prefix" type="lock" />
      </a-input-password>
      <div v-if="testAlert" class="textAlert">用户或密码错误，请重试</div>
      <input type="submit" @click="login" value="登 录" class="sign-up-button">
    </div>
  </div>
</template>

<script>

import {postAdmin} from "@/service/login_api";
import {postAdminData} from "@/service/document_api";
import { setXToken } from "@/service/base";
import { setById } from "@/service/base_y";
import {setUserId} from "@/service/common";
import {login} from '@/service/common'

export default {
  name: "Login",
  data(){
    return{
      password:'',
      loginName:'',
      content:[],
      testAlert:false
    }
  },
  created() {
  },
  methods:{
    async login2(){
      //创建用户名和密码
      const data = {
        login_name:this.loginName,
        password:this.password
      }
      //向接口传数据
      const result = await postAdmin(data)
      if(result.code === 0){
        this.$message.success('登录成功')
        //登录成功后像接口传用户详细信息
        const login_name = this.loginName
        const adminDates = await postAdminData(login_name)
        if(adminDates.code === 0){
          //登录,并转为json格式
          this.content = JSON.stringify(adminDates.data)
          console.log(adminDates.data)
          //向本地存储信息
          window.localStorage.setItem("user_info",this.content)
          const id =  adminDates.data.id
          //赋值x-token
          await setXToken(id)
          //赋值byid   
          await setById(id)
          //赋值埋点id
          await setUserId(id)
        }else {
          this.$message.error('获取接口失败，请联系管理员' + adminDates.message)
        }
        //账号密码正确后，调准到上一层路由，也就是界面
        window.history.go(-1)
      }else {
        this.$message.error('账号或密码错误！')
        this.password = ''
      }
    },

    async login(){
       const response = await login(this.loginName, this.password);
       if (response.code === 0){
         this.testAlert = false
         //登录成功获取到的数据,并转为json格式
         this.content = JSON.stringify(response.data)
         //向本地存储信息
         window.localStorage.setItem("user_info",this.content)
         const id =  response.data.id
         const type =  response.data.type
         //赋值x-token
        await setXToken(id, type)
         //赋值埋点id
         await setUserId(id)
         this.$message.success('登录成功')
         if (id===72){
           this.$router.push({path:"/caseRoot"})
         }else {
           window.history.go(-1)
         }
         // this.$router.push({path:"/"})
       }else {
         // this.$message.error('账号或密码错误')
         this.testAlert = true
       }
    },
  }
}
</script>

<style scoped lang="scss">
.login {
  height: 100%;
  width: 100%;
  background-image: url('../assets/bgi.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .login-bg{
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
.sign-up {
  position: relative;
  top: 35%;
  left: 60%;
  width: 23%;
  padding: 40px 25px 40px;
  background: rgba(255,255,255,0.6);
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
}

.sign-up-title {
  margin: -25px -25px 25px;
  padding: 5px 25px;
  font-size: 25px;
  font-weight: bold;
  color: #40A2FB;
  text-align: center;
}

input {
  font-family: inherit;
  color: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.sign-up-input {
  width: 100%;
  //height: 40px;
  margin-bottom: 25px;
  //padding: 0 15px 2px;
  //font-size: 17px;
  //background: white;
  //border: 2px solid #ebebeb;
  //border-radius: 4px;
  //-webkit-box-shadow: inset 0 -2px #ebebeb;
  //box-shadow: inset 0 -2px #ebebeb;
}

//.sign-up-input:focus {
//  border-color: #40A2FB;
//  outline: none;
//}
.textAlert{
  color: red;text-align: center;width: 100%;
}
.sign-up-button {
  margin-top: 5px;
  position: relative;
  vertical-align: top;
  width: 100%;
  height: 35px;
  padding: 0;
  font-size: 16px;
  color: white;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(#4AC7FE, #47BDFD, #3E9DF9, #3273F3);
  //background: #40A2FB;
  border: 0;
  border-bottom: 2px solid #40A2FB;
  border-radius: 5px;
  cursor: pointer;
}

.sign-up-button:active {
  top: 1px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

:-moz-placeholder {
  color: #ccc;
  font-weight: 300;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
  font-weight: 300;
}

::-webkit-input-placeholder {
  color: #ccc;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #ccc;
  font-weight: 300;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

</style>
