/**
 * name:lcg
 * data:2021年12月16日
 */
import { Exception } from "sass";
import { get, put, post, del, postUserId, getDrugExel } from "./base";
//获取医学杂志
export function getMagazine(title, is_show, is_top, page_no, time, page_size) {
  return get("/api/admin/magazine?", {
    page_size: page_size,
    title,
    is_show,
    is_top,
    page_no,
    time,
  });
}
export function getMagazineContent(id) {
  return get("/api/admin/magazine/" + id);
}

// 显示隐藏按钮
export function putMagazine(data) {
  return put("/api/admin/magazine", data);
}
// export  function postUpload(file){
//     return post('/api/admin/common/update',file,{headers:{'Content-Type':'multipart/form-data'}})
// }
// 添加表单
export function postUpload(data) {
  return post("/api/admin/magazine", data);
}
// 修改表单
export function putEdit(data) {
  return put("/api/admin/magazine", data);
}
export function delMagazines(ids) {
  return del("/api/admin/magazine", { ids });
}

// 获取医学杂志浏览记录
export function getRecord(pageNo, pageSize, startTime, endTime) {
  return get(
    `/v3/magazineVisit?pageNo=${pageNo}&pageSize=${pageSize}&startTime=${startTime}&endTime=${endTime}`
  );
}
// 导出
export function getExcel(data) {
  return getDrugExel("/v3/magazineVisit/export", data);
}

// 批量上架下架
export function putBatch(is_show, data) {
  return put(`/api/admin/magazine/batch/${is_show}`, data);
}