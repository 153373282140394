import axios from "axios";
import qs from 'qs'
import headerConfig from './api_header_config'


const header = headerConfig.api_header
const drugHeader = headerConfig.api_header_drug  //药品管理头
// const header = "http://192.168.31.88:10086"; // 韩笑本地

let x_token;
let x_type;

/**
 * 赋值x-token
 * @param token
 */
export function setXToken(token, type) {
    x_token = token
    x_type = type
}
//封装get
export function get(url, params) {
    return axios.get(header + url, {
        headers: { "x-token": x_token },
        params,
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
// 封装药品管理 drugHeader
export function drugGet(url, params) {
    return axios.get(drugHeader + url, {
        headers: { "x-token": x_token },
        params,
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
// 导出execl（药品管理）
export function getDrugExel(url, params) {
    return axios.get(drugHeader + url, {
        headers: { "x-token": x_token },
        params,
        responseType: 'blob'
    }).then((res) => {
        if (!res) return
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf8' })
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);//创建下载的链接
        downloadElement.href = href;
        downloadElement.download = params.ExelName;//下载后文件名
        // downloadElement.download =decodeURIComponent(
        //     res.headers['content-disposition'].split(";")[1].split("filename=")[1]); // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click();//点击下载
        document.body.removeChild(downloadElement);//下载完成移除元素
        window.URL.revokeObjectURL(href);//释放掉blob对象
    }).catch((e) => {
        console.log(e)
    })
}
// 封装put //李凌
export function llput(url, params) {
    return axios.put(header + url, params, { headers: { "x-token": x_token } }
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}

// 封装post  //李凌
export function llpost(url, params) {
    return axios.post(header + url, params, { headers: { "x-token": x_token } }
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}

// 封装get  //李凌
export function llget(url, params) {
    return axios.get(header + url, {
        headers: { "x-token": x_token },
        params,
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
//
//get  病例查看比伐芦定在冠心病患者PCI治疗中的应用真实世界研究临床观察表
export function getCase(url, params) {
    return axios.get(header + url, {
        params,
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
export function getType(url, params) {
    return axios.get(header + url, {
        headers: {
            "x-token": x_token,
            "type": x_type,
        },
        params,
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
//下载表格----params.ExelName为表格名字
export function getExel(url, params) {
    return axios.get(header + url, {
        headers: { "x-token": x_token , "byId": x_token},
        params,
        responseType: 'blob'
    }).then((res) => {
        if (!res) return
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf8' })
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);//创建下载的链接
        downloadElement.href = href;
        downloadElement.download = params.ExelName;//下载后文件名
        // downloadElement.download =decodeURIComponent(
        //     res.headers['content-disposition'].split(";")[1].split("filename=")[1]); // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click();//点击下载
        document.body.removeChild(downloadElement);//下载完成移除元素
        window.URL.revokeObjectURL(href);//释放掉blob对象
    }).catch((e) => {
        console.log(e)
    })
}
export function post(url, params) {
    return axios.post(header + url, params, { headers: { "x-token": x_token } }
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
// 封装药品管理 drugHeader
export function drugPost(url, params) {
    return axios.post(drugHeader + url, params, { headers: { "x-token": x_token } }
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}

export function patch(url, params) {
    return axios.patch(header + url, params, { headers: { "x-token": x_token } }
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}


export function postBId(url, params) {
    return axios.post(header + url, params, { headers: { "byId": x_token } }
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
export function postLogin(url, params) {
    return axios.post(header + url, params
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
export function postUserId(url, params) {
    return axios.post(header + url, params, { headers: { "userId": x_token } }
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
export function put(url, params) {
    return axios.put(header + url, params, { headers: { "x-token": x_token } }
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
// 封装药品管理 drugHeader
export function drugPut(url, params) {
    return axios.put(drugHeader + url, params, { headers: { "x-token": x_token } }
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}

export function putUserId(url, params) {
    return axios.put(header + url, params, { headers: { "userId": x_token } }
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
export function putById(url, params) {
    return axios.put(header + url, params, { headers: { "byId": x_token } }
    ).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}


export function del(url, params) {
    return axios.delete(header + url, {
        headers: { "x-token": x_token },
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, { indices: false })
        }
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}

// 封装药品管理
export function drugDel(url, params) {
    return axios.delete(drugHeader + url, {
        headers: { "x-token": x_token },
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, { indices: false })
        }
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}

export function delById(url, params) {
    return axios.delete(header + url, {
        headers: { "byId": x_token },
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, { indices: false })
        }
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}

export function delUserId(url, params) {
    return axios.delete(header + url, {
        headers: { "userId": x_token },
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, { indices: false })
        }
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}
// export function uploadExam(url,params,onUploadProgress) {
//     return axios.post(header + url, params, onUploadProgress
//     ).then((res) => {
//         return res.data
//     }).catch((e) => {
//         console.log(e)
//     })
// }



