<template>
  <div style="margin: -15px -15px">
<!--    侧边选择区-->
    <a-spin :spinning="spinning">
    <div class="bigDiv">
      <!--      左边  身份切换-->
      <div class="divLeft">
        <a-radio-group v-model="value" default-value="a" @change="onChange" button-style="solid" style="width: 100%;">
          <a-radio-button value="a"  class="divLeft-btn">
            医生
          </a-radio-button>
          <a-radio-button value="b" class="divLeft-btn">
            护士
          </a-radio-button>
          <a-radio-button value="c" class="divLeft-btn">
            医技
          </a-radio-button>
          <a-radio-button value="d" class="divLeft-btn">
            药师
          </a-radio-button>
        </a-radio-group>
      </div>
      <!--      右边  具体内容-->
      <div class="divRight">
        <!--    医护人员管理-->

        <!--    顶部 搜索-->
        <div class="bgc-staff">
          <div class="medical-bgc">
            <div class="medical-top">
              <div class="medical-top-top">
                <div class="medical-top-title">
                  手机号码: <a-input @pressEnter="enterSearch" v-model="searchForm.tel" style="width: 60%" placeholder="请输入手机号码" />
                </div>
                <div class="medical-top-expert">
                  姓名: <a-input @pressEnter="enterSearch" v-model="searchForm.name" style="width: 60%" placeholder="请输入姓名" />
                </div>
                <div class="medical-top-title">
                  医院: <a-input @pressEnter="enterSearch" v-model="searchForm.hospital_name" style="width: 60%" placeholder="请输入医院" />
                </div>
                <div class="medical-top-expert">
                  科室: <a-input @pressEnter="enterSearch" v-model="searchForm.hospital_offices" style="width: 60%" placeholder="请输入科室" />
                </div>
              </div>
              <div style="display: flex">
                <div class="medical-top-expert-Two">
                  职称: <a-input @pressEnter="enterSearch" v-model="searchForm.job_title" style="width: 60%" placeholder="请输入职称" />
                </div>
                <div class="medical-top-expert-Two">
<!--                  职称: <a-input @pressEnter="enterSearch" v-model="searchForm.job_title" style="width: 60%" placeholder="请输入职称" />-->
                  <span>注册时间：</span>
                  <a-range-picker
                      v-model="timeVal"
                    @change="onChange_range_picker " />
                </div>
              </div>
              <div class="medical-bottom-btn">
                <a-button @click="checkBtn">查询</a-button>
                <a-button style="margin-left: 15px" @click="resetBtn">重置</a-button>
              </div>
            </div>
          </div>
        </div>
        <!--     列表 内容  -->
        <div class="bgc-staff-two">
          <div class="staff-table">
            <div class="staff-table-btn">
              <a-button @click="addBtn" type="primary" class="staff-table-btn-btn">新增</a-button>
              <!--          <a-button class="staff-table-btn-btn">重置密码</a-button>-->
              <a-popconfirm placement="top" ok-text="Yes" cancel-text="No" @confirm="restBtn">
                <template slot="title">
                  <p>是否重置密码？</p>
                  <p>重置密码后密码为111111</p>
                </template>
                <a-button class="staff-table-btn-btn">重置密码</a-button>
              </a-popconfirm>
              <a-popconfirm placement="top" ok-text="Yes" cancel-text="No" @confirm="deleteBtn">
                <template slot="title">
                  <p>是否确认删除？</p>
                </template>
                <a-button type="danger">删除</a-button>
              </a-popconfirm>
            </div>
            <!-----------------表格 :pagination="{ pageSize: 5 }"   ------------------->
            <a-table
                :columns="columns"
                :data-source="medicalList"
                :rowKey='record=>record.id'
                :pagination="false"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            >
          <span slot="system" slot-scope="text, record">
            <a @click="lookBtn(record)">查看</a>
            <a @click="operateBtn(record)" style="margin-left: 15px">修改</a>
          </span>
          <span slot="created_time" slot-scope="text, record">
<!--            {{(record.created_time).slice(0,-9)}}-->
            <div v-if="record.created_time !== null && record.created_time !== undefined && record.created_time !== ''">{{(record.created_time).slice(0,-9)}}</div>
            <div v-else> </div>
          </span>
            </a-table>
            <!--          分页-->
            <div style="width: 100%;height: 50px;">
              <div style="float: right;margin-right: 20px;margin-top: 15px">
                <a-pagination
                    @change="listChange"
                    :defaultCurrent="pageNo"
                    :total="listCount" />
              </div>
            </div>
          </div>
        </div>
        <!-----------------------------------------对话框---------------------------------------->
        <!--    新增对话框-->
        <a-drawer
            title="新增"
            placement="right"
            :closable="false"
            :visible="addVisible"
            @close="addClose"
            width="500"
        >
          <div class="addForms">
            <a-form-model
                ref="addRuleForm"
                :model="addForm"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol">
              <a-form-model-item label="姓名" prop="name">
                <a-input v-model="addForm.name" placeholder="请输入姓名" />
              </a-form-model-item>
              <a-form-model-item label="手机号码" prop="tel">
                <a-input v-model="addForm.tel" placeholder="请输入手机号" />
              </a-form-model-item>
              <a-form-model-item label="医院" prop="hospital_name">
                <a-input v-model="addForm.hospital_name" placeholder="请输入医院" />
              </a-form-model-item>
              <a-form-model-item label="科室" prop="hospital_offices">
                <a-input v-model="addForm.hospital_offices" placeholder="请输入科室" />
              </a-form-model-item>
              <a-form-model-item label="职称" prop="job_title">
                <a-input v-model="addForm.job_title" placeholder="请输入职称" />
              </a-form-model-item>
              <a-form-model-item label="省份" prop="province">
<!--                <a-input v-model="addForm.province" placeholder="请输入省份" />-->
                <a-select
                    show-search
                    v-model="addForm.province"
                    placeholder="请选择省份"
                    option-filter-prop="children"
                    style="width: 200px"
                >
                  <a-select-option
                      v-for="(provinces,index) in json"
                      :key="provinces.code"
                      @click="selectChange_priviceAdd(provinces.name,index)"
                      :value="provinces.name">
                    {{provinces.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="城市" prop="city">
<!--                <a-input v-model="addForm.city" placeholder="请输入城市" />-->
                <a-select
                    show-search
                    v-model="addForm.city"
                    placeholder="请选择城市"
                    option-filter-prop="children"
                    style="width: 200px"
                >
                  <a-select-option
                      v-for="(city,index) in cityListAdd"
                      :key="city.code"
                      @click="selectChange_cityAdd(city.name)"
                      :value="city.name">
                    {{city.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset:14}">
                <a-button style="margin-right: 10px;" @click="addCloseBtn">
                  取消
                </a-button>
                <a-button type="primary" @click="onSubmit">
                  确定
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </a-drawer>
        <!--    操作对话框-->
        <a-drawer
            title="修改"
            placement="right"
            :closable="false"
            :visible="editVisible"
            @close="editClose"
            width="500"
        >
          <div class="addForms">
            <a-form-model
                ref="editRuleForm"
                :model="editForm"
                :rules="editRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol">
              <a-form-model-item label="姓名" prop="name">
                <a-input  v-model="editForm.name" />
              </a-form-model-item>
              <a-form-model-item label="手机号码" prop="tel">
                <a-input v-model="editForm.tel" />
              </a-form-model-item>
              <a-form-model-item label="医院" prop="hospital_name">
                <a-input v-model="editForm.hospital_name" />
              </a-form-model-item>
              <a-form-model-item label="科室" prop="hospital_offices">
                <a-input v-model="editForm.hospital_offices" />
              </a-form-model-item>
              <a-form-model-item label="职称" prop="job_title">
                <a-input v-model="editForm.job_title" />
              </a-form-model-item>
              <a-form-model-item label="省份" prop="province">
<!--                <a-input v-model="editForm.province" />-->
                <a-select
                    show-search
                    v-model="editForm.province"
                    placeholder="请选择省份"
                    option-filter-prop="children"
                    style="width: 200px"
                >
                  <a-select-option
                      v-for="(provinces,index) in json"
                      :key="provinces.code"
                      @click="selectChange_privice(provinces.name,index)"
                      :value="provinces.name">
                    {{provinces.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="城市" prop="city">
<!--                <a-input v-model="editForm.city" />-->
                <a-select
                    show-search
                    v-model="editForm.city"
                    placeholder="请选择城市"
                    option-filter-prop="children"
                    style="width: 200px"
                >
                  <a-select-option
                      v-for="(city,index) in cityList"
                      :key="city.code"
                      @click="selectChange_city(city.name)"
                      :value="city.name">
                    {{city.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="身份" prop="type">

                <a-select default-value="a" v-model="valueEdit" style="width: 200px" @change="selectGroup">
                  <a-select-option value="a">
                    医生
                  </a-select-option>
                  <a-select-option value="b">
                    护士
                  </a-select-option>
                  <a-select-option value="c">
                    医技
                  </a-select-option>
                  <a-select-option value="d">
                    药师
                  </a-select-option>
                  <a-select-option value="e">
                    学生
                  </a-select-option>
                  <a-select-option value="f">
                    其他医务从业者
                  </a-select-option>
                  <a-select-option value="g">
                    非医务从业者
                  </a-select-option>
                  <a-select-option value="h">
                    其他
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset:14}">
                <a-button style="margin-right: 10px;" @click="editCloseBtn">
                  取消
                </a-button>
                <a-button type="primary" @click="editSubmit">
                  确定
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </a-drawer>
        <!--    查看对话框-->
        <a-drawer
            title="查看"
            placement="right"
            :closable="false"
            :visible="lookVisible"
            @close="lookClose"
            width="400"
        >
          <div class="addForms">
            <a-form-model :model="lookForm" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-model-item label="姓名">
                <!--            <a-input v-model="lookForm.name"/>-->
                <div>{{lookForm.name}}</div>
              </a-form-model-item>
              <a-form-model-item label="手机号码">
                <div>{{':'+lookForm.tel}}</div>
                <!--            <a-input v-model="lookForm.tel"/>-->
              </a-form-model-item>
              <a-form-model-item label="医院">
                <div>{{lookForm.hospital_name}}</div>
                <!--            <a-input v-model="lookForm.hospital_name" />-->
              </a-form-model-item>
              <a-form-model-item label="科室">
                <div>{{lookForm.hospital_offices}}</div>
                <!--            <a-input v-model="lookForm.hospital_offices" />-->
              </a-form-model-item>
              <a-form-model-item label="职称">
                <div>{{lookForm.job_title}}</div>
                <!--            <a-input v-model="lookForm.job_title" />-->
              </a-form-model-item>

              <a-form-model-item label="省份">
                <div>{{lookForm.province}}</div>
                <!--            <a-input v-model="lookForm.hospital_name" />-->
              </a-form-model-item>
              <a-form-model-item label="城市">
                <div>{{lookForm.city}}</div>
                <!--            <a-input v-model="lookForm.hospital_offices" />-->
              </a-form-model-item>
              <a-form-model-item label="注册时间">
                <div>{{':'+lookForm.created_time}}</div>
                <!--            <a-input v-model="lookForm.created_time" />-->
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset:18}">
                <a-button  @click="closeLook">
                  关闭
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </a-drawer>
      </div>
    </div>
    </a-spin>
  </div>


</template>

<script>
import {
  adimnListCon,
  delAdminList,
  getMedicalStaff,
  postAdmin,
  restListDatas,
  restPassWorld, totalList
} from "../../../service/recruitment";
import json from '../../../assets/city.json'
export default {
  data(){
    return{
      json:json,
      spinning:false,
      delVisible:false,
      columns:[
        {
          title: '姓名',
          dataIndex: 'name',
          align: 'center',
          width: '10%'
        },
        {
          title: '手机号',
          dataIndex: 'tel',
          align: 'center',
          width: '17%',
        },
        {
          title: '医院',
          dataIndex: 'hospital_name',
          align: 'center',
          width: '20%',
        },
        {
          title: '科室',
          dataIndex: 'hospital_offices',
          align: 'center',
          width: '10%',
        },
        {
          title: '职称',
          dataIndex: 'job_title',
          align: 'center',
          width: '10%',
        },
        {
          title: '注册时间',
          dataIndex: 'created_time',
          align: 'center',
          width: '12%',
          scopedSlots: { customRender: 'created_time' }
        },
        {
          title: '操作',
          dataIndex: 'system',
          width: '15%',
          align: 'center',
          scopedSlots: { customRender: 'system' }
        },
      ],
      medicalList:[],
      selectedRowKeys: [],
      //新增
      addVisible:false,
      //修改
      editVisible:false,
      lookVisible:false,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      addForm: {
        name: '',
        tel:'',
        hospital_name: '',
        hospital_offices:'',
        job_title: '',
        province:undefined,
        city:undefined,
      },
      rules:{
        name: [{ required: true, message: '请输入名字', trigger: 'blur' }],
        tel: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { min: 11, max: 11, message: '请输入11为手机号', trigger: 'blur' },
        ],
        hospital_name: [{ required: true, message: '请输入医院名称', trigger: 'blur' }],
        hospital_offices: [{ required: true, message: '请输入科室名称', trigger: 'blur' }],
        job_title: [{ required: true, message: '请输入职称', trigger: 'blur' }],
        province: [{ required: true, message: '请输入省份', trigger: 'blur' }],
        city: [{ required: true, message: '请输入城市', trigger: 'blur' }],
      },
      editForm: {
        name: '',
        tel:'',
        hospital_name: '',
        hospital_offices:'',
        job_title: '',
        province:undefined,
        city:undefined,
        type:'',
      },
      editRules:{
        name: [{ required: true, message: '请输入名字', trigger: 'blur' }],
        tel: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { min: 11, max: 11, message: '请输入11为手机号', trigger: 'blur' },
        ],
        hospital_name: [{ required: true, message: '请输入医院名称', trigger: 'blur' }],
        hospital_offices: [{ required: true, message: '请输入科室名称', trigger: 'blur' }],
        job_title: [{ required: true, message: '请输入职称', trigger: 'blur' }],
        province: [{ required: true, message: '请输入省份', trigger: 'blur' }],
        city: [{ required: true, message: '请输入城市', trigger: 'blur' }],
        type: [{ required: true, message: '请选择身份', trigger: 'change' }],
      },
      searchForm: {
        name: '',
        tel:'',
        hospital_name: '',
        hospital_offices:'',
        job_title: '',
        creatStartTime: '',
        createEndTime: '',
      },
      lookForm: {
        name: '',
        tel:'',
        hospital_name: '',
        hospital_offices:'',
        job_title: '',
        province: '',
        city: '',
        created_time: '',
      },
      searchList:[],
      addAdminList:[],
      user_id:'',
      userId:[],
      editDatas:{},
      value:'a',
      valueEdit:'a',
      //身份type
      typeData:'1001',
      listCount:null,
      cityList:[],
      cityListAdd:[],
      pageNo:1,
      pageSize:10,
      timeVal:[],
    }
  },
  mounted() {
    this.getList()
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"医护人员管理")
  },
  methods:{
    //控制type
    async onChange(){
      this.searchForm = {}
      switch(this.value){
        case 'a':
          //医生
          this.typeData = '1001'
          this.valueEdit = 'a'
          //刷新列表
          await this.getList()
          break;
        case 'b':
          //护士
          this.typeData = '1002'
          this.valueEdit = 'b'
          //刷新列表
          await this.getList()
          break;
        case 'c':
          //医技
          this.typeData = '1003'
          this.valueEdit = 'c'
          //刷新列表
          await this.getList()
          break;
        case 'd':
          //药师
          this.typeData = '1004'
          this.valueEdit = 'd'
          //刷新列表
          await this.getList()
          break;
        default:
          break;
      }
    },
    async selectGroup(){
      switch(this.valueEdit){
        case 'a':
          //医生
          this.typeEdit = '1001'
          this.editForm.type = this.typeEdit
          break;
        case 'b':
          //护士
          this.typeEdit = '1002'
          this.editForm.type = this.typeEdit
          break;
        case 'c':
          //医技
          this.typeEdit = '1003'
          this.editForm.type = this.typeEdit
          break;
        case 'd':
          //药师
          this.typeEdit = '1004'
          this.editForm.type = this.typeEdit
          break;
        case 'e':
          //护士
          this.typeEdit = '1005'
          this.editForm.type = this.typeEdit
          break;
        case 'f':
          //医技
          this.typeEdit = '1006'
          this.editForm.type = this.typeEdit
          break;
        case 'g':
          //药师
          this.typeEdit = '1007'
          this.editForm.type = this.typeEdit
          break;
        case 'h':
          //其他
          this.typeEdit = '0'
          this.editForm.type = this.typeEdit
          break;
        default:
          break;
      }
    },
    //获取列表
    async getList(data){
      this.spinning = true
      const type = this.typeData
      const medicalLists = await getMedicalStaff(type,data)
      if(medicalLists.code === 0){
        this.medicalList = medicalLists.data.rows
        this.listCount = medicalLists.data.count
      }
      this.spinning = false
    },
    //搜索列表功能
    checkBtn(){
      this.spinning = true
      const reponse = this.searchForm
      const data = {
        page_no:this.pageNo,
        page_size:this.pageSize,
       job_title : reponse.job_title,
       tel : reponse.tel,
       hospital_name : reponse.hospital_name,
       hospital_offices : reponse.hospital_offices,
       name : reponse.name,
       creatStartTime : reponse.creatStartTime,
       createEndTime : reponse.createEndTime,
      }
      this.getList(data)
      this.spinning = false
    },
    //新增对话框提交按钮
    onSubmit() {
      this.$refs.addRuleForm.validate(async valid => {
        if (valid) {
          const responce = this.addForm
          const data = responce
          const type = this.typeData
          const addAdminLists = await postAdmin(type, data)
          if (addAdminLists.code === 0) {
            this.addAdminList = addAdminLists.data
            //关闭对话框
            this.addVisible = false
            //清空表单
            this.addForm = {}
            this.$message.success("添加成功！")
            //刷新列表
            await this.getList()
          } else {
            this.$message.warning('失败'+ addAdminLists.message)
          }
        } else {
          return false;
        }
      });
    },
    //删除列表确认按钮
    async deleteBtn(){
      this.spinning = true
      const userIds = this.user_id
      if(userIds !== '' && userIds !== null && userIds !== undefined){
        const reponse = await delAdminList(userIds)
        if(reponse.code === 0){
          this.$message.success(reponse.data)
          //刷新列表
          const reponse = this.searchForm
          const data = {
            page_no:this.pageNo,
            page_size:this.pageSize,
            job_title : reponse.job_title,
            tel : reponse.tel,
            hospital_name : reponse.hospital_name,
            hospital_offices : reponse.hospital_offices,
            name : reponse.name,
            creatStartTime : reponse.creatStartTime,
            createEndTime : reponse.createEndTime,
          }
          await this.getList(data)
          //清空user_id
          this.user_id = ''
        }else {
          this.$message.error('出错请联系管理员！' + reponse.message)
        }
        //清空
        this.selectedRowKeys = []
      }else {
        this.$message.warning("请选择需要删除的列表！")
      }
      this.spinning = false
    },
    //重置密码
    async restBtn(){
      this.spinning = true
      const userId = this.user_id
      if(userId !== '' && userId !== null && userId !== undefined){
        const reponse = await restPassWorld(userId)
        if(reponse.code === 0){
          this.$message.success("修改成功！")
          //清空
          this.selectedRowKeys = []
          //刷新列表
          const form = this.searchForm
          const data = {
            page_no:this.pageNo,
            page_size:this.pageSize,
            job_title : form.job_title,
            tel : form.tel,
            hospital_name : form.hospital_name,
            hospital_offices : form.hospital_offices,
            name : form.name,
            creatStartTime : form.creatStartTime,
            createEndTime : form.createEndTime,
          }
          await this.getList(data)
          //清空user_id
          this.user_id = ''
        }else {
          this.$message.error('出错请联系管理员！' + reponse.message)
        }
      }else {
        this.$message.warning("请选择需要重置密码的列表！")
      }
      this.spinning = false
    },
    //获取id
    onSelectChange(selectedRowKeys){
      //选择的列表的id
      this.user_id = selectedRowKeys
      //显示是否被选中
      this.selectedRowKeys = selectedRowKeys;
    },
    //重置搜索输入框
    resetBtn(){
      this.spinning = true
      this.searchForm = {}
      this.pageNo = 1
      this.pageSize = 10
      this.timeVal = []
      this.spinning = false
      this.getList()
    },
    //点击新增对话框弹框
    addBtn(){
      this.addVisible = true
    },
    //新增对话框关闭
    addClose(){
      this.addVisible = false
    },
    //新增对话框的取消按钮
    addCloseBtn(){
      this.addVisible = false
    },
    //点击查看按钮打开对话框
    async lookBtn(row){
      this.spinning = true
      this.lookVisible = true
      const reponce = await adimnListCon(row.id)
      if(reponce.code === 0){
        this.lookForm = reponce.data
      }else {
        this.$message.warning("出错请联系管理员" + reponce.message)
      }
      this.spinning = false
    },
    //点击操作按钮打开对话框
    async operateBtn(row) {
      this.spinning = true
      //将user_id赋值
      this.userId = row.id
      const reponce = await adimnListCon(row.id)
      if (reponce.code === 0) {
        let form = this.editForm
        form.tel= reponce.data.tel
        form.hospital_name = reponce.data.hospital_name
        form.hospital_offices = reponce.data.hospital_offices
        form.job_title = reponce.data.job_title
        form.name = reponce.data.name
        form.province = reponce.data.province
        form.city = reponce.data.city
        form.type = reponce.data.type
        form.id = reponce.data.id
      } else {
        this.$message.warning("出错请联系管理员" + reponce.message)
      }
      //获取城市列表
      await this.Change_city()
      this.editVisible = true
      //获取当前行的数据
      this.spinning = false
    },
    editClose(){
      this.editVisible = false
    },
    //查看对话框的关闭功能
    lookClose(){
      this.lookVisible = false
    },
    //修改对话框中的取消按钮
    editCloseBtn(){
      //关闭对话框
      this.editVisible = false
    },
    //修改对话框中的确认按钮
    editSubmit(){
      this.$refs.editRuleForm.validate(async valid => {
        if (valid) {
          const user_id = this.userId
          const datas = this.editForm
          const reponce = await restListDatas(user_id,datas)
          if (reponce.code === 0) {
            this.$message.success("修改成功")
            //关闭对话框
            this.editVisible = false
            //刷新列表
            let form = this.searchForm
            let data = {
              page_no:this.pageNo,
              page_size:this.pageSize,
              name: form.name,
              tel:form.tel,
              hospital_name: form.hospital_name,
              hospital_offices:form.hospital_offices,
              job_title: form.job_title,
              creatStartTime: form.creatStartTime,
              createEndTime: form.createEndTime
            }
            await this.getList(data)
          } else {
            this.$message.warning("出错！" + reponce.message)
          }
        } else {
          return false;
        }
      });
    },
    //查看的取消按钮
    closeLook(){
      this.lookVisible = false
    },
    //分页效果
    listChange(pageNo, pageSize){
      //page   第几页, pageSize  一页有多少条
      this.pageNo = pageNo
      this.pageSize = pageSize
      const form = this.searchForm
      const data = {
        page_no:this.pageNo,
        page_size:this.pageSize,
        job_title : form.job_title,
        tel : form.tel,
        hospital_name : form.hospital_name,
        hospital_offices : form.hospital_offices,
        name : form.name,
        creatStartTime : form.creatStartTime,
        createEndTime : form.createEndTime,
      }
      this.getList(data)
    },
    enterSearch(){
      this.checkBtn()
    },

    selectChange_privice(name,index){
      this.editForm.city = undefined
      this.editForm.province = name
      //选择省份获取城市
      this.cityList =  this.json[index].cityList
    },
    Change_city(){
      let provinces = this.editForm.province
      if(provinces !== '' && provinces !== null && provinces !== undefined){
        let list = this.json
        for (let i=0;i<list.length;i++){
          if(provinces === this.json[i].name){
            this.cityList = this.json[i].cityList
          }
        }
      }
    },
    selectChange_city(name){
      this.editForm.city = name
    },
    selectChange_priviceAdd(name,index){
      this.addForm.province = name
      this.addForm.city = undefined
      //选择省份获取城市
      this.cityListAdd =  this.json[index].cityList
    },
    selectChange_cityAdd(name){
      this.editForm.city = name
    },
    onChange_range_picker(date, dateString){
      this.searchForm.creatStartTime = dateString[0]
      this.searchForm.createEndTime = dateString[1]
    },
  }
}
</script>

<style lang="scss" scoped>
.bigDiv{
  display: flex;
}
.divLeft{
  width: 13%;
  height: 100%;
  padding-top: 15px ;
  background-color: #ffffff;
  .divLeft-btn{
    width: 100%;
    //margin-top: 15px;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
    padding-left: 30px;
    border: white;
  }
}
.divRight{
  width: 85%;
  height: 100%;
  background-color: #ffffff;
}
.bgc-staff{
  background-color: #f5f5f5;
  width: 100%;
  height: auto;
  border-bottom: 15px solid #f5f5f5;
  border-left: 15px solid #f5f5f5;
}
.bgc-staff-two{
  background-color: #f5f5f5;
  width: 100%;
  height: auto;
  border-left: 15px solid #f5f5f5;
}
.medical-bgc{
  width: 100%;
  margin: auto;
  height: 150px;
  background-color: #fff;
  .medical-top{
    color: white;
    width: 100%;
    height: 150px;
    .medical-top-top{
      background-color: #fff;
      padding-top: 15px ;
      display: flex;
      width: 100%;
      .medical-top-title{
        color: black;
        width: 25%;
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
      .medical-top-expert{
        color: black;
        width: 25%;
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
    }
    .medical-top-expert-Two{
      color: black;
      height: 30px;
      margin-top: 15px;
      line-height: 30px;
      text-align: center;
    }
    .medical-bottom-btn{
      width: 25%;
      text-align: center;
      height: auto;
      margin-top: 15px;
    }
  }
}
.staff-table{
  width: 100%;
  height: auto;
  background-color: #fff;
  margin: auto;
  .staff-table-btn{
    width: 98%;
    padding-top: 15px;
    margin-left: 20px;
    margin-bottom: 20px;
    .staff-table-btn-btn{
      margin-right: 10px;
    }
  }
}
.addForms{
  width: 100%;
  height: auto;

}


</style>