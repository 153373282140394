import { llget, put, post, del, postUserId, putUserId, delUserId, get,patch,drugGet } from "./base";
import {postFeedBackById,patchFeedBackById} from './base_y'
import qs from "qs";

// 查询意见反馈
export function getFeedback(type,degree,channel,content,status,pageSize,pageNum,memberId){
	return drugGet(`/v3/yun/admin/selectFeedback?type=${type}&degree=${degree}&channel=${channel}&content=${content}&status=${status}&pageSize=${pageSize}&pageNum=${pageNum}&memberId=${memberId}`) 
}

// 按id查询描述详情 
export function getContentDetails(id){
	return get(`/v3/yun/admin/feedbackById?id=${id}`) 
}

//新增意见（传id到请求头）
export function PostFeedback(data){
    return postFeedBackById('/v3/yun/admin/addFeedback',data)
}

//修改（更新）意见（传id到请求头）还需要传一个参数id
export function PostChangeFeedback(id,data){
    return patchFeedBackById('/v3/yun/admin/editFeedback?id='+id,data)
}

// 修改解决方案  
export function patchModifyScheme(id,solver,solution,processDate){
	return patch(`/v3/yun/admin/editProcess?id=${id}&solver=${solver}&solution=${solution}&processDate=${processDate}`) 
}

// Modify the scenario

// export function getLiveScoreRecordsByConventionId(conventionId){
//     return get('/api/admin/getLiveScoreRecordsByConventionId?conventionId='+conventionId)
// }

// export function get_staff_list(contentId,data){
//     return get("/api/admin/meetMember/"+contentId,data)
// }

// 安排弹窗
export function postArrange(id,type,plannedDate){
	return post(`/v3/yun/admin/insertProcess?id=${id}&type=${type}&plannedDate=${plannedDate}`) 
}

// 已解决弹窗
export function patchResolved(id,solver,solution){
	return patchFeedBackById(`/v3/yun/admin/resolveFeedback?id=${id}&solver=${solver}&solution=${solution}`) 
}

// 解决方案弹窗
export function patchResolvedScheme(id,solver,solution,processDate){
	return patch(`/v3/yun/admin/feedbackOption?id=${id}&solver=${solver}&solution=${solution}&processDate=${processDate}`) 
}


// 获取解决方案列表
export function getResolved(id){
	return get(`/v3/yun/admin/processList?id=${id}`) 
}

// 更新未解决的状态/v3/yun/admin/unsolved
export function patchUnesolved(id){
	return patch(`/v3/yun/admin/unsolved?id=${id}`) 
}

// 更新点击重新打开时的状态
export function patchReConfirm(id){
	return patch(`/v3/yun/admin/restart?id=${id}`) 
}

// 查询解决方案列表   可能需要注释
export function getScheme(id){
	return get(`/v3/yun/admin/processList?id=${id}`) 
}

// 删除意见反馈  http://192.168.31.101:55555/v3/yun/admin/deleteFeedback?id=48
export function delFeekBack(id){
	return del(`/v3/yun/admin/deleteFeedback?id=${id}`) 
}

// 重启意见反馈
export function patchReset(id){
	return patch(`/v3/yun/admin/reset?id=${id}`) 
}

// 关闭意见反馈状态
export function patchClose(id){
	return patch(`/v3/yun/admin/close?id=${id}`) 
}

// 暂不处理意见反馈
export function patchPause(id){
	return patch(`/v3/yun/admin/pause?id=${id}`) 
}

// //新增会员到参会人员
// export function PostRegisterMember(memberId, meetId){
//     return postBuId("/api/admin/insMeetMember?memberId="+memberId+'&meetId='+meetId)
// }

// 查询病程接口
// export function getCourse(startTime,endTime,name,pageNum,pageSize){
// 	return get(`/v3/coursePatient?startTime=${startTime}&endTime=${endTime}&name=${name}&pageNum=${pageNum}&pageSize=${pageSize}`) 
// }

