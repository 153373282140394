import {
  del,
  delUserId,
  get,
  post,
  postUserId,
  put,
  putById,
  putUserId,
  getDrugExel,
} from "./base";
import { getEpb, getEpbXtoken } from "./base_yun";
import qs from "qs";
/**
 首页功能内容api
 */

// app首页内容获取
export function getAppContent(column_code, page_no, page_size) {
  return getEpb("/api2/v1/app/home/getHome", {
    column_code,
    page_no,
    page_size,
  });
}
export function getAppContent2(pageNum) {
  return get("/v3/yun/contents", { pageNum });
}
// app首页内容排序
export function postAppContentMove(id, data) {
  return post("/v3/yun/content/moveUpDown/" + id, qs.stringify(data));
}
export function delAppContent(id) {
  return delUserId("/v3/yun/content/" + id);
}
// 社区首页内容获取

export function getCommunityContent2(title, isOpen, isTop, pageNo, pageSize) {
  return get("/v3/yun/community/content/publish", {
    title,
    isOpen,
    isTop,
    pageNo,
    pageSize,
  });
}
export function getCommunityContent(page_no, page_size) {
  return getEpbXtoken("/api2/v1/app/home/getCommunityHome", {
    page_no,
    page_size,
  });
}
export function getCommunityContentCreateByName(pageNo, pageSum) {
  return get("/v3/yun/community/content/name", { pageNo, pageSum });
}
// 移除app首页内容
export function delCommunityContent(id) {
  return delUserId("/v3/yun/community/content/remove/" + id);
}
// export function getCommunityContent(pageNum,isOpen,title,time){
//     return get('/v3/yun/community/content',{pageNum,isOpen,title,time})
// }
//社区内容是否置顶
export function putCommunityContentTop(id, data) {
  return putUserId("/v3/yun/community/content/top/" + id, qs.stringify(data));
}
//社区内容是否上架下架
export function putCommunityContentOpen(id, data) {
  return putUserId("/v3/yun/community/content/" + id, qs.stringify(data));
}
//社区内容是否删除
export function putCommunityContentDel(id) {
  return putById("/v3/yun/community/content/del?id=" + id);
}
//修改社区内容
export function putCommunityContentEdit(data) {
  return putById("/v3/yun/community/content", data);
}
//发布社区内容
export function postCommunityContent(data) {
  return postUserId("/v3/yun/community/content", data);
}

// 社区帖子浏览记录
export function getReadRecord(pageNo, pageSize, startTime, endTime) {
  return get(
    `/v3/communityVisit?pageNo=${pageNo}&pageSize=${pageSize}&startTime=${startTime}&endTime=${endTime}`
  );
}
// 导出帖子浏览记录
// export function getExport(startTime, endTime) {
//   return getDrugExel(
//     `/v3/communityVisit/export?startTime=${startTime}&endTime=${endTime}`
//   );
// }
export function getExport(data) {
  return getDrugExel('/v3/communityVisit/export',data)
}
