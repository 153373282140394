<template>
<div>
  <a-spin :spinning = "spinning">

    <div style="margin-bottom: 5px;
          display: flex;
          justify-content: space-between">
      <a-button type="primary"
                @click="open_add_model"
      >添加
      </a-button>
      <a-input-search
          style="width: 300px"
          placeholder="输入标题搜索"
          enter-button
          @search="search_getLive_meetList" />
    </div>

    <div class="live_meet_table">
      <a-table :columns="live_meet_columns"
               rowKey="id"
               :data-source="live_meet.rows"
               :pagination="pagination"
               @change="table_change"
      >
<!--        标题-->
      <span @click="open_details_model(record.id)"
            slot="title_handle"
            slot-scope="text,record"
            style="cursor: pointer;color: #1890ff">
        {{text}}
      </span>
<!--查看推流拉流地址-->
      <span slot="pull_push" slot-scope="text,record">
        <a-space>
           <a-button type="primary" @click="open_pull_push_model(record)">
            查看
          </a-button>
        </a-space>
      </span>
<!--修改-->
      <span slot="operation" slot-scope="text,record">
        <a-space>
          <a-dropdown>
          <a-menu slot="overlay" >
            <a-menu-item key="1" @click="open_alter_btn(record.id)">修改</a-menu-item>
            <a-menu-item key="2" @click="open_live_protocol_model(record.id)">协议设置</a-menu-item>
            <a-menu-item key="3" @click="open_live_duration_model(record.id)">时长设置 </a-menu-item>
            <a-menu-item key="4" @click="open_live_Look_back(record.id)">直播回放设置</a-menu-item>
            <a-menu-item key="5" @click="open_live_login_type_model(record)">登录类型设置 </a-menu-item>
            <a-menu-item key="6" @click="open_live_convention(record.id)">绑定大会直播 </a-menu-item>
            </a-menu>
            <a-button style="margin-left: 8px" type="primary"> 操作 <a-icon type="down" /> </a-button>
          </a-dropdown>
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="download_record(record.id)">下载观看记录 </a-menu-item>
              <a-menu-item key="2" @click="download_login_export(record.id)">下载姓名医院登录记录</a-menu-item>
              <a-menu-item key="3" @click="downloadTotal_viewing_time(record.id)">下载人员观看总时长</a-menu-item>
              </a-menu>
              <a-button style="margin-left: 8px" type="primary"> 下载 <a-icon type="down" /> </a-button>
          </a-dropdown>
        </a-space>
      </span>
      </a-table>
    </div>


<!--   =================== 对话框================ -->

<!--    推流拉流对话框  -->
    <a-modal v-model="pull_push_visible" width="60%">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="推流地址">
          <a-descriptions :column="8">
            <a-descriptions-item :span="7" label="服务器">
              {{ pull_push_text. obs_push_ip}}
            </a-descriptions-item>
            <a-descriptions-item :span="1">
              <a-icon type="copy" class="copy_icon"
                  v-clipboard:copy="pull_push_text.obs_push_ip"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"/>
            </a-descriptions-item>
            <a-descriptions-item :span="7" label="串流密钥">
              {{pull_push_text.obs_push_name}}
            </a-descriptions-item>
            <a-descriptions-item :span="1">
              <a-icon type="copy" class="copy_icon"
                  v-clipboard:copy="pull_push_text.obs_push_name"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
              />
            </a-descriptions-item>
          </a-descriptions>
        </a-tab-pane>
        <a-tab-pane key="2" tab="拉流地址">
          <a-descriptions :column="8">
            <a-descriptions-item :span="7" label="rtmp">
              {{ pull_push_text. pull_rtmp_ip}}
            </a-descriptions-item>
            <a-descriptions-item :span="1">
              <a-icon type="copy" class="copy_icon"
                      v-clipboard:copy="pull_push_text.pull_rtmp_ip"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"/>
            </a-descriptions-item>
            <a-descriptions-item :span="7" label="flv">
              {{ pull_push_text. pull_flv_ip}}
            </a-descriptions-item>
            <a-descriptions-item :span="1">
              <a-icon type="copy" class="copy_icon"
                      v-clipboard:copy="pull_push_text.pull_flv_ip"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"/>
            </a-descriptions-item>
            <a-descriptions-item :span="7" label="m3u8">
              {{ pull_push_text. pull_m3u8_ip}}
            </a-descriptions-item>
            <a-descriptions-item :span="1">
              <a-icon type="copy" class="copy_icon"
                      v-clipboard:copy="pull_push_text.pull_m3u8_ip"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"/>
            </a-descriptions-item>
          </a-descriptions>
        </a-tab-pane>
      </a-tabs>
    </a-modal>

<!--      添加会议对话框     -->
    <a-modal v-model="add_live_meet_visible"
    @ok="submit_add_live_meet">
      <a-spin :spinning="spinning">
        <a-form-model
          ref="addFrom"
          :rules="FromRules"
          :model="addFrom" :labelCol="{ span: 4 }" :wrapperCol="{ span: 14 }">
        <!--        视频封面-->
        <a-form-model-item class="update" label="视频封面" prop="cover_url">
          <div class="updateFile">
            <input type="file" ref="add_cover_url" class="container"
                   @change="ImageUpdate('add_cover_url')"
                   accept="image/*">
          </div>
        </a-form-model-item>
        <a-card  class="image_container"
                 style="margin: -20px 10px 10px 16%;
                        width: 300px;">
          预览图:
          <img v-if="addFrom.cover_url"
               :src="addFrom.cover_url"
               :alt="addFrom.cover_url">
        </a-card>
        <!--        宣传海报-->
        <a-form-model-item class="update" label="宣传海报" prop="desc_image_url">
          <div class="updateFile">
            <input type="file" ref="add_desc_image_url" class="container"
                   @change="ImageUpdate('add_desc_image_url')"
                   accept="image/*">
          </div>
        </a-form-model-item>
        <a-card class="image_container"
                style="margin: -20px 10px 10px 16%;
                width: 300px;">
          预览图:
          <img v-if="addFrom.desc_image_url" :src="addFrom.desc_image_url" :alt="addFrom.desc_image_url">
        </a-card>
        <!--     标题   -->
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="addFrom.title" />
        </a-form-model-item>
        <!--     开始时间  -->
        <a-form-model-item label="开始时间" prop="start_time">
          <a-date-picker show-time placeholder="Select Time"
                         v-model="addFrom.start_time"
                         :default-value="moment('00:00:00', 'HH:mm:ss')"
                         valueFormat="YYYY-MM-DD HH:mm:ss"
                         />
        </a-form-model-item>
        <!--     结束时间  -->
        <a-form-model-item label="结束时间" prop="end_time">
          <a-date-picker show-time placeholder="Select Time"
                         v-model="addFrom.end_time"
                         :default-value="moment('00:00', 'HH:mm')"
                         valueFormat="YYYY-MM-DD HH:mm:ss"
                         />
        </a-form-model-item>
      </a-form-model>
      </a-spin>
    </a-modal>

<!--          修改对话框    -->
    <a-modal v-model="alter_live_meet_visible"
    :title="alterFrom.title"
    @ok="submit_alter_live_meet">
      <a-spin :spinning="spinning">
        <a-form-model
          ref="alterFrom"
          :rules="FromRules"
          :model="alterFrom" :labelCol="{ span: 4 }" :wrapperCol="{ span: 14 }">
        <!--        视频封面-->
        <a-form-model-item class="update" label="视频封面" prop="cover_url">
          <div class="updateFile">
            <input type="file" ref="alter_cover_url" class="container"
                   @change="ImageUpdate('alter_cover_url')"
                   accept="image/*">
          </div>
        </a-form-model-item>
        <a-card  class="image_container"
                 style="margin: -20px 10px 10px 16%;
                        width: 300px;">
          预览图:
          <img
              v-if="alterFrom.cover_url"
               :src="alterFrom.cover_url"
               :alt="alterFrom.cover_url">
        </a-card>
        <!--        宣传海报-->
        <a-form-model-item class="update" label="宣传海报" prop="desc_image_url">
          <div class="updateFile">
            <input type="file" ref="alter_desc_image_url" class="container"
                   @change="ImageUpdate('alter_desc_image_url')"
                   accept="image/*">
          </div>
        </a-form-model-item>
        <a-card class="image_container"
                style="margin: -20px 10px 10px 16%;
                width: 300px;">
          预览图:
          <img v-if="alterFrom.desc_image_url"
               :src="alterFrom.desc_image_url"
               :alt="alterFrom.desc_image_url">
        </a-card>
        <!--     标题   -->
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="alterFrom.title" />
        </a-form-model-item>
      </a-form-model>
      </a-spin>
    </a-modal>

<!--    点击标题详情对话框   -->
    <a-modal v-model="live_meet_details_visible"
    @cancel="close_details_model">
<!--      { "id": 501, "title": "测试数据上报", "cover_url":"start_time": "2022-01-12T16:00:00.000Z", "end_time": "2022-01-13T16:00:00.000Z", "desc_image_url": "https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/e885c9f5-1db2-4b7e-86ca-69a3e394e3fb.png", "protocol_is_open": 0, "protocol_content": null, "created_time": "2022-01-13T07:09:39.000Z", "duration_is_open": 0, "duration_remark": null, "login_type": "phone_code", "raffle_is_open": 0, "raffle_is_finish": 0 }-->
<!--      {{live_meet_details_content}}-->
      <a-descriptions :title="live_meet_details_content.title"
                      :column="1">
        <a-descriptions-item label="会议ID">
          {{live_meet_details_content.id}}
        </a-descriptions-item>
        <a-descriptions-item label="标题">
          {{live_meet_details_content.title}}
        </a-descriptions-item>
        <a-descriptions-item label="会议时间">
          {{live_meet_details_content.start_time|dateFrm}}-{{live_meet_details_content.end_time|dateFrm}}
        </a-descriptions-item>
        <a-descriptions-item label="封面图">
          <img
              style="max-width:80%"
            :src="live_meet_details_content.cover_url"
            alt="封面图"/>
        </a-descriptions-item>
        <a-descriptions-item label="宣传海报">
          <img
              style="max-height: 500px;height: auto;max-width: 80%"
              :src="live_meet_details_content.desc_image_url"
              alt="宣传海报"/>
        </a-descriptions-item>
      </a-descriptions>
      <!--    去除a-model自带的取消和确认-->
      <div slot="footer"></div>
    </a-modal>

<!--    协议设置对话框       -->
    <a-modal v-model="live_protocol_visible"
             :title="live_protocol_details.title"
             width="75%"
    @ok="submit_alter_live_protocol(live_protocol_details)">
      <a-spin :spinning="spinning">
        <a-radio-group name="radioGroup" v-model="live_protocol_details.protocol_is_open">
          是否开启协议：
          <a-radio :value=0>
            关闭
          </a-radio>
          <a-radio :value=1>
            开启
          </a-radio>
        </a-radio-group>
        <br>
        协议内容：<br>
        <div  ref="editor"></div>
      </a-spin>
    </a-modal>

<!--    时长设置对话框        -->
    <a-modal v-model="live_duration_visible" width="50%"
             :title="live_duration_details.title"
      @ok="submit_live_duration_alter(live_duration_details)">
<!--      "duration_is_open": 0, "duration_remark": null,   -->
      <a-spin :spinning="spinning">
        <a-radio-group name="radioGroup" v-model="live_duration_details.duration_is_open">
          是否开启时长：
          <a-radio :value=0>
            关闭
          </a-radio>
          <a-radio :value=1>
            开启
          </a-radio>
        </a-radio-group>
        <br>
        时长备注:
        <a-textarea
            style="width: 100%"
            placeholder="请输入" :rows="4"
            v-model="live_duration_details.duration_remark"/>
      </a-spin>
    </a-modal>

<!--    登录类型设置对话框-   -->
    <a-modal v-model="live_login_type_visible"
             :title="live_login_type_alter_details.title"
              @ok="submit_alter_login_type">
      <a-radio-group name="radioGroup" v-model="live_login_type_alter_details.login_type">
        <a-radio value="phone_code">
          手机验证码
        </a-radio>
        <a-radio value="name_hospital">
          姓名医院
        </a-radio>
      </a-radio-group>
    </a-modal>

<!--   直播回看设置       -->
    <a-modal  v-model="live_Look_back_visible" width="80%" :title="'直播回看设置'+live_Look_back_details.id">
      <a-descriptions :column="1">
        <a-descriptions-item label="是否开启回看">
          <!--    回放是否开启0：否1：是      -->
          <a-button
              @click="alterReplay_is_open(live_Look_back_details.id,live_Look_back_details.replay_is_open===1?0:1)"
              :type="live_Look_back_details.replay_is_open===1?'danger':'primary'"
              :loading="live_Look_back_button_disable">
            {{live_Look_back_details.replay_is_open===1?'关闭':'开启'}}
          </a-button>
        </a-descriptions-item>
        <a-descriptions-item label="回看列表">
          <a-table :loading="spinning" :columns="live_Look_back_columns" :data-source="live_Look_back_details.video_list">
            <span slot="operate" slot-scope="text,record">
              <a-space>
                <a-button type="primary" @click="alter_live_look_back_title(record.id,record.title)">修改</a-button>
                <a-popconfirm  @confirm="deleteLiveLookBack(record.id)" title="确定删除吗？" ok-text="Yes" cancel-text="No">
                  <a-button type="danger">删除</a-button>
                </a-popconfirm>
              </a-space>
            </span>
          </a-table>
        </a-descriptions-item>
      </a-descriptions>
      <template slot="footer">
        <a-button @click="(()=>{this.live_Look_back_visible=false})">
          关闭
        </a-button>
      </template>
    </a-modal>

<!--    修改单个直播回看视频标题-->
    <a-modal :title="'修改直播回看视频标题:'+alter_Look_back_video_detail.id" @ok="get_alter_live_look_back_title" v-model="alter_Look_back_video_visible">
      <a-input placeholder="直播回看视频标题" v-model="alter_Look_back_video_detail.title" />
    </a-modal>

<!--    绑定大会直播  -->
    <a-modal @ok="alter_live_convention" v-model="live_convention_visible" :title="'大会直播绑定:'+alter_live_convention_id">
      {{live_convention_is_check}}<br>
      大会直播列表：
      <a-select allowClear :loading="spinning"  :default-value="live_convention_is_check.title"
                 @change="((value)=>{this.live_convention_is_check.id=value})"
                 style="width: 300px">
        <a-select-option v-for="live_convention in  live_convention_lists"
                         :key="live_convention.id"
                         :value="live_convention.id">
          {{live_convention.title}}
        </a-select-option>
      </a-select>
    </a-modal>

  </a-spin>
</div>
</template>

<script>
import {
  addLive_meet,
  alter_duration_,
  alter_live_protocol,
  alter_login_type, alter_video_title, convention,
  delete_video_, live_id_convention_id,
  live_meet_details,
  modifyLive_meet,
  playback_details,
  playback_open_close_
} from "@/service/live_api";
import {getLive_meet} from "@/service/live_api_2";
import {update} from "@/utils/update";
import moment from 'moment';
import {auth_data_report} from '@/service/common'
import common from "@/common";
import E from "wangeditor";

export default {
  name: "Live",
  data(){
    return{
      //等待加载
      spinning:false,
      //直播列表
      live_meet:"",
      //表格分页配置
      pagination:{
        //配置分页
        pageSize:10,
        //总数
        total:0,
        //默认的当前页数
        current:1
      },
      // 表格配置
      live_meet_columns:[
        {
          title: 'ID',
          dataIndex: 'id',
        },
        {
          title: '标题',
          dataIndex: 'title',
          scopedSlots: { customRender: 'title_handle'}
        },
        {
          title: '开始时间',
          dataIndex: 'start_time',
        },
        {
          title: '结束时间',
          dataIndex: 'end_time',
        },
        {
          title:"获取推拉流",
          scopedSlots: {customRender: 'pull_push'}
        },
        {
          title:"操作",
          scopedSlots: {customRender: 'operation'}
        }
      ],
     //=======推流拉流配置========
      pull_push_visible:false,
      //地址详情
      pull_push_text:'',

      //========添会议=======
      add_live_meet_visible:false,
      //表单验证规则
      FromRules: {
        cover_url: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
        desc_image_url: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
        title: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
        start_time: [
          {
            required: true,
            trigger: 'change'
          },
        ],
        end_time: [
          {
            required: true,
            trigger: 'change'
          },
        ],
      },
      addFrom:{
        cover_url:null,
        desc_image_url:null,
      },

      //========修改会议=======
      alter_live_meet_visible:false,
      alterFrom:{},

      //搜索
      search_value:'',

      //===========会议详情========
      //查看详情对话框
      live_meet_details_visible:false,
      //详情内容
      live_meet_details_content:{},

      //=======登录方式=========
      live_login_type_visible:false,
      //正在修改的会议详情
      live_login_type_alter_details:{},

      //========= 协议 ==========
      live_protocol_visible:false,
      //会议详情，爆款协议内容
      live_protocol_details:{},


      //==========时长对话框======
      live_duration_visible:false,
      live_duration_details:{},


      //=========直播回看设置=====
      live_Look_back_visible:false,
      live_Look_back_details:{},
      live_Look_back_button_disable:false,
      live_Look_back_columns:[
        {
          title: 'id',
          dataIndex: 'id',
        },
        {
          title: '标题',
          dataIndex: 'title',
        },
        {
          title: '时长',
          dataIndex: 'duration',
        },
        {
          title: '链接',
          dataIndex: 'video_url',
        },
        {
          title: "操作",
          key:'operate',
          scopedSlots: { customRender: 'operate' },
        }
      ],
      //正在修改的直播回看会议id
      alter_Look_back_id:'',
      //正在修改的直播回看视频的详情，包括id和标题
      alter_Look_back_video_detail:{id:'',title:''},
      alter_Look_back_video_visible:false,

      //=========绑定大会直播======
      live_convention_visible:false,
      //大会直播列表
      live_convention_lists:'',
      //正在修改的大会绑定大会直播会议id
      alter_live_convention_id:'',
      //已绑定的大会直播列表
      live_convention_is_check:{
        id:null,
        title:null
      },

      //========富文本编辑器======
      editor:null,

    }
  },
  filters:{
    dateFrm(value){
      return moment(value).format("YYYY-MM-DD HH:mm:ss")
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"直播会议管理")
  },
  async mounted() {
    //获取会议列表-第一页
    await this.getLive_meetList(1)
  },
  methods:{
    //配置默认时间
    moment,
    //获取会议列表
    async getLive_meetList(pageNumber,title){
      this.spinning  = true
      const response = await getLive_meet(pageNumber,title);
      if (response.code === 0){
        const live_meet = response.data
        this.pagination.total = live_meet.count
        this.live_meet = live_meet
      }else {
        this.$message.error("获取列表错误。",response.message)
      }
      this.spinning = false
    },
    //搜索按钮
    async search_getLive_meetList(value) {
      //赋值搜索的值
      this.search_value = value;
      await this.getLive_meetList(1, value)
      //将页数置为1
      this.pagination.current = 1
    },
    //分页器中 页码改变的回调，参数是改变后的页码及每页条数
     async table_change(pageNumber){
        await this.getLive_meetList(pageNumber.current,this.search_value)
        this.pagination.current = pageNumber.current
    },
    //  打开推流拉流对话框
    async open_pull_push_model(record){
      this.pull_push_text = record
      this.pull_push_visible = true
    },
    // 复制成功 or 失败（提示信息！！！）
    onCopy(){
      this.$message.success("复制成功")
    },
    onError(){
      this.$message.error("复制失败，请手动复制")
    },
    //获取直播详情
    async getLive_meet_details(live_id){
      return  await live_meet_details(live_id)
    },

//=========上传图片========================

    async ImageUpdate(type) {
      this.spinning = true
      let file = this.$refs[type].files[0]
      if (file !== undefined) {
        if (file.size<2086666){
          const response = await update(file);
          if (response.code === 0) {
            //  上传成功
            //判断赋值到添加还是修改(add,alter)
            switch (type) {
              case'add_cover_url':
                this.addFrom.cover_url = response.data.url
                break;
              case 'add_desc_image_url':
                this.addFrom.desc_image_url = response.data.url
                break;
              case 'alter_cover_url':
                this.alterFrom.cover_url = response.data.url
                break;
              case 'alter_desc_image_url':
                this.alterFrom.desc_image_url = response.data.url
                break;
            }
            this.$message.success('上传成功！')
          } else {
            //  上传失败
            this.$message.error("上传失败，请重试。", response.message)
          }
        }else {
          //图片尺寸太大
          this.$message.error("图片尺寸请小于2mb")
        }
      } else {
        console.log(file)
      }
      this.spinning = false
    },
// ===========添加============

    //点击添加按钮
    open_add_model(){
      this.add_live_meet_visible = true
    },
    //提交添加
    async submit_add_live_meet(){
      //非空判断
      this.$refs.addFrom.validate(async valid => {
        if (valid) {
          const response = await addLive_meet(this.addFrom);
          if (response.code === 0) {
            //  添加成功
            //  刷新列表
            await this.getLive_meetList(1)
            //  关闭弹窗
            this.add_live_meet_visible = false
            //  清空输入
            this.$refs.addFrom.resetFields();
            //上报埋点数据
            await auth_data_report("直播","添加直播",0)
          } else {
            this.$message.error("失败", response.message)
          }
        } else {
          this.$message.error("输入为空，请完整输入~")
          return false;
        }
      });
    },

//==============修改===========

    //点击修改按钮，获取详情，打开弹窗
    async open_alter_btn(id){
      const response = await  live_meet_details(id);
      if (response.code === 0){
        //成功
        //赋值
        this.alterFrom = response.data
        //弹框
        this.alter_live_meet_visible = true
      }else {
        this.$message.error("失败"+response.message)
      }
    },
    //点击修改，完成修改
    async submit_alter_live_meet(){
      this.$refs.alterFrom.validate(async valid => {
        if (valid) {
          //  获取id
          const alterFrom = this.alterFrom
          //  提交
          const response = await modifyLive_meet(alterFrom.id, alterFrom);
          if (response.code === 0) {
            this.$message.success("修改成功!")
            //上报埋点数据
            await auth_data_report("直播","修改直播",alterFrom.id)
            //刷新列表
            await this.getLive_meetList(this.pagination.current)
            //关闭弹窗
            this.alter_live_meet_visible = false;
          }
        } else {
          this.$message.error("情完整输入后再尝试。")
          return false;
        }
      });
    },

//============获取直播详情================

    /**
     * 点击标题打开对话框获取直播详情
     * @param id
     * @returns {Promise<void>}
     */
    async open_details_model(id){
      const response = await this.getLive_meet_details(id);
      if (response.code === 0){
        //赋值
        this.live_meet_details_content = response.data
        //打开对话框
        this.live_meet_details_visible = true
      }
    },
    /**
     * 关闭直播详情对话框
     */
    close_details_model(){
      this.live_meet_details_content={}
    },

  //==========================下载观看记录====================
    //下载观看记录
    async download_record(id){
      window.open(common.yun_serve_header+'/api2/v1/admin/live-meet/'+id+'/export')
      // await download_record_(id)
    },
    //下载姓名医院登录记录
    async download_login_export(id){
      window.open(common.yun_serve_header+'/api2/v1/admin/live-meet/'+id+'/login_export')
    },
    //下载人员观看总时长
    async  downloadTotal_viewing_time(stream_id){
      window.open('http://yun-new-admin-api.yunicu.com/api/excel/exportTotalViewingTime/'+stream_id)
    },

    //==========================更多设置====================

    //  登录类型设置
    //打开类型框
    async open_live_login_type_model(live_details){
      //赋值正在修改的
      this.live_login_type_alter_details=live_details
      //打开弹框
      this.live_login_type_visible = true
    },
    //提交登录类型修改
    async submit_alter_login_type() {
      const response = await alter_login_type(this.live_login_type_alter_details.id, this.live_login_type_alter_details.login_type)
      if (response.code === 0) {
        this.$message.success("修改成功!")
        //关闭弹窗
        this.live_login_type_visible = false
      } else {
        this.$message.error('修改失败，请联系管理员' + response.message)
      }
    },


    //===========协议==============
    //点击协议对话框,获取协议,打开对话框
    async open_live_protocol_model(id) {
      this.spinning=true;
      const response = await this.getLive_meet_details(id);
      if (response.code === 0){
        this.live_protocol_visible = true;
        this.live_protocol_details = response.data
        if (!this.editor){
          //初始化富文本编辑器
          await this.create_editor_config()
        }
        //向富文本编辑器中添加内容
        if (this.live_protocol_details.protocol_content) {
          this.editor.txt.html(this.live_protocol_details.protocol_content)
        }
      }else {
        this.$message.error('获取失败，请联系管理员' + response.message)
      }
      this.spinning=false
    },

    //提交协议修改
    async submit_alter_live_protocol(live_protocol_details){
      this.spinning=true;
      const response = await alter_live_protocol(live_protocol_details.id,
          live_protocol_details.protocol_is_open,
          this.editor.txt.html());
      if (response.code === 0 ){
        //修改成功
        this.$message.success("修改成功！")
        this.live_protocol_visible = false
      }else {
        this.$message.error('修改失败，请联系管理员' + response.message)
      }
      this.spinning=false
    },


    //=============时长============
    //打开时长弹框
    async open_live_duration_model(id){
      const response = await this.getLive_meet_details(id);
      if (response.code === 0){
        this.live_duration_details = response.data
        this.live_duration_visible = true
      }else {
        this.$message.error('获取失败，请联系管理员' + response.message)
      }
    },

    //提交修改时长
    async submit_live_duration_alter(live_duration_details){
      const response = await alter_duration_(live_duration_details.id,
          live_duration_details.duration_is_open,
          live_duration_details.duration_remark);
      if (response.code === 0){
        this.$message.success("修改成功！")
        //关闭弹窗
        this.live_duration_visible = false
      }else {
        this.$message.error('获取失败，请联系管理员' + response.message)
      }
    },

    //=============直播回看=============
    /**
     * 获取直播回看
     * @param id
     * @returns {Promise<void>}
     */
    async open_live_Look_back(id) {
      this.spinning = true
      const response = await playback_details(id);
      if (response.code=== 0){
        //赋值正在修改的id,用于下文的刷新
        this.alter_Look_back_id = id
        this.live_Look_back_visible = true
        this.live_Look_back_details = response.data
      }else {
        this.$message.error("获取直播回看错误。"+response.message)
      }
      this.spinning = false
    },


    /**
     * 改变回放状态
     * @param id
     * @param parameter
     * @returns {Promise<void>}
     */
    async alterReplay_is_open(id,parameter){
      this.live_Look_back_button_disable = true
      const response = await playback_open_close_(id, parameter);
      if (response.code === 0){
        this.$message.success("修改成功。")
        await this.open_live_Look_back(id)
      }else {
        this.$message.error("修改失败"+response.message)
      }
      this.live_Look_back_button_disable = false
    },

    /**
     * 删除直播回看
     * @param id
     * @returns {Promise<void>}
     */
    async deleteLiveLookBack(id){
      this.spinning = true
      const response = await delete_video_(id);
      if (response.code === 0){
        //刷新列表
        await this.open_live_Look_back(this.alter_Look_back_id)
      }else {
        this.$message.error("修改失败"+response.message)
      }

    },

    /**
     * 修改直播回看标题
     * @returns {Promise<void>}
     */
    async alter_live_look_back_title(id,title){
      //赋值直播详情
      this.alter_Look_back_video_detail ={
        id, title
      }
      //打开对话框
      this.alter_Look_back_video_visible = true
    },
    /**
     * 提交修改直播回看
     */
    async get_alter_live_look_back_title(){
      this.spinning = true
      const alter_Look_back_video_detail = this.alter_Look_back_video_detail
      //提交修改
      const response = await alter_video_title(alter_Look_back_video_detail.id,alter_Look_back_video_detail.title);
      if (response.code === 0){
        this.$message.success("修改成功！")
        //刷新列表
        await this.open_live_Look_back(this.alter_Look_back_id)
        this.alter_Look_back_video_visible = false
      }else {
        this.$message.error("修改失败"+response.message)
      }

    },



    //===========绑定大会直播========
    async open_live_convention(id){
      this.spinning = true
      const response = await convention(id);
      if (response.code === 0){
        this.alter_live_convention_id = id
        const data = response.data;
        //for循环变量,查找出已经选择的哪一个
        for (let i = 0; i < data.length; i++) {
          if (data[i].is_check===1){
            this.live_convention_is_check.title = data[i].title
            break;
          }
        }
        this.live_convention_lists = data
        this.live_convention_visible = true
      }else {
        this.$message.error("大会列表获取失败。")
      }
      this.spinning = false
    },


    /**
     * 提交绑定大会直播修改
     * @returns {Promise<void>}
     */
    async alter_live_convention(){
      let conventionId = this.live_convention_is_check.id;
      if (!conventionId){
        conventionId = null
      }
      const response = await live_id_convention_id(
          this.alter_live_convention_id,
          conventionId);
      if (response.code === 0){
        this.$message.success("绑定大会直播成功")
        this.live_convention_visible = false
      }
    },

    //==========富文本编辑器=========
    async create_editor_config(){
      this.$nextTick(async () => {
        //等待DOM加载完毕
        const editor =  new E(this.$refs.editor)
        this.editor = editor
        //去除图片视频上传
        editor.config.excludeMenus = [
          'image',
          'video'
        ]
        // 设置编辑区域高度为 500px
        editor.config.height =100
        //创建编辑器
        await editor.create()
      })
    }


  },
}
</script>

<style lang="scss">
.live_meet_table{
  overflow: auto;
}
.copy_icon{
  cursor: pointer;
  &:hover{
    color: #1890ff;
  }
}
.update{
  .ant-form-item{
    margin-bottom: 0;
  }
  .updateFile {
    margin-top: 7px;
    height: 25px;
    width: 80px;
    background-image: url("../../assets/addImage.png");
    background-size: 80px 25px;
    cursor: pointer;
    .container {
      height: 25px;
      width: 80px;
      opacity: 0;
      cursor: pointer;
    }
  }
}
.image_container{
  img{
    max-width: 290px;
    max-height: 150px;
  }
}

</style>