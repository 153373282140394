import {get, post, put, del, getExel, getType} from './base'
import {delXtoken, postXtoken} from "@/service/base_y";

/**
 * 获取直播列表
 * @param pageNo
 * @param pageSize
 * @param title
 * @param isShow
 * @param sortTime
 * @param domainCode
 * @param name
 * @returns {Promise<* | void>}
 */
export function getLiveList(pageNo,pageSize,title,isShow,sortTime,domainCode,name){
    return getType("/api/admin/liveMettingSpecials",{pageNo,pageSize,title,isShow,sortTime,domainCode,name})
}
export function getLiveMeetList(data){
    return getType("/api/admin/liveMettingSpecials",data)
}
//获取个人测试会议
export function getLiveTestList(userName){
    return get("/api/admin/selTestLiveMeeting?userName="+userName)
}

/**
 * 修改直播
 * @param id
 * @param data
 * @returns {Promise<* | void>}
 */
export function putLiveList(id,data){
    return put("/api/admin/liveMettingSpecial/"+id,data)
}
/**
 * 添加直播
 * @param data
 * @returns {Promise<* | void>}
 */
export function postLiveList(data){
    return post("/api/admin/liveMettingSpecial",data)
}
//直播会议上移下移
export function LiveList_upOrDown(id,action){
    return put("/api/admin/liveMettingSpecialMove/"+id+'/'+action)
}
/**
 * 获取直播标题和
 * @param id
 * @returns {Promise<* | void>}
 */
export function getListContent(id){
    return get("/api/admin/liveMettingSpecial/"+id)
}

/**
 * 获取设置直播列表
 * @param page_no
 * @param page_size
 * @param title
 * @param live_date
 * @returns {Promise<* | void>}
 */
// export function getSetLiveList(page_no,page_size,title,start_time,live_metting_id){
//     return get("/api/admin/liveMeet",{page_no,page_size,title,start_time,live_metting_id})
// }
export function getSetLiveList_feature(data){
    return get("/yun/selLiveStreamOfConvention",data)
}
export function getSetLiveList(data){
    return get("/api/admin/liveMeet",data)
}

export function putSetLiveList(id,data){
    return put("/api/admin/liveMeet/"+id,data)
}

/**
 * 获取单个直播间
 * @param data
 * @returns {Promise<unknown | void>}
 */
export function getOneLiveList(id){
    return get("/api/admin/liveMeet/"+id)
}
//新-添加直播间
export function newPostSetLive(data){
    return post("/api/admin/insLiveStream",data)
}
//新-修改直播间
export function newPutSetLive(data){
    return put("/api/admin/updLiveStream",data)
}
/**
 * 原始观看记录
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_Original_viewing(data){
    return get('/api/admin/liveWatchRecords',data)
}
/**
 * 获取直播间访问记录
 */
export function get_liveWatchRecords(mettingId,data){
    return get('/yun/admin/getAccessLiveReport/'+mettingId,data)
}
/**
 * 原始观看记录---手动上报
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_Original_viewing_operation(data){
    return get('/api/admin/liveReportedRecords',data)
}
/**
 * 人员观看总时长
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_People_watch(data){
    return get('/api/admin/liveWatchRecordTotalTimes',data)
}
/**
 * 获取全部人员观看总时长-会员
 */
export function getLiveWatchRecord(meetingId,data){
    return get('/yun/admin/getLiveWatchRecord/'+meetingId,data)
}
/**
 * 获取全部人员观看总时长-游客
 */
export function getLiveWatchRecordYK(meetingId,data){
    return get('/yun/admin/getLiveWatchRecordYK/'+meetingId,data)
}
/**
 * 获取名单人员观看总时长
 */
export function getCreditLiveWatchRecord(conventionId,data){
    return get('/yun/admin/getCreditLiveWatchRecord/'+conventionId,data)
}
/**
 * 人员观看总时长---手动上报
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_People_watch_operation(data){
    return get('/api/admin/liveReportedRecordTotalTimes',data)
}
/**
 * 姓名医院登记记录
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_name_hos(data){
    return get('/api/admin/liveNameHospitalRecord',data)
}

/**
 * 聊天记录
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_chatting_records(data){
    return get('/api/admin/liveChatRecord',data)
}
export function delList(liveChatIds){
    return del('/api/admin/liveChatRecord',{liveChatIds})
}

/**
 * 删除
 * @param liveMeetingId
 * @returns {Promise<* | void>}
 */
export function delSetLiveList(liveMeetingId){
    return del('/api/admin/liveMeet/'+ liveMeetingId)
}

/**
 * 修改聊天室
 * @param id
 * @returns {Promise<* | void>}
 */
export function putVodHide(id,data){
    return put('/api/admin/liveChatRecord/'+ id,data)
}

export function insCreditRecord(id,conventionId,data){
    return postXtoken('/api/admin/insCreditRecord/'+id+'/'+conventionId,data)
}
//-----------------------------------------直播问答管理-------------------------------------------
/**
 * 问题模板列表
 * @returns {Promise<unknown | void>}
 */
export function getAnswer(title,is_open,page_no,page_size){
    return get('/api/admin/liveQuestionTemplates',{title,is_open,page_no,page_size})
}

/**
 * 新增答题模板
 * @param data
 * @returns {Promise<* | void>}
 */
export function postAnsList(data){
    return post('/api/admin/liveQuestionTemplate',data)
}

/**
 * 修改答题模板
 * @param data
 * @returns {Promise<unknown | void>}
 */
export function putAnsList(id,data){
    return put('/api/admin/liveQuestionTemplate/' + id,data)
}

export function delAnsList(id){
    return del('/api/admin/liveQuestionTemplate/' + id)
}

/**
 * 答题记录列表
 * @param templateId
 * @returns {Promise<unknown | void>}
 */
// export function getAnswerRecord(questionId,answer,liveStreamTitle){
//     return get('/api/admin/liveQuestionLogs',{questionId,answer,liveStreamTitle})
// }
export function getAnswerRecord(data){
    return get('/api/admin/liveQuestionLogs',data)
}

export function putAnswerRecord(id,isWinning){
    return put('/api/admin/liveQuestionLogs/'+id+'/'+isWinning)
}
//-------------------------直播抽奖活动---中奖名单-------------------------------

/**
 * 直播抽奖活动列表
 * @returns {Promise<unknown | void>}
 */
export function getActivityList(page_no,page_size,title,isFinish){
    return get("/api/admin/activities",{page_no,page_size,title,isFinish})
}

/**
 * 获取中奖列表
 * @param activity_id
 * @param page_no
 * @param page_size
 * @returns {Promise<* | void>}
 */
export function getRaffle(activity_id,page_no,page_size,release_status,tel){
    return get("/api/admin/raffleWinnings", {activity_id,page_no,page_size,release_status,tel})
}


export function putRaffle(raffleWinnings,data){
    return put("/api/admin/raffleWinning/" +raffleWinnings, data)
}

//----------------------权限管理---通讯录管理-----------------------
/**
 * 获取左侧列表
 * @returns {Promise<* | void>}
 */
export function getCommunictionLeft(){
    return get("/api/admin/departments")
}

/**
 * 获取列表
 * @param page_no
 * @param page_size
 * @param type
 * @param userName
 * @param departmentById
 * @returns {Promise<* | void>}
 */
export function getCommunictionList(page_no,page_size,type,userName,departmentById){
    return get("/api/admin/adminUsers",{page_no,page_size,type,userName,departmentById})
}

/**
 * 提交会议场次得分记录
 * @param data
 * @returns {Promise<unknown>}
 */
export function submitToStatistics(data){
    return postXtoken("/api/admin/submitToStatistics",data)
}

/**
 * 获取原始会议场次得分
 * @param conventionId
 * @returns {Promise<unknown>}
 */
export function statisticsConventionScore(conventionId){
    return get("/api/admin/statisticsConventionScore/"+conventionId)
}


/**
 * 获取关闭或开启的直播类型数据
 * @param isOpen
 * @returns {Promise<* | void>}
 */
export function getDictionaryList(isOpen){
    return get("/live/stream_dictionary/getDictionaryList?isOpen="+isOpen)
}

/**
 * 获取状态为开启或关闭的设计字典列表
 * @param isOpen
 * @returns {Promise<unknown>}
 */
export function dictionary(isOpen){
    return get("/live/directionary/getDictionaryList?isOpen="+isOpen)
}

/**
 * 添加直播间设计源文件
 * @param isOpen
 * @returns {Promise<unknown>}
 */
export function upLoadSourceFile(data){
    return postXtoken("/api/admin/upLoadSourceFile",data)
}

export function delLoadSourceFile(id){
    return delXtoken("/api/admin/delFileById?id="+id)
}

/**
 * 同步
 * @returns {Promise<* | void>}
 */
export function synchronization(){
    return get("/api/admin/auth/synchronization")
}
//查询可关联的医学会议
export function selCanJoinConvention(data){
    return get("/api/admin/selCanJoinConvention",data)
}

//下载
export function Download_answers_exal(data){
    return getExel("/api/admin/export/liveQuestionLogs",data)
}

//直播间访问记录
export function getAccessLiveReportExport(mettingId,data){
    return getExel("/yun/admin/getAccessLiveReportExport/"+mettingId,data)
}
//导出全部人员观看总时长-会员
export function getLiveWatchRecordExport(mettingId,data){
    return getExel("/yun/admin/getLiveWatchRecordExport/"+mettingId,data)
}
//导出获取全部人员观看总时长-游客
export function getLiveWatchRecordYKExport(mettingId,data){
    return getExel("/yun/admin/getLiveWatchRecordYKExport/"+mettingId,data)
}
//导出获取名单人员观看总时长
export function getCreditLiveWatchRecordExport(conventionId,data){
    return getExel("/yun/admin/getCreditLiveWatchRecordExport/"+conventionId,data)
}
//下载原始观看记录
export function Download_OriginalViewing_exal(data){
    return getExel("/api/admin/liveWatchRecords/export-excel",data)
}
//下载人员观看总时长
export function Download_PeopleWatch_exal(data){
    return getExel("/api/admin/liveWatchRecordTotalTimes/export-excel",data)
}
//下载姓名医院登录记录
export function Download_NameHos_exal(data){
    return getExel("/api/admin/liveNameHospitalRecord/export-excel",data)
}
//下载聊天室记录
export function Download_ChattingRecords_exal(data){
    return getExel("/api/admin/liveChatRecord/export-excel",data)
}

/**
 * 设置直播间关联的专家组
 * @param streamId
 * @returns {Promise<unknown>}
 */
export function updLiveStreamExperts(streamId,data){
    return put("/admin/updLiveStreamExperts/"+streamId,data)
}

/**
 * 获取直播间关联的专家组
 * @param streamId
 * @returns {Promise<unknown>}
 */
export function getLiveStreamExperts(streamId){
    return get("/admin/getLiveStreamExperts/"+streamId)
}
