import {get, post, put, del, getExel, getType,putById} from './base'
import {delXtoken, postXtoken, putXtoken} from "@/service/base_y";
//--------------------------------------医学会议--会议管理------------------
/**
 * 获取医学会议列表
 * @param page_no
 * @param page_size
 * @param isPublished   状态
 * @param title   标题
 * @returns {Promise<unknown | void>}
 * @constructor
 */
export function CaseLive(page_no,page_size,isPublished,title,sortTime,domainCode){
    return get("/api/admin/conventions",{page_no,page_size,isPublished,title,sortTime,domainCode})
}
export function CaseLiveNew(data){
    return getType("/api/admin/conventions",data)
}
//获取左侧大会列表（点播视频管理）
export function getLiveList(data){
    return getType("/yun/getConventionForVod",data)
}

/**
 * 获取医学会议单个列表
 * @param id
 * @returns {Promise<unknown | void>}
 * @constructor
 */
export function CaseLiveOneList(id){
    return get("/api/admin/convention/"+id)
}
/**
 * 获取功能管理模块
 * @param convention_id   医学会议id
 * @returns {Promise<* | void>}
 * @constructor
 */
export function CaseLiveFeature(convention_id){
    return get("/api/admin/conventionFunctions/"+ convention_id)
}
/**
 * 获取单个功能管理模块
 * @param id   列表id
 * @returns {Promise<* | void>}
 * @constructor
 */
export function CaseLiveFeatureOne(id){
    return get("/api/admin/conventionFunction/"+ id)
}

/**
 * 上移下移
 * @param convention_id
 * @param action
 * @returns {Promise<* | void>}
 */
export function upDown(convention_id,action){
    return put("/api/admin/conventionMove/"+ convention_id + '/' + action)
}
/**
 * 功能模块上移下移
 * @param convention_id
 * @param action
 * @returns {Promise<* | void>}
 */
export function function_upDown(convention_id,action){
    return put("/api/admin/conventionFunctionMove/"+ convention_id + '/' + action)
}

/**
 * 医学会议开关
 * @param id
 * @param data
 * @returns {Promise<* | void>}
 */
export function putSwitch(id,data){
    return put("/api/admin/convention/"+ id,data)
}

/**
 * 获取内容-轮播广告
 * @param id
 * @returns {Promise<* | void>}
 */
export function get_content_ByAdvertising(conventionId){
    return get("/api/admin/conventionBanners/"+ conventionId)
}

/**
 * 添加内容-轮播广告
 * @param data
 * @returns {Promise<* | void>}
 */
export function post_content_ByAdvertising(data){
    return post("/api/admin/conventionBanner",data)
}

/**
 * 删除内容-轮播广告
 * @param id
 * @returns {Promise<unknown | void>}
 */
export function delByAdvertising(id){
    return del("/api/admin/conventionBanner/"+id)
}

export function put_content_ByAdvertising_upDown(id,action){
    return put("/api/admin/conventionBannerMove/"+id + '/' + action)
}
//-字幕文字通知--------------------------------------------------------------------
/**
 * 获取列表
 * @param conventionId
 * @returns {Promise<* | void>}
 */
export function get_content_alphabetic_list(conventionId){
    return get("/api/admin/conventionBulletins/"+conventionId)
}

/**
 * 添加
 * @param data
 * @returns {Promise<* | void>}
 */
export function post_content_alphabetic_list(data){
    return post("/api/admin/conventionBulletin",data)
}

/**
 * 删除
 * @param id
 * @returns {Promise<* | void>}
 */
export function del_content_alphabetic_list(id){
    return del("/api/admin/conventionBulletin/"+id)
}

//图文链接展示----------------------------------------------------------------------
/**
 * 获取列表
 * @param conventionId
 * @returns {Promise<* | void>}
 */
export function get_content_GraphicLinks_list(conventionId,funcId){
    return get("/api/admin/conventionGalleries/"+conventionId+'/'+funcId)
}

/**
 * 添加
 * @param data
 * @returns {Promise<* | void>}
 */
export function post_content_GraphicLinks_list(data){
    return post("/api/admin/conventionGallery",data)
}

/**
 * 上移下移
 * @param id
 * @param action
 * @returns {Promise<* | void>}
 */
export function put_content_GraphicLinks_move(id,action){
    return put("/api/admin/conventionGalleryMove/"+id + '/' +action)
}

/**
 * 删除
 * @param id
 * @returns {Promise<* | void>}
 */
export function del_content_GraphicLinks_list(id){
    return del("/api/admin/conventionGallery/"+id)
}

//新增功能模块
export function post_content_card(data){
    return post("/api/admin/conventionFunction",data)
}


//内容-图片展-HYC----内容-PDF展示---HYTZ----------------------------------------------------------------------
export function put_content_picture(id,data){
    return put("/api/admin/conventionFunction/"+id,data)
}
//  名单-----------------------------------------------------------------------------------------
/**
 * 获取列表--名单-学分注册
 * @param data
 * @returns {Promise<* | void>}
 */
export function get_credit_registration_list(data){
    return get("/api/admin/creditRecords",data)
}
/**
 * 获取列表--名单-学分注册
 * @param data
 * @returns {Promise<* | void>}
 */
export function get_credit_registration_lists(data){
    return get("/yun/admin/liveSumRecord",data)
}

/**
 * 修改学分信息
 * @param funcId
 * @param value
 * @param remark
 * @returns {Promise<* | void>}
 */
export function putUpdXFZC(funcId,value,remark,limitEnrollCount){
    return put("/api/admin/updXFZC?funcId="+funcId+'&value='+value+'&remark='+remark+'&limitEnrollCount='+limitEnrollCount)
    // return put("/api/admin/updXFZC",data)
}
/**
 * 计算观看数据
 * @param data
 * @returns {Promise<* | void>}
 * @constructor
 */
export function countLiveSumRecord(data){
    return post("/yun/admin/countLiveSumRecord",data)
}
export function selDurationZD(memberId,conventionId){
    // return get("/api/admin/selDurationZD/"+memberId+"/"+conventionId+"/"+dateTime)
    return get("/api/admin/selListDur/"+memberId+"/"+conventionId)
}

/**
 * 名单-注册缴费
 * @param page_no
 * @param conventionId
 * @param name
 * @param mobilePhone
 * @returns {Promise<unknown | void>}
 */
export function get_Tuition_fees_register_list(data){
    return get("/api/admin/conventionEnrollRecords",data)
}

/**
 * 获取单个会议详情给人数-input赋值
 * @param id
 * @returns {Promise<unknown | void>}
 */
export function get_per_OfThe_one_list(id){
    return get("/api/admin/convention/"+id)
}

export function put_per_OfThe_one_list_ofNumber(id,data){
    return put("/api/admin/convention/"+id,data)
}

//导入人员名单到报名缴费
export function postUploadFile(conventionId,file){
    return post("/api/admin/importExcelMember?conventionId="+conventionId,file)
}
//导入人员名单模板下载  data = {ExelName:文件名}
export function getImportExcelMemberTemplate(data){
    return getExel("/api/admin/getImportExcelMemberTemplate",data)
}


//内容-日程日历展示-------------------------------------------------------------------
//获取时间列表
export function get_CalendarCalendar_timeList(conventionId,id){
    return get("/api/admin/conventionSchedulesOnBeginDate/"+conventionId+'/'+id)
}
//获取一级列表
export function get_CalendarCalendar_timeList_listTable(conventionId,beginDate,funcId,siteId){
    return get("/api/admin/conventionSchedules/"+conventionId,{beginDate,funcId,siteId})
}
//修改一级列表
export function put_CalendarCalendar_timeList_listTable(id,data){
    return put("/api/admin/conventionSchedule/"+id,data)
}
//新增一级列表
export function post_CalendarCalendar_timeList_listTable(data){
    return post("/api/admin/conventionSchedule",data)
}
//删除一级列表
export function del_CalendarCalendar_timeList_listTable(id){
    return del("/api/admin/conventionSchedule/"+id)
}
//获取二级列表
export function get_CalendarCalendar_timeList_listTable_child(scheduleId){
    return get("/api/admin/conventionAcademics/",{scheduleId})
}
//修改二级列表
export function put_CalendarCalendar_timeList_listTable_child(id,data){
    return put("/api/admin/conventionAcademic/"+id,data)
}

//添加二级列表
export function post_CalendarCalendar_timeList_listTable_child(data){
    return post("/api/admin/conventionAcademic",data)
}
//删除二级列表
export function del_CalendarCalendar_timeList_listTable_child(id){
    return del("/api/admin/conventionAcademic/"+id)
}
//获取地点列表11111111111111
export function get_CalendarCalendar_AddressList(conventionId){
    return get("/api/admin/conventionSites/"+conventionId)
}
//删除地点
export function del_CalendarCalendar_AddressList(id){
    return del("/api/admin/conventionSite/"+id)
}
//地点上移下移
export function UpOrDown_AddressList(id,type){
    return put("/api/admin/conventionSite/UpOrDown?id="+id+'&type='+type)
}
//获取主席列表
export function get_CalendarCalendar_chairmanList(data){
    return get("/api/admin/conventionMembers",data)
}

//内容-日程日历展示------------------------------------------------------------------------------


//导入xsxl文件
export function get_importExpertTemporaries_xsxl(file){
    return post("/api/admin/importExpertTemporaries", file,{headers: {'Content-Type':'multipart/form-data'}})
}
//获取导入的临时表
export function importExpertTemporaries_xsxl(page_no){
    return get("/api/admin/expertTemporaries",{page_no})
}
//查询专家库列表
// export function get_experts_xsxl(name,page_no,page_size,conventionId){
//     return get("/api/admin/experts",{name,page_no,page_size,conventionId})
// }
export function get_experts_xsxl(data){
    return get("/api/admin/experts",data)
}
//获取单个专家信息表
export function get_experts_xsxl_one(id){
    return get("/api/admin/expert/"+id)
}
//修改专家信息表
export function put_experts_xsxl_one(id,data){
    return put("/api/admin/expert/"+id,data)
}
//添加专家信息表
export function post_experts_xsxl_one(data){
    return post("/api/admin/expert",data)
}
//添加专家库
export function post_experts_xsxl(data){
    return post("/api/admin/conventionMembers",data)
}

//企业海报展示--------------------------------------------------------------------------
//获取企业海报列表
export function getEnterprisePosters(conventionId,funcId,page_no,page_size){
    return get("/api/admin/conventionEnterprisePosters",{conventionId,funcId,page_no,page_size})
}
//获取关联直播会议列表
export function getLiveBroadcastLists(data){
    return get("/api/admin/getConventionLiveMeet",data)
}
//关联直播会议
export function putLiveBroadcastLists(conventionId,liveMeetId,isDefault){
    return putById("/api/admin/relationLiveMeet?conventionId="+conventionId+'&liveMeetId='+liveMeetId+'&isDefault='+isDefault)
}

//新增
export function postEnterprisePosters(data){
    return post("/api/admin/conventionEnterprisePoster",data)
}
//修改
export function putEnterprisePosters(id,data){
    return put("/api/admin/conventionEnterprisePoster/"+id,data)
}
//获取单一日程
export function getOneDate(id){
    return get("/api/admin/conventionSchedule/"+id)
}
//获取企业海报列表
export function corporatePoster(id){
    return get("/api/admin/corporatePoster/"+id)
}
//新增
export function postCorporatePoster(data){
    return postXtoken("/api/admin/corporatePoster",data)
}
//删除
export function delCorporatePoster(id){
    return delXtoken("/api/admin/corporatePoster/"+id)
}
//企业海报上下移
export function putCorporatePoster(id,type){
    return putXtoken("/api/admin/corporatePoster/"+id+'/'+type)
}

//地点-日程日历展示----------------------------------------------------------------------
//获取列表
export function getPlaceList(conventionId){
    return get("/api/admin/conventionSites/"+conventionId)
}
//修改点播回放时长是否开启
export function updVodIsOpenDuration(funcId,isOpen,durationRemark){
    // return put("/api/admin/updVodIsOpenDuration/"+funcId+"/"+isOpen,data)
    return put("/api/admin/updVodIsOpenDuration/"+funcId+"/"+isOpen+'?durationRemark='+durationRemark)
}
//修改列表地址
export function putPlaceList(id,data){
    return put("/api/admin/conventionSite/"+id,data)
}
//新增列表地址
export function postPlaceList(data){
    return post("/api/admin/conventionSite",data)
}
//内容-直播日历展示 -----------------------------------------------------------------
export function getLiveBroadcast(conventionId,page_no,page_size){
    return get("/api/admin/liveMeet",{conventionId,page_no,page_size})
}
//修改公用
export function put_RCYL_isOpen(id,data){
    return put("/api/admin/liveMeet/"+id,data)
}

//回放列表
export function get_Playback_list(streamId,page_no){
    return get("/api/admin/liveStreamVideos",{streamId,page_no})
}

//获取视频库列表
export function get_video_list(data){
    return get("/api/admin/videoManages",data)
}

//添加视频库
export function post_video_list(streamId,videoManegeId){
    return post("/api/admin/liveStreamVideo/"+streamId+'/'+videoManegeId)
}


export function put_Playback_list(id,data){
    return put("/api/admin/liveStreamVideo/"+id,data)
}
//下载全部的--------------------------------------------------------------------------------------------
//原始观看记录（自动上报）
export function getCase_Original_viewing_voluntarily_exal(data){
    return getExel("/api/admin/liveWatchRecords/export-excel",data)
}
//人员观看总时长（自动上报）
export function getCase_People_watch_voluntarily_exal(data){
    return getExel("/api/admin/liveWatchRecordTotalTimes/export-excel",data)
}

//原始观看记录（手动上报）
export function getCase_Original_viewing_operation_exal(data){
    return getExel("/api/admin/export-excel/liveReportedRecords",data)
}
//人员观看总时长（手动上报）
export function getCase_People_watch_operation_exal(data){
    return getExel("/api/admin/export-excel/liveReportedRecordTotalTimes",data)
}
//聊天室记录
export function getCase_chatting_records_exal(data){
    return getExel("/api/admin/liveChatRecord/export-excel",data)
}
//删除会议功能
export function del_card(id){
    return del("/api/admin/conventionFunction/"+id)
}


//下载导入的模板
export function Download_the_template_exal(data){
    return getExel("/api/admin/download/ImportExpertTemporariesTemplate",data)
}
//批量导出
export function batch_export_exal(data){
    return getExel("/api/admin/exportExcel/creditRecords",data)
}
//批量导出
export function batch_export_exal_register(data){
    return getExel("/api/admin/exportExcel/conventionEnrollRecords",data)
}
//删除临时表内容
export function del_list_person(id){
    return del("/api/admin/expertTemporary/"+id)
}
//删除全部临时表内容
export function del_list_person_all(){
    return del("/api/admin/expertTemporaryAll")
}
//-------------------------------------------------------------------帮助中心内容管理/统计------------------------------------
/**
 * 获取帮助中心内容管理列表
 * @param data
 * @returns {Promise<unknown | void>}
 */
export function get_help_center_list(data){
    return get("/api/admin/helpers",data)
}
//获取单个
// export function get_help_center_list_one(id){
//     return get("/api/admin/helper/"+id)
// }

export function get_help_center_list_one(id){
	return get(`/v3/helperById?id=${id}`)
}

//删除
export function del_help_center_list_one(id){
    return del("/api/admin/helper/"+id)
}

/**
 * 新增助中心内容管理列表
 * @param data
 * @returns {Promise<unknown | void>}
 */
// export function post_help_center_list(data){
//     return post("/api/admin/helper",data)
// }

export function post_help_center_list(data){
    return post("/v3/helper",data)
}

//修改
// export function put_help_center_list(id,data){
//     return put("/api/admin/helper/"+id,data)
// }

export function put_help_center_list(data){
    return put("/v3/helper",data)
}

//上移下移
export function upDown_help_center_list(id,action,data){
    return put("/api/admin/helperMove/"+id + '/' +action,data)
}
//上移下移---企业海报
export function EnterprisePosterMovement(id,action){
    return put("/api/admin/conventionEnterprisePosterMove/"+id + '/' +action)
}
export function del_zcjz(id){
    return del("/api/admin/conventionMember/"+id)
}

export function delMember(id){
    return del("/api/admin/delCreditRecordMember?id="+id)
}
