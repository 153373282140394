import {get, put, postBId, del, post, getExel, delById,llget,llpost,llput} from "./base";
import qs from "qs";
import {postXtoken} from "@/service/base_y";


/** 专家咨询管理 **/

//上传专家附件
export function postExpertFile(file){
    return post('/v3/yun/expert/enclosure',file,{headers:{'Content-Type':'multipart/form-data'}})
}


/** zoom会议管理 **/
export function getZoomMeet(pageNum,meetId,type){
    return get('/v3/yun/meets',{pageNum,meetId,type,'pageSize':10})
}
export function getZoomMeet2(pageNum,meetId,type){
    return get('/v3/yun/meets',{pageNum,meetId,type,'pageSize':9999})
}
export function postZoomMeet(data){
    return postBId('/v3/yun/meet',data)
}
export function putZoomMeet(data){
    return put('/v3/yun/meet',data)
}
export function delZoomMeet(id){
    return del('/v3/yun/meet',{id})
}

/** 预约记录 **/

//获取预约记录
/** 状态 ENDINGP:申请中 ARRENGED：已安排 FINISH：已结束 CANCEL：已取消 **/

export function getAppointLog(pageNum,status){
    return get('/v3/yun/book/record',{pageNum,status,'pageSize':10})
}
//预约记录状态修改
export function putAppointLog(data){
    return put('/v3/yun/book/record',qs.stringify(data))   //(id,status:状态)
}



/** 会诊会议管理 **/
/** isOpen: 1：已结束 0：未结束
 * type:会议类型  ：CONAULTATION：会诊  METTING：会议
 *
 *
 * **/
export function getConsultationMeet(pageNum,type,isOpen,title){
    return get('/v3/yun/consultation',{pageNum,type,isOpen,title,'pageSize':10})
}
export function getConsultationMeet2(pageNum,type,title){
    return get('/v3/yun/consultation',{pageNum,type,'isOpen':0,title,'pageSize':9999})
}
export function postConsultationMeet(data){
    return postBId('/v3/yun/consultation',data)
}

export function putConsultationMeet(data){
    return put('/v3/yun/consultation',data)
}

//结束会议
export function delConsultationMeet(id){
    return del('/v3/yun/consultation',{id})
}

//查看人员
export function getMeetMember(consultationId,pageNum,pageSize,name,tel){
    return get('/v3/yun/meet/member',{consultationId,pageNum,pageSize,name,tel})
}
//搜索人员
export function getMeetMemberTel(name,tel,id){
    return get('/v3/yun/Member',{name,tel,id})
}
//添加人员
export function postMeetMember(data){
    return postBId('/v3/yun/consultation/member',qs.stringify(data))
}

//移除人员
export function delMeetMember(meetMemberId){
    return del('/v3/yun/meet/member',{meetMemberId})
}
//获取会议记录
export function getMeetLog(consultationId,id){
    return get('/v3/yun/consultation/media',{consultationId,id})
}
//新增会诊会议记录
export function postMeetLog(data){
    return postBId('/v3/yun/consultation/media',data)
}
export function putMeetLog(data){
    return put('/v3/yun/consultation/media',data)
}
export function delMeetLog(id){
    return del('/v3/yun/consultation/media',{id})
}

export function openMeetLog(id){
    return put('/v3/yun/openConsultation/'+id)
}


/** 注册专家 ll **/
export function getExpert(data){
    return get('/v3/yun/sign/experts',data)
}

export function getExpertList(data){
    return get('/v3/yun/sign/experts/withHospital',data)
}

export function getExpertId(id){
    return get('/v3/yun/sign/experts',{'pageNum':1,id,'pageSize':10})
}
//新增专家附件
export function postExpertAccessor(data){
    return postBId('/v3/yun/sing/expert/enclosure',qs.stringify(data))
}
export function getExpertAccessor(expertId){
    return get('/v3/yun/sing/expert/enclosure',{expertId})
}
export function postExpertAccessorPut (data) {
    return postBId('/v3/yun/expert/update', data)
}
//新增专家
export function postExpert(data){
    return post('/v3/yun/sign/expert',data)
}
//修改专家
export function putExpert(data){
    return put('/v3/yun/sign/expert',data)
}
//删除专家
export function delExpert(id){
    return del('/v3/yun/sign/expert', {id})
}
export function getExpertApi (name) {
    return get('/v3/yun/sign/experts/name', name)
}
/** 专家id：expertId,绑定传memberId，解绑传memberId：null **/
export function putExpertMember(data){
    return put('/v3/yun/sign/expert/member',qs.stringify(data))
}
export function postMeetExpert(data){
    return postBId('/v3/yun/sign/consultation/member', qs.stringify(data))
}


/** 会诊专家 **/
export function getExpertMeet(pageNum,keyWord){
    return get('/v3/yun/consultation/expert', {pageNum,keyWord})
}
//up:上移 down：下移
export function postExpertMeet(id,data){
    return post('/v3/yun/consultation/expert/moveUpDown/' + id, qs.stringify(data))
}
export function delExpertMeet(id){
    return del('/v3/yun/consultation/expert' ,{id})
}

/**
 * 查询参会人员
 * @param contentId
 * @param data
 * @returns {Promise<* | void>}
 */
export function get_staff_list(contentId,data){
    return get("/api/admin/meetMember/"+contentId,data)
}

/**
 * 查询平台注册人员
 * @param contentId
 * @param data
 * @returns {Promise<* | void>}
 */
export function get_registered_personnel(menberId,meetId){
    return get("/api/admin/insMeetMember/"+menberId,meetId)
}

/**
 * 参会人员EXCEL导入
 * @param contentId
 * @param file
 * @returns {Promise<* | void>}
 */
export function postUploadFileMeet(contentId,file){
    return postBId("/api/admin/meetMemberImport?contentId="+contentId,file)
}

/**
 * 参会人员导入名单模板
 * @param data
 * @returns {Promise<unknown>}
 */
export function meetMemberTemplate(data){
    return getExel("/api/admin/meetMemberTemplate",data)
}

/**
 * 根据手机号查询人员信息
 * @param tel
 * @returns {Promise<unknown>}
 */
export function memberByTel(tel){
    return get("/api/admin/memberByTel/"+tel)
}

/**
 * 添加单个参会人员
 * @param memberId
 * @param meetId
 * @returns {Promise<* | void>}
 */
export function insMeetMember(memberId,meetId){
    return postBId('/api/admin/insMeetMember?memberId='+memberId+'&meetId='+meetId)
}

/**
 * 删除参会人员
 * @param id
 * @returns {Promise<unknown>}
 */
export function delMeetPer(id){
    return delById("/api/admin/delMeetMember/"+id)
}


// 人员draw里面的查询所有人员  /v3/yun/consultation/memberList
// export function getMembersList(tel,consultationId){
//     return get(`/v3/yun/consultation/memberList?tel=${tel}&consultationId=${consultationId}`)
// }
export function getMembersList(data){
    return get(`/v3/yun/consultation/memberList`,data)
}
//查询专家
export function getVisitsByExpertApi (data) {
    return get('/v3/social/records/visitsByExpert', data)
}
//非专家
export function getVisitsByNoApi (data) {
    return get('/v3/social/records/visitsByMember', data)
}

//查询个人访问量
export function getVisitsByNoPeopleApi (data) {
    return get('/v3/social/records/visitMemberList', data)
}

//粉丝量-专家
export function getFansByApi (data) {
    return get('/v3/social/records/fansByExpert', data)
}

//粉丝量-非专家
export function getFansByNoApi (data) {
    return get('/v3/social/records/fansByMember', data)
}

//粉丝量-个人主页
export function getFansByNoPeopleApi (data) {
    return get('/v3/social/records/fansMemberList', data)
}

//关注量-专家
export function getNumberFApi (data) {
    return get('/v3/social/records/followByExpert', data)
}

//关注量-非专家
export function getNumberNoFApi (data) {
    return get('/v3/social/records/followByMember', data)
}

//关注量-个人主页
export function getNubmerNoFapi (data) {
    return get('/v3/social/records/followMemberList', data)
}

//会员信息-擅长设置
export function getExportLabelApi (data) {
    return get('/v3/yun/expert/label', data)
}

//擅长-新增
export function postExprotLabelAddApi (data) {
    return postBId('/v3/yun/expert/label', data)
}

//擅长-编辑
export function putExportLabelEditApi (data) {
    return put('/v3/yun/expert/label', data)
}

//擅长-删除
export function delExportLabelDelApi (id) {
    return del('/v3/yun/expert/label?id=' + id)
}

//擅长-上移
export function putExportLabelUpDown (data) {
    return get('/v3/yun/expert/label/move', data)
}

//关注-非专家导出
export function getFangWenExportApi (data) {
    return getExel('/v3/social/records/followByMember/export', data)
}
export function getFangWenExportApi2 (data) {
    return getExel('/v3/social/records/fansByMember/export', data)
}