/**
 *
 * @param file
 * @returns {Promise<*>}
 */
import {postDocumentFile, postDocumentImg} from "../service/document_api";

export async function update(file) {
    let formData = new FormData()
    formData.append('file', file)
    return  await postDocumentImg(formData);
}
export async function update_File(file) {
    let formData = new FormData()
    formData.append('file', file)
    return  await postDocumentFile(formData);
}
